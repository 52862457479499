export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cardholder-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder Name"])},
        "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
        "card-holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill the card holder's name"])},
        "form-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The credit card payment did not go through"])}
      },
      "cn": {
        "cardholder-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持卡者姓名"])},
        "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡"])},
        "card-holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写持卡者姓名"])},
        "form-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡支付有误"])}
      }
    }
  })
}
