<template>
<div class="ui form" :id="'add-description-'+pkey">
    <div class="inline field">
    <label><span>{{$t("language")}}</span></label>
    <!-- <select class="ui selection dropdown" v-model="lang" name="lang">
    <option :value="lg" v-for="lg in $root.$i18n.availableLocales" :key="lg">
        {{ $t(lg) }}
    </option>
    </select> -->

    <div class="ui selection dropdown">
        <input type="hidden" name="lang" v-model.lazy="lang">
        <i class="dropdown icon"></i>
        <div class="default">{{$t(lang)}}</div>
        <div class="menu">
            <div class="item" :data-value="lg" v-for="lg in $root.$i18n.availableLocales" :key="lg">
                {{ $t(lg) }}
            </div>        
        </div>
    </div>


    <a @click="$emit('remove', pkey);" class="ui primary label" style="float:right; bottom: -4px; position:relative;" v-if="pkey">
        {{$t('remove')}}
    </a>
    </div>

    <div class="field">
        <label><span>{{$t("short")}}</span></label>
        <textarea rows=2 name="short" v-model.trim="short"></textarea>               
    </div>

    <div class="field">
        <label><span>{{$t("long")}}</span></label>
        <textarea name="long" v-model.trim="long"></textarea>
    </div>

    <div class="ui hidden divider"></div>

</div>
</template>

<script>
export default {
    props: ['pkey', 'locale', 'short_description', 'long_description'],
    emits: ['remove', 'update'],

    data () {
        return {
            lang: this.locale || this.$i18n.locale,
            short: this.short_description || '',
            long: this.long_description || '',
        }
    },

    mounted () {
        this.form_initial();
    },

    updated () {
        this.form_initial();
    },

    watch: {
        lang (newValue, oldValue) {
            $("#add-description-"+this.pkey).form('remove errors', {lang: 'Duplicate language selected'});
            $("#add-description-"+this.pkey).form('remove prompt', 'lang');
        },

        locale (n, o) {
            this.lang = n;
        },

        short_description (n, o) {
            this.short = n;
        },

        long_description (n, o) {
            this.long = n;
        }
    },

    methods: {
        form_initial () {
            $('.selection.dropdown').dropdown();
            $('#add-description-'+this.pkey).form({
                inline: true,
                fields: {
                    short: {
                        identifier: 'short',
                        rules: [
                            {
                                type: 'maxLength[255]',
                                prompt: this.$t('short-length-error')
                            }
                        ]
                    },

                    long: {
                        identifier: 'long',
                        rules: [{
                            type: 'maxLength[4000]',
                            prompt: this.$t('long-length-error')
                        }]
                    }

                },
            });
        },

        validation () {
            $("#add-description-"+this.pkey).form("validate form");
            return $("#add-description-"+this.pkey).form("is valid");
        },

        update_emit () {
            if (this.short || this.long) {
                this.$emit('update', {
                    'pkey': this.pkey,
                    'locale': this.lang,
                    'short_description': this.short,
                    'long_description': this.long
                });
            }
        },

        mark_duplicate_lang () {
            $("#add-description-"+this.pkey).form('add errors', {lang: 'Duplicate language selected'});
            $("#add-description-"+this.pkey).form('add prompt', 'lang', 'Duplicate language selected');
        }
    },




}
</script>

<i18n>
{
  "en": {
      "language": "Language",
      "short": "Short Description (Max. 255 chars)",
      "long": "Long Description",
      "en": "English",
      "cn": "Chinese",
      "short-length-error": "The short description can't be longer than 255 characters",
      "long-length-error": "The long description can't be longer than 4000 characters",
      "remove": "Remove",
  },
    
  "cn": {
      "language": "语言",
      "en": "英语",
      "cn": "中文",
      "short": "简短介绍(最多127字)",
      "long": "详细介绍",
      "short-length-error": "简短介绍不可多于127字",
      "long-length-error": "详细介绍不可多于4000字",
      "remove": "移除",

  },
}
</i18n>