<template>
<div class="ui basic grid segment">
<div class="row">
<div class="column column-adjust">
    <house-cart-list @update="subtotal('housing', $event)"></house-cart-list>
</div>
</div>


<div class="right aligned row">
<div class="column">
    <span style="margin-right: 20px;"><b>{{$t('total')}}:</b></span>
    <span style="margin-right: 10px;"><b>€{{total.toFixed(2)}}</b></span>
    <div class="ui divider"></div>
</div>
</div>

<div class="centered row">
<div class="thirteen wide column">
    <payment-ui ref="paymentui"></payment-ui>
</div>
</div>

<div class="centered row" style="padding: 0;">
    <div class="thirteen wide column">
        <div class="ui negative message" v-if="pay_error">
            <ul class="list">
                <li>{{pay_error}}</li>
            </ul>
        </div>
    </div>
</div>

<div class="centered row">
    <div class="ui primary button" style="width: 100px" @click="pay" 
        :class="{'loading': loading, 'disabled': loading}">{{$t("pay")}}</div>
</div>

</div>
</template>

<script>
import HouseCartList from '@/apps/housing/components/HouseCartList.vue'
import PaymentUi from '@/components/PaymentUi.vue'

export default {

inject: ['DateTime'],

components: {
    HouseCartList,
    PaymentUi
},

data () {
    return {
        pay_error: '',
        subtotals: {},
        loading: false,
    }
},

computed: {
    total () {
        console.log("subtotals", this.subtotals);
        let sum = 0;
        for (var k in this.subtotals) {
            console.log(k, this.subtotals[k])
            sum += this.subtotals[k];
        }
        return sum;
    }
},

methods: {
    subtotal(k, v) {
        this.subtotals[k] = v;
    },

    async create_order () {
        let payload = {
            'housing': this.$store.state.housing.cart,
            'total': this.total
        }

        try{
            console.log("payload", payload);
            let response = await this.$store.dispatch('auth/createOrder', payload);
            return response.data;
        } catch (error) {
            if (error.response){
                if ('duplicated' in error.response.data)
                {   
                    let d = this.DateTime.fromISO(error.response.data.duplicated, {zone: "utc"}).toLocal();
                    this.pay_error = this.$t('duplicated', {'date': d.toLocaleString(this.DateTime.DATETIME_MED)});
                } else if ('conflict' in error.response.data) {
                    let d = this.DateTime.fromISO(error.response.data.conflict, {zone: "utc"}).toLocal();
                    this.pay_error = this.$t('conflict', {'date': d.toLocaleString(this.DateTime.DATETIME_MED)});
                } else if ('invalid' in error.response.data) {
                    let d = this.DateTime.fromISO(error.response.data.invalid, {zone: "utc"}).toLocal();
                    this.pay_error = this.$t('invalid', {'date': d.toLocaleString(this.DateTime.DATETIME_MED)});
                } else {
                    this.pay_error = error.response.data.message;
                }
            }
                
            else if (error.message)
                this.pay_error = error.message;
            else
                this.pay_error = this.$t('pay-error');
            return '';
        }
    },

    clear_cart () {
        this.$store.commit('housing/CLEAR_CART');
    },

    is_cart_empty () {
        if (this.$store.state.housing.cart.length==0) 
            return this.$t('empty-cart');
        else
            return '';
    },


    async pay () {
        this.loading = true;
        this.pay_error = '';
        this.pay_error = this.is_cart_empty();
        var ret = {};
        if (this.pay_error === '')
            ret = await this.create_order();
        if (ret.client_secret) {
            this.pay_error = await this.$refs['paymentui'].submit(ret.client_secret);
            if (this.pay_error === "") {
                try {
                    await this.$store.dispatch('auth/confirmOrder', {'order_id': ret.order_id});
                    this.clear_cart();
                    this.$router.push('/paysuccess');
                } catch (error) {
                    if (error.response)
                        this.pay_error = error.response.data.message;
                    else if (error.message)
                        this.pay_error = error.message;
                    else
                        this.pay_error = this.$t('pay-error');
                }
            } 
        }

        this.loading = false;
    },
}

}
</script>

<style scoped>
.column-adjust {
    padding: 0 !important;
}
</style>

<i18n>
{
"en": {
    "total": "TOTAL",
    "pay": "Pay",
    "pay-error": "Sorry, there is an error in your payment process. Please contact us or try again later.",
    "duplicated": "You have another booking on {date}.",
    "conflict": "Your viewing on {date} is not available.",
    "empty-cart": "The cart is empty.",
    "invalid": "Your booking on {date} is not valid.",
},

"cn": {
    "total": "总计",
    "pay": "支付",
    "pay-error": "很抱歉，您的支付有错误。请与我们联系或稍后再试。",
    "duplicated": "您在 {date} 已有预约",
    "conflict": "{date} 已被他人预订",
    "invalid": "很抱歉，您的{date}预约无效。",
    "empty-cart": "购物车为空",
}
}
</i18n>
