<template>
<div class="ui raised segment" style="min-width: 300px;
    border: 2px var(--primary-color) solid">
<div class="ui form" id="card-form">
    <div class="field">
        <label><span>{{$t('cardholder-name')}}</span></label>
        <input type="text" placeholder="Cardholder Name" v-model="card_holder" name="card_holder">
    </div>

    <div class="field">
        <label><span>{{$t('card')}}</span></label>
        <div id="card-element" style="
            border: 1px #e6e6e6 solid;
            border-radius: 4px;
            padding: 10px;"></div>
    </div>
</div>
</div>
</template>

<script>
export default {

    // inject: ['stripe'],
    inject: {
        nondemo_stripe: {
            from: 'stripe'
        }
    },

    data () {
        return {
            token: null,
            card: null,
            card_error: '',
            form_error: '',
            card_holder: null,
        }
    },

    computed: {
        stripe_elements () {
            return this.stripe.elements();
        },
    },

    mounted () {
        var style={
            base: {
                color: "#32325d",
            },
        };
        if (this.$store.state.auth.user.last_name == "Demo" && 
            (this.$store.state.auth.user.first_name == "Viewer" ||
            this.$store.state.auth.user.first_name == "Agent"))
            this.stripe = Stripe('pk_test_51JWHkHCwH0GavkEiXEBbgICyQag8DwdHnled8yYP9afVP4qlJQkSrMduw1CWGXWRVl7dpP7KFw5WbSM5xS0rjMhv00e5VhpDBY');
        else
            this.stripe = this.nondemo_stripe;

        this.card = this.stripe_elements.create('card');
        this.card.mount('#card-element', {style: style});
        this.card.on('change', (event)=>{
            if (event.error)
                this.card_error = event.error.message;
            else 
                this.card_error = '';
        });

    },

    beforeUnmount () {
        this.card.destroy();
    },

    methods: {
        validation () {
            if (this.card_error) {
                this.form_error = this.card_error;
                return this.form_error;
            }
            if (this.card_holder)
                return '';
            else {
                this.form_error = this.$t('card-holder');
                return this.form_error;
            }
        },

        async submit (client_secret) {
            this.form_error = '';
            if (this.validation())
                return this.form_error;

            try {
                let result = await this.stripe.confirmCardPayment(client_secret, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: this.card_holder
                        }
                    }
                });

                if (result.error) {
                    this.form_error = result.error.message;
                } else if (result.paymentIntent.status === 'succeeded') {
                    this.form_error = '';
                } else {
                    this.form_error = this.$t('form-error');
                }

                return this.form_error;

            } catch (error) {
                if (error.response)
                    this.form_error = error.response.data.message;
                else if (error.message)
                    this.form_error = error.message;
                else
                    this.form_error = this.$t('form-error');

                return this.form_error;
            }


        },

    }

}
</script>

<i18n>
{
    "en": {
        "cardholder-name": "Cardholder Name",
        "card": "Card",
        "card-holder": "Please fill the card holder's name",
        "form-error": "The credit card payment did not go through"
    },

    "cn": {
        "cardholder-name": "持卡者姓名",
        "card": "信用卡",
        "card-holder": "请填写持卡者姓名",
        "form-error": "信用卡支付有误"
    }
}
</i18n>