<template>
<div class="ui basic grid segment">

<div class="centered row" style="margin-top: 16px;">
<div>
    <h2 class="ui primary header">
        <i class="user circle icon"></i>{{username}}
    </h2>
    <label class="ui large label"><i class="primary mail icon"></i>{{$t('email')}}: {{email}}</label>
    <div style="margin-top: 8px;">
        <a href="javascript:;" @click="change_password">{{$t('change-password')}}</a>
    </div>
</div>
</div>

<div class="row">
<div class="column">
    <div class="ui basic segment" :class="{'loading': realtor_loading}">
    <div class="ui divider" style="margin-top: 0"></div>
        <!-- <div class="ui error message" v-if="fetch_error">
             <ul class="list"><li>{{fetch_error}}</li></ul>
        </div> -->

        <div class="center-item">
            <div class="ui primary button" style="width: 200px;" @click="$router.push('/housing/create-realtor')" 
                v-if="!is_active">Become a Viewing Agent</div>
            <div v-else class="ui primary button" style="width: 200px;"
                @click="dashboard" :class="{'loading': dashboard_loading, 'disabled': dashboard_loading}">
                Manage Stripe Account
            </div>
        </div>

        <div class="ui hidden divider"></div>
        <!-- <div v-if="last_step">
            <pay-deposit />
        </div> -->


        <div v-if="is_active">

            <div class="ui primary header">
                <i class="ui clock outline icon"></i>Time Adjustment
            </div>
            <div class="ui positive message" style="margin-bottom: 4px;">
                <i class="info circle icon"></i>
                You can set your unavailable time by locking a single or multiple time slots. 
                If you are going to have a holiday, you can uncheck all the workdays in the information section below. 
            </div>
            <calendar-admin :time_zone="realtor.time_zone" :work_days="realtor.work_days"
                :start_hour_iso="realtor.start_hour_iso"
                :end_hour_iso="realtor.end_hour_iso"
                :rest_hours="realtor.rest_hours"
                :booked_slots="booked_slots"
                :admin_slots="admin_slots"
                @update="calendar_update"
                ref="calendar_admin"></calendar-admin>
            <div class="ui negative message" v-if="calendar_error">
                <ul class="list">
                    <li>{{calendar_error}}</li>
                </ul>
            </div>
            <div class="center-item" style="margin-top: 15px; margin-bottom: 35px;">
                <div class="ui primary button" style="margin-right: 10px;" @click="lock('unlock')">Unlock</div>
                <div class="ui primary button" @click="lock('lock')">Lock</div>
            </div>

            <realtor-info :info="realtor"></realtor-info>

            <div class="ui primary header">
                <i class="angle double down icon"></i>Agent App Download
            </div>
            <div style="margin-top: 20px;">
                <a @click="download_app" class="ui centered medium image">
                    <img src="@/assets/android_download.png">
                </a>
            </div>

        </div>

    </div>
</div>
</div>

</div>
</template>

<script>
import mixin from '@/mixin'

import { mapState } from 'vuex';
import RealtorInfo from '@/apps/housing/components/RealtorInfo.vue'
import CalendarAdmin from '@/apps/housing/components/CalendarAdmin.vue';
// import PayDeposit from '../components/PayDeposit.vue';

export default {

inject: ['DateTime'],
mixins: [mixin],

components: {
    RealtorInfo,
    CalendarAdmin,
    // PayDeposit
},

data () {
   
    return {
        realtor: {},
        workdays: [],
        selected_dates: [],
        realtor_loading: false,
        dashboard_loading: false,
        lock_loading: false,
        calendar_error: "",
        booked_slots: [],
        admin_slots: [],
        // fetch_error: ""
    }
},

async created () {
    if (this.user==null){
        this.gToast(this.$t('login-first'));
        this.$router.push('/login');
        return;
    }

    try{
        this.realtor_loading = true;
        // this.fetch_error = "";
        if (this.user.agent) {
            let response = await this.$store.dispatch('housing/fetchRealtor');
            this.realtor = {...response.data};
            if (this.realtor.step == 4) {
                let slots = await this.$store.dispatch('housing/fetchSlots',
                    {'realtor_id': this.realtor.id});
                this.booked_slots = slots.data.booked_slots;
                this.admin_slots = slots.data.admin_slots;
            }

            // if ("deposit" in this.realtor) {
            //     this.fetch_error = this.$t('deposit-error');
            // }
        }
    } catch (error) {
        // this.gErrorHandler(error);
        this.realtor = {}
        this.booked_slots = [];
        this.admin_slots = [];
        // console.clear()
    } finally {
        this.realtor_loading = false;
    }
},

computed: {
    ...mapState('auth', ['user']),

    username () {
        if (this.user)
            return this.gUpperFirstChar(this.user.first_name) + ' ' + this.gUpperFirstChar(this.user.last_name);
        else
            return '';
    },

    email () {
        if (this.user)
            return this.user.email;
        else
            return '';
    },

    is_active () {
        if (this.realtor.step == 4)
            return true;
        else
            return false;
    },

    // is_active () {
    //     if (Object.keys(this.realtor).length==0)
    //         return false;
        
    //     if (this.realtor.is_active)
    //         return true;
        
    //     return false;
    // },

    // last_step () {
    //     if ((Object.keys(this.realtor).length>0) && (this.realtor.agent.is_active)
    //         && (this.realtor.is_active==null))
    //         return true;
    //     else
    //         return false;
    // }
},

methods: {
    async change_password () {
        try {
            const currentUser = Parse.User.current();
            if (!currentUser)
                return;
            await this.$store.dispatch("auth/resendPassword", {'email': currentUser.get('email')});
            $('body').toast({
                class: 'inverted olive',
                displayTime: 5000,
                closeIcon: true,
                message: this.$t('password-send'),
                position: 'top center'
            });
        
        } catch (error) {
            let message = "";
            if (error.response)
                message = error.response.data.message;
            else
                message = error.message;
            $('body').toast({
                class: 'orange',
                displayTime: 5000,
                closeIcon: true,
                message: message,
                position: 'top center'
            });
        }
    },

    calendar_update(event) {
        if (Array.isArray(event))
            this.selected_dates = event.map(el=>({
                'booking': el.toUTC().toJSDate(),
                'booking_iso': el.toISO(),
                'time_zone': el.zoneName
            }));
        else
            this.selected_dates = [{
                'booking': this.DateTime.fromJSDate(event).toUTC().toJSDate(),
                'booking_iso': this.DateTime.fromJSDate(event).toISO(),
                'time_zone': this.DateTime.fromJSDate(event).zoneName
            }]
    },

    async lock (act) {
        this.lock_loading = true;
        this.selected_dates = [];
        this.calendar_error = this.$refs['calendar_admin'].validation();
        if (!this.calendar_error) {
            this.$refs['calendar_admin'].update_emit();
        }
        if (this.selected_dates.length > 0) {
            try{
                await this.$store.dispatch('housing/adminBook', {
                    'dates': this.selected_dates,
                    'action': act
                });

                $('body').toast({
                    class: 'green',
                    displayTime: 5000,
                    closeIcon: true,
                    message: this.$t('lock-confirm', {'act': act+'ed'}),
                    position: 'top center'
                });

                this.$refs['calendar_admin'].single_update();

                let slots = this.admin_slots.map(
                    el=>this.DateTime.fromISO(el).toLocal());
                if (act=='unlock') {
                    this.selected_dates.map( (el)=>{
                        let slot = this.DateTime.fromISO(el.booking_iso).toLocal();
                        slots = slots.filter(elem=>!elem.equals(slot));
                    });
                }

                if (act=='lock') {
                    this.selected_dates.map( (el)=>{
                        let slot = this.DateTime.fromISO(el.booking_iso).toLocal();
                        slots = slots.filter(elem=>!elem.equals(slot));
                        slots.push(slot);
                    });
                }

                this.admin_slots = slots.map(el=>el.toISO());

            } catch (error) {
                if (error.response) {
                    let conflict_date = this.DateTime.fromISO(error.response.data.conflict);
                    this.calendar_error = this.$t('conflict-date', {'date': conflict_date.toLocaleString(this.DateTime.DATETIME_MED)});
                } else {
                    console.log(error);
                }
            } finally {
                this.lock_loading = false;
            }
        } else 
            this.lock_loading = false;
    },

    async dashboard () {
        try{
            this.dashboard_loading = true;
            let response = await this.$store.dispatch('auth/stripeDashboard');
            let link = response.data.url;
            window.open(link, "_blank");
        } catch (error) {
            console.log(error);
            this.gToast(this.$t('dashboard-error'));
        } finally {
            this.dashboard_loading = false;
        }
    },

    async download_app () {
        try {
            let res = await this.$store.dispatch('housing/housingApp');
            const href = URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'microhalo.apk');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (error) {
            this.gErrorHandler(error);
        }
    },
},


}
</script>

<style scoped>
.ui.button {
    width: 100px;
}

.ui.basic.segment {
    margin: 0;
    padding: 0;
}

a:hover {
    cursor: pointer;
}
</style>

<i18n>
{
    "en":{
        "dashboard-error": "Can't connect to dashboard. Please try again later!",
        "email": "Email",
        "deposit-error": "Can not collect shipping fee and deposit successfully.",
        "conflict-date": "{date} has been booked by your customer",
        "lock-confirm": "Selected dates have been successfully {act}.",
        "change-password": "change password",
        "password-send": "An email for password reset has been sent to you"
    },

    "cn": {
        "email": "邮箱",
        "password-send": "密码重置邮件已发送到你的邮箱"
    }
}
</i18n>
