<template>
<div class="ui form" id="agent-basic">

    <!-- <div class="inline field">
        <div class="ui checkbox">
            <input type="checkbox" name="is_company" v-model="is_company">
            <label><span><b>{{$t('iscompany')}}</b></span></label>
        </div>
        <div class="ui input" :class="{'disabled': !is_company}">
        <input type="text" :placeholder="$t('company-name')" name="company_name"
            v-model.trim="company_name" size="40">
        </div>
    </div> -->

    <div class="inline field">
        <div class="ui checkbox">
            <input type="checkbox"  name="has_correspondent" v-model="has_correspondent">
            <label><span><b>{{$t('other-correspondent')}}</b></span></label>
        </div>
        <div class="ui input" :class="{'disabled': !has_correspondent}">
            <input type="text" name="correspondent"
                :placeholder="$t('other-correspondent-placeholder')"
                v-model.trim="correspondent" size="40"/>
        </div>
    </div>


    <div class="required inline field">
        <label><span>{{$t('contact-number')}}</span></label>
        <input type="tel" :placeholder="$t('contact-number-placeholder')" name="phone" v-model.trim="phone" size="30">
    </div>

    <div class="required field">
        <label><span>{{$t('full-address')}}</span></label>
        <input type="text" :placeholder="$t('full-address-placeholder')" name="address" v-model="address">
    </div>

    <div class="inline six wide field disabled">
        <label><span>{{$t('from-company')}}</span></label>
        <div class="ui selection dropdown">
            <input type="hidden" name="company_id" v-model.lazy="company_id">
            <i class="dropdown icon"></i>
            <div class="default text">{{$t('from-company')}}</div>
        </div>
    </div>

    <div class="field">
        <label><span>Page URL</span></label>
        <input type="url" name="url" :placeholder="$t('url')" v-model.trim="url">
    </div>

    <div class="field" style="margin: 20px 0">
    <label><span>{{$t('workdays')}}</span></label>
        <div class="ui checkbox" v-for="val, day in week_days" :key="val"
            style="margin-right: 24px;">
            <input type="checkbox" :value="val" v-model="work_days">
            <label>{{day}}</label>
        </div>
    </div>

    <div class="field">
        <label><span>{{$t('working-hours')}}</span></label>

        <div class="fields">
        <div class="inline five wide field">
        <span>{{$t('start-hour')}}</span>
        <select class="ui selection dropdown" name="start_hour" v-model="start_hour">
            <option :value="v-1" :key="k" v-for="v, k in 24" >
                {{(v-1).toLocaleString('default', {minimumIntegerDigits: 2})}}:00
            </option>
        </select>
        </div>

        <div class="inline five wide field">
        <span>{{$t('end-hour')}}</span>
        <select class="ui selection dropdown" name="end_hour" v-model="end_hour">
            <option :value="v-1" :key="k" v-for="v, k in 24" >
                {{(v-1).toLocaleString('default', {minimumIntegerDigits: 2})}}:00
            </option>
        </select>        
        </div>

        </div>
    </div>

    <div class="nine wide field">
        <label><span>{{$t('rest-hours')}}</span></label>
        <div class="ui clearable multiple selection dropdown">
            <input type="hidden" name="rest_hours" v-model.lazy="rest_hours">
            <i class="dropdown icon"></i>
            <div class="default text">{{$t('select-hours')}}</div>
            <div class="menu">
                <div class="item" :key="k" v-for="v, k in 24" :data-value="v-1">
                    {{(v-1).toLocaleString('default', {minimumIntegerDigits: 2})}}:00
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {

props: ['basic'],

data () {
    return {
        // is_company: this.basic.is_company || false,
        // company_name: this.basic.company_name || "",
        company_id: 0,

        has_correspondent: this.basic.has_correspondent || false,
        correspondent: this.basic.correspondent || null,
        address: this.basic.address || "",
        phone: this.basic.phone || "",
        start_hour: this.basic.start_hour || 0,
        end_hour: this.basic.end_hour || 23,
        rest_hours: this.basic.rest_hours || "", 
        work_days: this.basic.work_days || [1,2,3,4,5],
        url: this.basic.url || "",

        week_days: {'Sunday': 0, 'Monday': 1, "Tuesday": 2, "Wednesday": 3,
            "Thursday": 4, "Friday": 5, "Saturday": 6},

    }
},

mounted () {
    this.form_initial();
},

updated () {
    this.form_initial();
},

watch: {
    'basic.has_correspondent' (n, o) {
        this.has_correspondent = n;
    },

    'basic.correspondent' (n, o) {
        this.correspondent = n;
    },

    'basic.address' (n, o) {
        this.address = n;
    },

    'basic.phone' (n, o) {
        this.phone = n;
    },

    'basic.start_hour' (n, o) {
        this.start_hour = n;
    },

    'basic.end_hour' (n, o) {
        this.end_hour = n;
    },

    'basic.rest_hours' (n, o) {
        this.rest_hours = n;
    },

    'basic.work_days' (n, o) {
        this.work_days = n;
    },

    'basic.url' (n, o) {
        this.url = n;
    }
},

methods: {
    form_initial () {
        $(".ui.dropdown").dropdown();
        $("#agent-basic").form({
            inline: true,

            fields: {
                // company_name: {
                //     identifier: 'company_name',
                //     depends: 'is_company',
                //     rules: [
                //         {type: 'empty', prompt: this.$t('companyname-empty-error')},
                //         {type: 'maxLength[64]', prompt: this.$t('companyname-length-error')}
                //     ]
                // },

                correspondent: {
                    identifier: 'correspondent',
                    depends: 'has_correspondent',
                    rules: [
                        {type: 'empty', prompt: this.$t('correspondent-empty-error')},
                        {type: 'maxLength[64]', prompt: this.$t('correspondent-empty-error')}
                    ]
                },

                phone: {
                    identifier: 'phone',
                    rules: [
                    {
                        type: 'regExp[/^[+]?[(]?[0-9]{1,4}[)]?[-.\\s0-9]{6,15}$/g]',
                        prompt: this.$t('phone-format-error')
                    }]
                },

                address: {
                    identifier: 'address',
                    rules: [
                        {type: 'empty', prompt: this.$t('address-empty-error')},
                        {type: 'maxLength[255]', prompt: this.$t('address-length-error')}
                    ]
                },

                url: {
                    identifier: 'url',
                    optional: true,
                    rules: [{type: 'url', prompt: this.$t('url-error')}]
                }

            }
        });
    },

    validation () {
        $('#agent-basic').form('validate form');
        return $('#agent-basic').form("is valid");
    },

    update_emit () {
        this.$emit('update', {
            // 'is_company': this.is_company,

            'has_correspondent': this.has_correspondent,
            'correspondent': this.correspondent,
            'phone': this.phone,
            'address': this.address,
            'work_days': this.work_days,
            'start_hour': this.start_hour,
            'end_hour': this.end_hour,
            'rest_hours': this.rest_hours,
            'url': this.url,
        });
    },
},

}
</script>

<i18n>
{
    "en": {
        // "iscompany": "Is a Company",
        // "company-name": "Company Name",

        "from-company": "From Company",
        "other-correspondent": "Other Correspondent",
        "other-correspondent-placeholder": "Full name different with signed name",
        "full-address": "Full Address",
        "full-address-placeholder": "Address, City, Country",
        "contact-number": "Contact Number",
        "contact-number-placeholder": "Phone number",
        "workdays": "Workdays",
        "working-hours": "Working Hours",
        "start-hour": "Start Hour",
        "end-hour": "End Hour", 
        "rest-hours": "Rest Hours (start at)",
        "select-hours": "Select Hours",
        "url": "External Personal Page",

        // "companyname-empty-error": "",
        // "companyname-length-error": "",
        "correspondent-empty-error": "",
        "correspondent-length-error": "",
        "phone-format-error": "",
        "address-empty-error": "",
        "address-length-error": "",
        "url-error": "",
    },

    "cn": {
        // "iscompany": "是否公司",
        // "company-name": "公司名称",

        "from-company": "来自公司",
        "other-correspondent": "联系人",
        "other-correspondent-placeholder": "联系人姓名",
        "full-address": "地址",
        "full-address-placeholder": "国家，城市，地址",
        "contact-number": "联系电话",
        "contact-number-placeholder": "联系电话",
        "workdays": "工作日",
        "working-hours": "工作时间",
        "start-hour": "上班",
        "end-hour": "下班", 
        "rest-hours": "休息时间(始于)",
        "select-hours": "选择钟点",
        "url": "个人介绍外部链接",

        // "companyname-empty-error": "请输入公司名称",
        // "companyname-length-error": "公司名称不可超过32个字",
        "correspondent-empty-error": "请输入联系人",
        "correspondent-length-error": "联系人姓名不可超过32个字",
        "phone-format-error": "联系电话输入错误",
        "address-empty-error": "请输入地址",
        "address-length-error": "地址不可超过127个字",
        "url-error": "URL地址错误"
    },
}
</i18n>