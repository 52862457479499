export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "upcoming-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Bookings"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing"])},
        "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
        "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent"])}
      },
      "cn": {
        "upcoming-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约等待"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标识号"])},
        "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观视"])},
        "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约"])},
        "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理"])}
      }
    }
  })
}
