<template>
<div class="ui basic grid segment" id="outer" :class="{'loading': loading}">
<div class="primary row" style="padding-bottom: 2px;">
<div class="column">
    <div class="ui inverted checkbox" style="margin-right: 20px;">
        <input type="checkbox" name="multiple" v-model="multiple" @change="multiple_clicked">
        <label><b>Multiple Selection</b></label>
    </div>
</div>
</div>

<div class="primary row" style="padding-top: 4px;">
<div class="column">

<div class="ui basic grid segment" id="inner">
<div class="row">
<div class="column">
    <div class="ui calendar" id="date-admin"></div>
</div>
</div>

<div class="row">
    <label class="ui fluid label"><div class="center-item">Hour</div></label>
</div>

<div class="row">
<div class="column">
    <div class="ui calendar" id="hour-admin"></div>
</div>
</div>

<div class="row">
    <label class="ui fluid label"><div class="center-item">Minute</div></label>
</div>

<div class="row">
<div class="column">
    <table class="ui celled unstackable table" id="minute-admin" @click="minute_select">
    <tbody>
    <tr class="center aligned">
        <td data-value="00" class="selectable">: 00</td>
        <td data-value="15" class="selectable">: 15</td> 
    </tr>
    <tr class="center aligned">
        <td data-value="30" class="selectable">: 30</td>
        <td data-value="45" class="selectable">: 45</td>
    </tr>
    </tbody>
    </table>
</div>
</div>

</div>
</div>
</div>
</div>
</template>

<script>
// function update_cell(date, container, uiclass) {
//     container.find('td').each(function () {
//         var cell = $(this);
//         var cellDate = cell.data('date');
//         if (!cellDate) {
//             return;
//         }
//         var disabled = cell.hasClass($.fn.calendar.settings.className.disabledCell);
//         var active = cell.hasClass($.fn.calendar.settings.className.activeCell);
//         // var focused = !!date1 && !!date2 && cellDate.getTime()==date.getTime();
//         cell.toggleClass(uiclass, !active && !disabled);

//     });
// }

function update_cell(date, container, uiclass, action=null) {
    container.find('td').each(function () {
        var cell = $(this);
        var cellDate = cell.data('date');
        if (!cellDate) {
            return;
        }
        var disabled = cell.hasClass($.fn.calendar.settings.className.disabledCell);
        // var active = cell.hasClass($.fn.calendar.settings.className.activeCell);
        var right = !!cellDate && !!date && cellDate.getTime()==date.getTime();
        if (!disabled && right){
            if (action==='remove')
                cell.removeClass(uiclass);
            else
                cell.addClass(uiclass);
        }
    });
}

import mixin from '@/mixin'

export default {
inject: ['DateTime'],
mixins: [mixin],

props:['work_days', 'start_hour_iso', 'end_hour_iso', 'rest_hours', 'time_zone', 'booked_slots', 'admin_slots'],

data () {
    return {
        disabled_datetimes: [],  // this is an intermediate variable for generating disabled_dates
        disabled_dates: [],  // this is used in dates-admin calendar
        disabled_times: [],  // this is used in minutes-admin calendar
        unavailable_slots: [],
        admin_booked: [],

        min_date: null,
        max_data: null,
        min_hour: null,
        max_hour: null,

        multiple: false,
        multiple_dates: [],
        multiple_hours: [],
        multiple_minutes: [],
        selected_slots: [],

        selected_date: null,
        selected_hour: null, 
        selected_minute: null,
        selected_js: null,

        event_dates: [{date: Date(), class: 'blue'}],
        loading: false,

    }
},

computed: {
    // focus_date () {
    //     var fd = $('#date-admin').find("span.link:not(.next):not(.prev)").text()
    //     return fd;
    // },

    // locale_start_hour () {
    //     return this.DateTime.fromISO(this.start_hour_iso, {zone: this.time_zone});
    // },

    // locale_end_hour () {
    //     return this.DateTime.fromISO(this.end_hour_iso, {zone: this.time_zone});
    // },

    // locale_disabled_days () {
    //     let days = [0,1,2,3,4,5,6];
    //     let workdays = this.work_days.split(',')
    //     let disabled = days.filter(elem=>!workdays.includes(elem));
    //     if (this.DateTime.local().zoneName == this.info.time_zone)
    //         return disabled;
    //     else
    //         return [];
    // },

    // locale_disabled () {
    //     let disabled = [];
    //     let start = this.DateTime.local().setZone(this.time_zone);
    //     let end = start.plus({days: 60});
    //     let rest_hours = this.rest_hours.split(',').map(elem=>parseInt(elem));
    //     let work_days = this.work_days.split(',').map(elem=>parseInt(elem));
    //     // let rest_days = days.filter(elem=>!work_days.includes(elem));

    //     for (let d=start; +d < +end; d=d.plus({hours: 1}))
    //     {
    //         // if (!rest_days.includes(d.weekday%7))
    //         //     continue;
    //         // if (work_days.includes(d.weekday%7))
    //         //     continue;
    //         // if (d.hour>=this.locale_start_hour.hour 
    //         //     && d.hour<this.locale_end_hour.hour && !rest_hours.includes(d.hour))
    //         //     continue;

    //         if(!rest_hours.includes(d.hour) && work_days.includes(d.weekday%7))
    //             continue;

    //         disabled.push(d.toLocal().set({
    //             minute: 0, second: 0, millisecond: 0}).toJSDate());
    //     }
    //     // console.log(disabled);
    //     return disabled;
    // }
},

created () {
    // let response = {'data': {
    //     'booked_slots': ['2021-10-26T16:30:00', '2021-10-27T17:00:00', 
    //         '2021-10-27T17:15:00', '2021-10-27T17:30:00', 
    //         '2021-10-27T17:45:00', '2021-10-27T18:15:00'],
    // }};
    // this.booked_slots = response.data['booked_slots'].map(
    //     item=>this.DateTime.fromISO(item, {zone: "utc"}).toLocal());

    // this.unavailable_slots = response.data['booked_slots'].map(
    //     item=>this.DateTime.fromISO(item, {zone: "utc"}).toLocal());
    
    // this.booked_slots2 = ['2021-10-26T16:30:00+01:00', '2021-10-27T17:00:00+01:00', 
    //     '2021-10-27T17:15:00+01:00', '2021-10-27T17:30:00+01:00', 
    //     '2021-10-27T17:45:00+01:00', '2021-10-27T18:15:00+01:00'];

    this.unavailable_slots = this.booked_slots.map(
        item=>this.DateTime.fromISO(item).toLocal());
    this.admin_booked = this.admin_slots.map(
        item=>this.DateTime.fromISO(item).toLocal());

    this.min_date = this.DateTime.local().startOf('day').plus({days: 2}).setZone(this.time_zone);
    this.max_date = this.min_date.plus({days: 60});
    this.min_hour = this.DateTime.fromISO(this.start_hour_iso, {zone: this.time_zone});
    this.min_hour = this.min_date.set({hour: this.min_hour.hour}).toLocal();
    // max_hour already minus 1 here. should be included in the rest of code
    this.max_hour = this.DateTime.fromISO(this.end_hour_iso, {zone: this.time_zone}).plus({hours: -1});
    this.max_hour = this.min_date.set({hour: this.max_hour.hour}).toLocal();
    let rest_hours = [];
    if (this.rest_hours)
        rest_hours = this.rest_hours.split(',').map(elem=>parseInt(elem));
    let work_days = [];
    if (this.work_days==null)
        work_days = [1,2,3,4,5];
    else
        work_days = this.work_days.split(',').map(elem=>parseInt(elem));

    for (let d=this.min_date; d<this.max_date; d=d.plus({hours: 1})) {
        if (!rest_hours.includes(d.hour) && work_days.includes(d.weekday%7))
            continue;
        this.disabled_datetimes.push( d.toLocal().set({minute: 0, second: 0, millisecond: 0}) );
    }

    for (let i=0; i<rest_hours.length; i++) {
        let rest_hour = this.min_date.set({hour: rest_hours[i]});
        this.disabled_times.push(rest_hour.toLocal().startOf('hour').toJSDate());
    }

    if (this.min_hour.hour > this.max_hour.hour)
    {
        for (let t=this.max_hour.hour+1; t<this.min_hour.hour; t=t+1)
            this.disabled_times.push(this.min_hour.set({hour: t}).toJSDate());
    }

    for (let d=this.min_date; d<this.max_date; d=d.plus({days: 1})) {
        let d_local = d.toLocal();
        let one_day = this.disabled_datetimes.filter(elem=>+elem.startOf('day')===+d_local.startOf('day'));
        if (one_day.length>=24) {
            this.disabled_dates.push(d_local.toJSDate());
        }
    }
},

beforeUnmount () {
    $('.ui.calendar').empty();
},

mounted () {
    $('#date-admin').calendar({
        'inline': true,
        'type': 'date',
        'startMode': 'day',
        'selectAdjacentDays': true,
        'disableYear': true,
        'disableMonth': true,
        'disabledDates': this.disabled_dates,
        'eventDates': this.event_dates,
        'minDate': this.min_date.toLocal().toJSDate(),
        'maxDate': this.max_date.minus({days: 1}).toLocal().toJSDate(),
        'onSelect': (date, mode)=>this.date_select(date, mode),
    });

    $('#hour-admin').calendar({
        'inline': true,
        'type': 'time',
        'startMode': 'hour',
        'disableMinute': true,
        'disabledDates': this.disabled_times,
        'minDate': this.min_hour.hour > this.max_hour.hour ? this.min_hour.startOf('day').toJSDate() : this.min_hour.toJSDate(),
        'maxDate': this.min_hour.hour > this.max_hour.hour ? this.max_hour.endOf('day').toJSDate() : this.max_hour.toJSDate(),
        'formatter': {
            time: function (date, settings) {
                if (!date) return '';
                var hour = date.getHours();
                var ampm = '';
                if (settings.ampm) {
                    ampm = ' ' + (hour < 12 ? settings.text.am : settings.text.pm);
                    hour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
                }
                return hour + ampm;    
            },
        },
        'onSelect': (date, mode)=>{
            this.hour_select(date, mode);
            return false;
        }

        // 'inline': true,
        // 'type': 'time',
        // 'startMode': 'hour',
        // 'disableMinute': true,
        // // 'minDate': this.DateTime.max(this.min_hour.toLocal(), this.DateTime.now()).toJSDate(),
        // 'minDate': this.min_hour.toLocal().toJSDate(),
        // 'maxDate': this.max_hour.toLocal().toJSDate(),
        // // 'disabledDates': this.disabled_datetimes.filter(elem=>
        // //     +(elem.startOf('day')) === +(this.DateTime.local().startOf('day')))
        // //     .map(elem=>elem.toJSDate()),
        // 'formatter': {
        //     time: function (date, settings) {
        //         if (!date) return '';
        //         var hour = date.getHours();
        //         var ampm = '';
        //         if (settings.ampm) {
        //             ampm = ' ' + (hour < 12 ? settings.text.am : settings.text.pm);
        //             hour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        //         }
        //         return hour + ampm;    
        //     },
        // },
        // 'onSelect': (date, mode)=>{
        //     this.hour_select(date, mode);
        //     return false;
        // }
    });


    // $('#date-admin span.prev').click(()=>{
    //     console.log("clicked");
    // })

    // $('#date-admin').find("span.link:not(.next):not(.prev)").on('change', ()=>{
    //     console.log("detected change");
    // });

    // console.log($('#date-admin').find("span.link:not(.next):not(.prev)").text())
},

// watch: {
//     focus_date (n, o) {
//         console.log("new", n);
//         console.log("old", o);
//     }
// },

watch: {
    admin_slots (n, o) {
        this.admin_booked = n.map(
            item=>this.DateTime.fromISO(item).toLocal()); 
    }
},

methods: {
    clear_cells (container, uiclass) {
        container.find('td').each(function () {
            var cell = $(this);
            cell.removeClass(uiclass);
        });    
    },

    has_color_cells (container, uiclass) {
        var has = false;
        container.find('td').each(function () {
            var cell = $(this);
            var disabled = cell.hasClass('disabled');
            if (!disabled && cell.hasClass(uiclass))
                has = true;
        });
        return has;    
    },

    update_minute_cells (minute, container, uiclass) {
        container.find('td').each(function () {
            var cell = $(this);
            var cellDate = Number(cell.data('value'));
            var disabled = cell.hasClass('disabled');
            var right = cellDate==minute;
            if (!disabled && right)
                cell.addClass(uiclass);
        });    
    },

    date_select (date, mode) {
        if (this.multiple)
            return this.date_select_multiple(date, mode);
        else
            return this.date_select_single(date, mode);
    },

    hour_select (date, mode) {
        if (this.multiple)
            return this.hour_select_multiple(date, mode);
        else
            return this.hour_select_single(date, mode);
    },

    minute_select (event) {
        if (this.multiple)
            return this.minute_select_multiple(event);
        else
            return this.minute_select_single(event);
    },

    multiple_clicked () {
        this.clear_cells($('#date-admin'), 'active');
        this.clear_cells($('#hour-admin'), 'active');
        this.clear_cells($('#minute-admin'), 'active');
        this.clear_cells($('#date-admin'), 'marked');
        this.clear_cells($('#hour-admin'), 'marked');
        this.clear_cells($('#minute-admin'), 'marked');
        this.clear_cells($('#hour-admin'), 'red disabled');
        this.clear_cells($('#minute-admin'), 'red disabled');
        this.clear_cells($('#hour-admin'), 'yellow');
        this.clear_cells($('#date-admin'), 'blue');
        this.clear_cells($('#hour-admin'), 'blue');
        this.clear_cells($('#minute-admin'), 'blue');
        this.multiple_dates = [];
        this.multiple_hours = [];
        this.multiple_minutes = [];
    },

    date_select_multiple(date, mode) {
        if ($('#date-admin .focus').hasClass('adjacent'))
            return true;

        this.clear_cells($('#date-admin'), 'active');
        let selected_date = this.DateTime.fromJSDate(date).startOf('day');
        let filtered = this.multiple_dates.filter(el=>!el.equals(selected_date));
        if (filtered.length == this.multiple_dates.length) {
            this.multiple_dates.push(selected_date);
            $('#date-admin .focus').addClass('blue');
        } else {
            this.multiple_dates = filtered;
            $('#date-admin .focus').removeClass('blue');
        }
        this.event_dates = this.multiple_dates.map(el=>({
            date: el.toJSDate(),
            class: 'blue'
        }))

        $('#date-admin').calendar('setting', 'eventDates', this.event_dates);
        return false;

        // console.log($('#date-admin'));
        // console.log($('#date-admin').calendar.metadata.month);

        // $('#date-admin').calendar('set dataKeyValue', $('#date-admin').calendar.settings.eventDates, this.event_dates, false);
        // $('#date-admin').calendar.settings.eventDates = this.event_dates;
        // console.log($('#date-admin'));
        // console.log(filtered.map(el=>el.toString()));
        // console.log(this.multiple_dates.map(el=>el.toString()));
    },

    date_select_single (date, mode) {
        this.selected_js = null;
        this.selected_hour = null;
        this.selected_minute = null;
        $('#hour-admin').calendar('set date', date);
        $('#hour-admin .active').removeClass('active');
        $('#hour-admin .marked').removeClass('marked');

        this.selected_date = this.DateTime.fromJSDate(date);
        let unavailable_hours = this.unavailable_slots.filter(
            item=>item.hasSame(this.selected_date, 'day'));
        let admin_hours = this.admin_booked.filter(
            item=>item.hasSame(this.selected_date, 'day'));

        for (let h=this.min_hour; h<=this.max_hour; h=h.plus({hours: 1})) {
            let slots_admin = admin_hours.filter(item=>item.hour==h.hour);
            let slots_user = unavailable_hours.filter(item=>item.hour==h.hour);
            if (slots_user.length == 4)
                update_cell(slots_user[0].set(
                    {minute: 0, second: 0, millisecond: 0}).toJSDate(), 
                    $('#hour-admin'), 'red disabled');
            else if (slots_user.length > 0) 
                update_cell(slots_user[0].set(
                    {minute: 0, second: 0, millisecond: 0}).toJSDate(), 
                    $('#hour-admin'), 'yellow');
            else if (slots_admin.length > 0)
                update_cell(slots_admin[0].set(
                    {minute: 0, second: 0, millisecond: 0}).toJSDate(), 
                    $('#hour-admin'), 'blue');
        }        

        // this.selected_date = this.DateTime.fromJSDate(date);
        // let booked_hours = this.booked_slots.filter(
        //     item=>item.hasSame(this.selected_date, 'day'));

        // // let locked_hours = this.locked_slots.filter();

        // for(let h=this.min_hour.toLocal().hour; h<=this.max_hour.toLocal().hour; h++) {
        //     let slots = booked_hours.filter(item=>item.hour==h);
        //     if (slots.length == 4)
        //         update_cell(slots[0].set(
        //             {minute: 0, second: 0, millisecond: 0}).toJSDate(), 
        //             $('#hour-admin'), 'red disabled');
        //     else if (slots.length > 0) {
        //         update_cell(slots[0].set(
        //             {minute: 0, second: 0, millisecond: 0}).toJSDate(), 
        //             $('#hour-admin'), 'yellow');
        //     }
        // }

        this.clear_cells($('#minute-admin'), 'red disabled');
        this.clear_cells($('#minute-admin'), 'blue');
        this.clear_cells($('#minute-admin'), 'active');
        this.clear_cells($('#minute-admin'), 'marked');
    },

    hour_select_multiple (date, mode) {
        if (this.multiple_dates.length==0) {
            this.message_box(this.$t('no-date'));
            return;
        }

        console.log("date", date);
        let selected_hour = this.DateTime.fromJSDate(date);
        let filtered = this.multiple_hours.filter(el=>!el.equals(selected_hour));
        if (filtered.length == this.multiple_hours.length) {
            this.multiple_hours.push(selected_hour);
            $('#hour-admin .focus').addClass('blue');
        } else {
            this.multiple_hours = filtered;
            $('#hour-admin .focus').removeClass('blue');
        }
        return false;
    },

    hour_select_single (date, mode) {
        this.selected_js = null;
        this.selected_minute = null;
        this.selected_hour = this.DateTime.fromJSDate(date);
        let unavailable_minutes = this.unavailable_slots.filter(
            item=>item.hasSame(this.selected_hour, 'hour'));
        let admin_minutes = this.admin_booked.filter(
            item=>item.hasSame(this.selected_hour, 'hour'));

        this.clear_cells($('#minute-admin'), 'red disabled');
        this.clear_cells($('#minute-admin'), 'blue');
        this.clear_cells($('#minute-admin'), 'active');
        this.clear_cells($('#minute-admin'), 'marked');

        admin_minutes.forEach((item)=>{
            this.update_minute_cells(item.get('minute'), $('#minute-admin'), 'blue');
        });

        unavailable_minutes.forEach((item)=>{
            this.update_minute_cells(item.get('minute'), $('#minute-admin'), 'red disabled');
        });

        // $('#hour-admin .grey').removeClass('grey');
        $('#hour-admin .marked.active').removeClass('active').removeClass('marked');
        let date_copy = new Date(date.getTime());
        date_copy.setMinutes(0,0,0);
        // update_cell(date_copy, $('#hour-admin'), 'grey');
        update_cell(date_copy, $('#hour-admin'), 'marked');
        update_cell(date_copy, $('#hour-admin'), 'active');
    },

    minute_select_multiple (event) {
        if (this.multiple_hours.length==0) {
            this.message_box(this.$t('no-hour'));
            return;
        }

        let selected_minute = Number(event.target.getAttribute('data-value'));
        let filtered = this.multiple_minutes.filter(el=>el!=selected_minute);
        if (filtered.length == this.multiple_minutes.length) {
            this.multiple_minutes.push(selected_minute);
            $(event.target).addClass('blue');
        } else {
            this.multiple_minutes = filtered;
            $(event.target).removeClass('blue');
        }
    },

    minute_select_single (event) {
        // remove first to clear other cells
        $('#minute-admin .marked.active').removeClass('active').removeClass('marked');
        this.selected_js = null;
        this.selected_minute = null;
        if (this.selected_date && this.selected_hour) {
            this.selected_minute = this.selected_hour.set(
                {minute: Number(event.target.getAttribute('data-value'))});
            this.selected_js = this.selected_minute.toJSDate();
        }
        event.target.classList.toggle('active')
        event.target.classList.toggle('marked');
    },

    single_update () {
        if (!this.multiple)
            $('#minute-admin .active.marked').toggleClass('blue');
            if (!$('#hour-admin .active.marked').hasClass('yellow'))
                if (this.has_color_cells($('#minute-admin'), 'blue'))
                    $('#hour-admin .active.marked').addClass('blue');
                else
                    $('#hour-admin .active.marked').removeClass('blue');
    },

    generate_slots () {
        let selected_slots = [];
        let multiple_minutes;
        let multiple_hours = [];
        if (this.multiple_minutes.length == 0)
            multiple_minutes = [0, 15, 30, 45];
        else
            multiple_minutes = this.multiple_minutes;

        let rest_hours = this.rest_hours.split(',').map(el=>parseInt(el));
        if (this.multiple_hours.length == 0) {
            if (this.min_hour.hour > this.max_hour.hour) {
                for (let d=this.max_hour.startOf('day'); d<this.max_hour; d=d.plus({hours: 1}) ) {
                    if (!rest_hours.includes(d.hour))
                        multiple_hours.push(d);
                }
                for (let d=this.min_hour; d<this.min_hour.endOf('day'); d=d.plus({hours: 1}) ) {
                    if (!rest_hours.includes(d.hour))
                        multiple_hours.push(d);
                }
            } else {
                for (let d=this.min_hour; d<=this.max_hour; d=d.plus({hours: 1})) {
                    if (!rest_hours.includes(d.hour))
                        multiple_hours.push(d);
                }
            }
        } else 
            multiple_hours = this.multiple_hours;

        for (let i=0; i<this.multiple_dates.length; i++) {
            let d = this.multiple_dates[i];
            for (let j=0; j<multiple_hours.length; j++) {
                d = d.set({hour: multiple_hours[j].hour});
                for (let k=0; k<multiple_minutes.length; k++) {
                    d = d.set({minute: multiple_minutes[k]});
                    selected_slots.push(d);
                }
            }

        }

        return selected_slots;
    },

    message_box (msg) {
        $('body').toast({
            class: 'orange',
            displayTime: 0,
            classActions: 'centered',
            // closeIcon: true,
            message: msg,
            position: 'top center',
            actions:	[{
                text: 'OK',
                class: 'primary',
                click: function() {return;}
            }]
        });
    },

    validation () {
        if (this.multiple) {
            let toast_msg = '';
            if ((this.multiple_minutes.length > 0) && (this.multiple_hours.length==0))
                toast_msg = this.$t('no-hour');
            if ((this.multiple_hours.length > 0) && (this.multiple_dates.length==0))
                toast_msg = this.$t('no-date');
            
            if (toast_msg) {
                // this.message_box(toast_msg);
                return toast_msg;
            }

            this.selected_slots = this.generate_slots();
            for (let i=0; i<this.selected_slots.length; i++) {
                let d = this.unavailable_slots.filter(el=>el.equals(this.selected_slots[i]));
                if (d.length > 0) {
                    return this.$t('conflict-date', {'date': d.toLocaleString(this.DateTime.DATETIME_MED)})
                }
            }
            return '';
        } else {
            if (this.selected_date === null)
                return this.$t('no-date');
            
            if (this.selected_hour === null)
                return this.$t('no-hour');

            if (this.selected_minute === null)
                return this.$t('no-minute');

            return '';
        }
    },

    update_emit () {
        if (this.multiple)
            this.$emit('update', this.selected_slots);
        else
            this.$emit('update', this.selected_js);
    },

    // update_emit () {
    //     if (!this.validation()) {
    //         console.log("update emit");
    //         this.$emit('update', {'selected_js': this.selected_js});
    //         console.log(this.selected_js);
    //         update_cell(this.selected_date.toJSDate(), $('#date-admin'), 'blue');
    //         update_cell(this.selected_hour.toJSDate(), $('#hour-admin'), 'blue');
    //         this.update_minute_cells(this.selected_minute.minute, $('#minute-admin'), 'blue');
    //     }
    // },

    // day_select_single (date, mode) {
    //     $('#hour-admin').calendar('set date', date);
    //     $('#hour-admin .active').removeClass('active');

    // },

    // on_select (date, mode) {
    //     let d = date.getTime();
    //     if (this.event_dates.includes(d)) {
    //         $('#date-admin .focus').removeClass('yellow');
    //         this.event_dates = this.event_dates.filter(item=>item!=d)
    //     } else {
    //         $('#date-admin .focus').addClass('yellow');
    //         this.event_dates.push(d);

    //         // $('#hour-admin').calendar('set date', date);
    //     }
    //     return false;
    // },

    // on_select (date, mode) {
    //     update_cell(date, $('#hour-admin'), 'red');
        
    //     $('#hour-admin').calendar('set date', date);
    //     $('#hour-admin .active').removeClass('active');
    //     var focus_date;
    //     focus_date = this.DateTime.fromJSDate(date).set({hour: 10}).toJSDate();
    //     // this.event_dates.push({
    //     //     date: focus_date,
    //     //     class: 'yellow'
    //     // });
    //     $('#hour-admin').calendar('set focusDate', focus_date, false, true);
    //     $('#hour-admin .focus').addClass('yellow');
    //     focus_date = this.DateTime.fromJSDate(date).set({hour: 13}).toJSDate();
    //     // this.event_dates.push({
    //     //     date: focus_date,
    //     //     class: 'yellow'
    //     // });
    //     // $('#hour-admin').calendar('eventDates', this.event_dates);
    //     $('#hour-admin').calendar('set focusDate', focus_date, false, true);
    //     $('#hour-admin .focus').addClass('yellow');

    // }

}



}
</script>

<style scoped>
#outer .row {
    padding: 5px 0;
}
#outer .column {
    padding: 0 5px;
}

#inner .row {
    margin: 0;
    padding: 0 1px;
    background-color: WhiteSmoke;
}

#inner .column {
    margin: 0;
    padding: 0;
}

#minute-admin td {
    padding: 6px !important;
}

.ui .checkbox {
    margin: 4px 6px;
}
</style>

<i18n>
{
"en": {
    "no-date": "No date is selected.",
    "no-hour": "No hour is selected.",
    "no-minute": "No minute is selected.",
    "conflict-date": "{date} has been booked by a viewer",
},

"cn": {
    "no-date": "请指定看房日期",
    "no-hour": "请指定看房时间",
    "no-minute": "请指定看房时间",
},
}
</i18n>