export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "primary-phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microdrones for Business"])},
        "secondary-phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["make active remote viewing possible"])}
      },
      "cn": {
        "primary-phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微型无人机的商业应用"])},
        "secondary-phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["让主动式远程观视成为可能"])}
      }
    }
  })
}
