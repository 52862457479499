<template>
    <div class="ui basic grid segment">        
        <div class="row">
        <div class="column">
            <housing-filter v-model="filtering_params" @searchClicked="search"></housing-filter>
        </div>
        </div>

        <div class="centered row">
        <div class="fourteen wide column">
        <div class="ui cards" v-for="house in houses" :key="house.id">
            <housing-item :admin=admin :item=house @click="$router.push({
                name: 'house', params: {'id': house.id} 
            })">
            </housing-item>
        </div>
        </div>
        </div>
        <div class="centered row">
        <pagination v-model="page" :total_pages="total_pages" @pageChanged="page_changed"></pagination>
        </div>
    </div>


</template>

<script>
import HousingItem from '../components/HousingItem.vue'
import HousingFilter from '../components/HousingFilter.vue'
import Pagination from '@/components/Pagination.vue'
import mixin from '@/mixin'

export default {
    mixins: [mixin],

    components: {
        HousingItem,
        HousingFilter,
        Pagination
    },

    data () {
        return {
            houses: [],
            page: 1,
            total_pages: 1,
            filtering_params: {},
            from_path:'',
            admin: false
        }
    },

    mounted () {
        if ("type" in this.$route.params){
            this.filtering_params = Object.assign({}, this.filtering_params, { "type": this.$route.params['type'] });
        }
        if ((this.from_path=='house') && (this.$store.state.housing.persistence.housing)) {
            this.page = this.$store.state.housing.persistence.housing.page;
            this.total_pages = this.$store.state.housing.persistence.housing.total_pages;
            this.filtering_params = this.$store.state.housing.persistence.housing.filtering_params;
            this.houses = this.$store.state.housing.persistence.housing.houses;            
            // this.from_path='';
        } else {
            this.search();
        }
    },

    methods: {
        page_changed () {
            this.search();
        },

        async search () {
            try {
                let response = await this.$store.dispatch('housing/houseFiltering', {
                    ...this.filtering_params,
                    'language': this.$i18n.locale,
                    'page': this.page,
                });
                this.houses = response.data.houses;
                this.total_pages = response.data.total_pages;
            } catch (error) {
                this.gErrorHandler(error);
            }
        },
    },

    beforeRouteLeave(to, from) {
        var local_persistence = {
            "to": to.name,
            "from": from.name,
            "page": this.page,
            "total_pages": this.total_pages,
            "filtering_params": this.filtering_params,
            "houses": this.houses
        } 
        this.$store.commit('housing/SET_PERSISTENCE', {'housing': local_persistence});
    },

    beforeRouteEnter(to, from, next) {
        next(vm=>{
            vm.$data.from_path = from.name;
        });
    }

}
</script>