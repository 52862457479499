<template>
<div class="ui basic segment">
    
    <img src="@/assets/mh.jpg" style="width: 100%;" />
    <div class="text-block">
        <p id="text-main">{{ $t('primary-phase') }}</p>
        <p id="text-sub">{{ $t('secondary-phase') }}</p>
    </div>
  
</div>

</template>

<style scoped>
@media screen and (min-width: 0px) and (max-width: 700px) {
  #text-main {
    font-size: 2.1vh;
  }
  #text-sub {
    font-size: 1.5vh;
    text-indent: 3em;
  }
}

@media screen and (min-width: 701px) {
  #text-main {
    font-size: 4vh;
  }
  #text-sub {
    font-size: 3.1vh;
    text-indent: 3em;
  }
}

.text-block {
  position: absolute;
  margin-top: -28%;
  margin-left: 5%;
  color: white;
  font-family: 'Russo One', Arial, Helvetica, sans-serif;
}

.ui.basic.segment {
  padding: 0;
  margin: 0;
}
</style>

<i18n>
{
  "en": {
    "primary-phase": "Microdrones for Business",
    "secondary-phase": "make active remote viewing possible",
  },
    
  "cn": {
    "primary-phase": "微型无人机的商业应用",
    "secondary-phase": "让主动式远程观视成为可能",
  },
}
</i18n>