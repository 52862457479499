import { createRouter, createWebHistory } from 'vue-router'

import FrontPage from '@/views/FrontPage.vue'
import Login from '@/views/Login.vue'
import SignUp from '@/views/SignUp.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import EmailConfirmation from '@/views/EmailConfirmation.vue'
import Cart from '@/views/Cart.vue'
import Profile from '@/views/Profile.vue'
import PaySuccess from '@/views/PaySuccess.vue'
import Bookings from '@/views/Bookings.vue'
import Viewings from '@/views/Viewings.vue'
import DepositSuccess from '@/views/DepositSuccess.vue'
import ResetSuccess from '@/views/ResetSuccess.vue'

const main_routes = [
    {path: '/', component: FrontPage},
    {path: '/login', component: Login},
    {path: '/signup', component: SignUp},
    {path: '/password-reset/:token', component: PasswordReset},
    {path: '/reset-success', component: ResetSuccess},
    // {path: '/password-reset', component: PasswordReset},
    {path: '/email-confirmation/:token', component: EmailConfirmation},
    {path: '/email-confirmation', component: EmailConfirmation},
    {path: '/cart', component: Cart},
    {path: '/profile', component: Profile},
    {path: '/paysuccess', component: PaySuccess},
    {path: '/deposit-success', component: DepositSuccess},
    {path: '/bookings', component: Bookings},
    {path: '/viewings', component: Viewings},
]

import housing_routes from '@/apps/housing/routes';
import ai_routes from '@/apps/ai/routes';
import Housing from '@/apps/housing/App.vue';
import Ai from '@/apps/ai/App.vue';

const routes = [
    ...main_routes,
    {path: '/housing', component: Housing, children: housing_routes},
    {path: '/ai', component: Ai, children: ai_routes},
]

let router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { top: 0 }
    }
})

export default router;