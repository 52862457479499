<template>
<div class="ui basic grid segment">
	<div class="row">
	<div class="column">
		<h1>{{$t('remote-viewing')}}</h1>
		<div class="ui divider"></div>
	</div>
	</div>
	
    <div class="stackable row">
    <div class="column">
        <p class="site-p" v-html="$t('house-viewing-description')"></p>
    </div>
    </div>

    <div class="stackable centered row">
	<div class="computer only six wide column">
		<video controls muted autoplay loop style="display: block; border: 1px solid #DCDCDC; width: 100%">
			<source src="@/assets/mh_banner.mp4" type="video/mp4">
		</video>
	</div>
	
	<div class="mobile tablet only fourteen wide column">
		<video controls muted autoplay loop style="display: block; border: 1px solid #DCDCDC; width: 100%">
			<source src="@/assets/mh_banner.mp4" type="video/mp4">
		</video>
	</div>    
    </div>


    <div class="stackable row" style="margin-top: 40px;">
    <div class="column">

        <div class="ui centered cards">
            <a class="card" v-for="h in ['house', 'hotel', 'holiday']" :key="h"  @click="view_more(h)">
                <div class="image" style="object-fit: cover;">
                    <img :src="require(`@/assets/${h}.jpg`)" />
                </div>
                <div class="ui attached primary button">{{$t('view-more-'+h)}}</div>
            </a>
        </div> 


    </div>
    </div>

<!-- 
        "house-viewing-description": "Viewing homes can be inconvenient and time consuming. \
        The images or videos shown online may not match the real places, which happens often when booking hotels or B&Bs. \
        Taking advantage of our patented technology Microhalo offers a very different viewing experience with its real-time remote viewing service. \
        Simply <b><a href='/' style='text-decoration: underline;'>logging in</a></b> our website, \
        a real-time house viewing can be done online on flexible time by just a few of clicks. \
        What you see is what you get. No more travel is needed, and \
        a better communication can be achieved during the remote viewing through the one-to-one video chat.",
 -->    
</div>

</template>

<script>
export default {
    methods: {
        view_more (h) {
            if (h=="house")
                this.$router.push('/housing');
            else if (h=="hotel")
                this.$router.push({name: 'housing', params: {'type': 'hotel'}});
            else if (h=="holiday")
                this.$router.push({name: 'housing', params: {'type': 'holiday'}});
        },
    }
}
</script>>


<style scoped>
.ui.basic.grid.segment {
	margin-top: 20px;
}

h1 {	
	font-family: 'Raleway', sans-serif;
	color: dimgrey;
	letter-spacing: 2px;
}

p:first-child:first-letter {
  color: var(--primary-color);
  float: left;
  font-family: Georgia, KaiTi;
  font-size: 55px;
  line-height: 40px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}
</style>

<i18n>
{
  "en": {
    "remote-viewing": "Remote Viewing",
    "house-viewing-description": "Viewing homes can be inconvenient and time consuming. \
        The images or videos shown online may not match the real places, which happens often when we book hotels or B&Bs. \
        Microhalo offers a very different real-time remote viewing experience with its patented technology. \
        Simply <b><a href='/' style='text-decoration: underline;'>logging in</a></b> our website, \
        a real-time home viewing can be done online with a flexible schedule by just a few clicks. \
        No more travel is needed, and what you see is what you get.",
    "view-more-house": "View houses",
    "view-more-hotel": "View hotels",
    "view-more-holiday": "View holiday homes",
  },
    
  "cn": {
    "remote-viewing": "远程观视",
    "house-viewing-description": "寻找新家总是费时费力。网上的图片和视频还可能和真实的地方不符。这种事情在我们网订酒店和民宿的时候时有发生。\
        借助 Microhalo 专利，Microhalo通过其实时远程观视服务提供了一种截然不同的看房体验。<b><a href='/' style='text-decoration: underline;'>登录</a></b>我们的网站， \
        只需几个点击您就可以随时随地轻松愉快地在线实时看房了。不再有舟车劳苦且所见即所得。远程观视里的一对一视频也将使得您和屋主的交流更加轻松。",
    "view-more-house": "查看房产",
    "view-more-hotel": "查看酒店",
    "view-more-holiday": "查看民宿",
  },
}
</i18n>
