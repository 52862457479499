<template>
<div class="ui basic grid segment">
<div class="centered row" style="margin: 40px 0 0 0;">
    <div class="sixteen wide mobile twelve wide tablet twelve wide computer column" v-if="server_error">
    <div class="ui negative icon message">
        <i class="red large exclamation circle icon"></i>
        <div class="content">
        <div class="header" style="margin-bottom: 12px;">
        {{ $t('email-confirmation') }}
        </div>
        <p>{{server_error}}
        <br>{{ $t('try-again') }}</p>
        </div>
    </div>
    </div>

    <suspense v-else>
    <template #default>
    <div class="sixteen wide mobile twelve wide tablet twelve wide computer column">
    <div class="ui icon message">
        <i class="olive large envelope open text icon"></i>
        <div class="content">
        <div class="header" style="margin-bottom: 12px;">
        Welcome to Microhalo!
        </div>
        <p v-html="$t('welcome-confirmation', {email: email})"></p>
        <i18n-t keypath="info" tag="p">
            <template #resend>
                <a class='ui blue label' @click='send_confirmation' style='margin: 0 6px 0 6px;'>{{$t('resend-label')}}</a>
            </template>
        </i18n-t>
        </div>
    </div>
    </div>
    </template>
    <template #fallback>
    </template>
    </suspense>

</div>
</div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {ref} from 'vue'

export default {
setup () {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const server_error = ref(null);
    const email = ref(null);
    const email_token = ref(null);

    if (route.params.token) {
        email_token.value = route.params.token;
        store.dispatch('auth/verifyEmail', {"email_token": route.params.token}).then( ()=>{
            return store.dispatch('auth/logOut').then( ()=>{
                $('body').toast({
                    class: 'inverted olive',
                    displayTime: 5000,
                    closeIcon: true,
                    message: "Your email has been confirmed. Please login!",
                    position: 'top center'
                });
                return router.push('/login');
            })
        }).catch((error)=>{
          if (error.response) {
            server_error.value = error.response.data.message;
          } else {
            console.log("Confirmation error", error);
            server_error.value = "Not succeed";
          }
        })
    } else {
        const currentUser = Parse.User.current();
        if (currentUser) {
            email.value = currentUser.get('email');
        } else {
            router.push('/login');
        }
    }

    return { server_error, email_token, email };


},

methods: {
    send_confirmation () {
        console.log("send confirmation func called");
        this.$store.dispatch('auth/sendConfirmation', {"email_token": this.email_token}).then(()=>{
            $('body').toast({
                class: 'inverted olive',
                displayTime: 5000,
                closeIcon: true,
                message: this.$t('toast-resend'), 
                position: 'top center',          
            });
        }).catch((error)=>{
          if (error.response) {
            this.server_error.value = error.response.data.message;
          } else {
            console.log("Resend error", error);
            this.server_error = this.$t('resend-error');
          }
        })
    }
}

}
</script>

<style scoped>
p {
    font-size: 110%;
    line-height: 150%;
}
</style>

<i18n>
{
"en":{
    "welcome-confirmation": "A confirmation email has been sent to <span><b>{email}</b></span>. \
Please follow the link in the email to activate your Microhalo account.",
    "info": "Click {resend} to resend the confirmation email.",
    "resend-label": "resend",
    "email-confirmation": "Email Confirmation",
    "try-again": "Please try again or contact us for help",
    "toast-resend": "A confirmation email has been resent.",
    "resend-error": "Resending confirmation error",
},

"cn":{
    "welcome-confirmation": "您的确认邮件已经发送到邮箱 <span><b>{email}</b></span>，请点击邮件内的链接激活您的Microhalo账号。",
    "info": "点击 {resend} 重新发送确认邮件",
    "resend-label": "重发",
    "email-confirmation": "邮件查核",
    "try-again": "请再试一次或与客服联系",
    "toast-resend": "新的确认邮件已经发送到您的邮箱",
    "resend-error": "重发错误",
}
}
</i18n>

