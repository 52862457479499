<template>
<div style="opacity: 0.7">
    <span v-if="full_screen" @click="exit_fullscreen"><i class="large inverted compress alternate link icon"></i></span>
    <span v-else @click="enter_fullscreen"><i class="large inverted expand alternate link icon"></i></span>

    <span v-if="video_on"><i class="large inverted video slash link icon"></i></span>
    <span v-else><i class="large inverted video link icon"></i></span>

    <span v-if="mic_on"><i class="large inverted microphone slash link icon"></i></span>
    <span v-else><i class="large inverted microphone link icon"></i></span>
    <!-- <span><i class="sync alternate link icon"></i></span> -->
</div>
</template>

<script>
export default {

data () {
    return {
        full_screen: false,
        video_on: true,
        mic_on: true,
    }
},

created () {
    ["fullscreenchange", "webkitfullscreenchange", 
        "mozfullscreenchange", "msfullscreenchange"].forEach(
            (evt) => document.addEventListener(evt, ()=>{
                if (this.full_screen)
                    this.full_screen = false;
                else
                    this.full_screen = true;
            })
        );
},

methods: {
    support_fullscreen () {
        const doc = document.documentElement;
        return ('requestFullscreen' in doc) ||
			('mozRequestFullScreen' in doc && document.mozFullScreenEnabled) ||
			('msRequestFullscreen' in doc && document.msFullscreenEnabled) ||
			('webkitRequestFullScreen' in doc)
    },

    enter_fullscreen () {
        if (!this.support_fullscreen()) {
            console.log("fullscreen not support");
            return;
        }
        
        // var elem = $('div#active-participant > div.participant.main', '#room')[0];
        var elem = $('#active-participant')[0]; //index 0 convert jquery object to html element
        console.log(elem);
		if (elem.requestFullscreen) 
			elem.requestFullscreen();
		else if (elem.mozRequestFullScreen)
			elem.mozRequestFullScreen();
		else if (elem.webkitRequestFullscreen)
			elem.webkitRequestFullscreen();
		else if (elem.msRequestFullscreen)
			elem.msRequestFullscreen();	

//        this.full_screen = true;
    },

    async exit_fullscreen () {
        if (document.fullscreenElement){
            try {
                if (document.exitFullscreen) {
                    await document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    await document.webkitExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    await document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    await document.msExitFullscreen();
                }
            } catch (error) {
                console.log("exit fullscreen", error)
            }

        }

//       this.full_screen = false;
    },

}



}
</script>

<style scoped>
span {
    width: 26px;
    height: 26px;
    display: inline-block;
    padding: 0px;
    margin: 0 0 0 8px;
    background-color: grey;
    border: 1px grey solid;  
}
</style>>
