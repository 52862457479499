<template>
    <div class="ui basic grid segment">

        <div class="centered row">
        <div class="fifteen wide column">
            <add-realtor-basic ref="add-realtor-basic" @update="get_basic($event)" :basic="basic"></add-realtor-basic>
        </div>
        </div>

        <div class="centered row">
        <div class="fifteen wide column">
            <add-images :max_photos=1 subfolder="profile">
                {{$t('image-uploader')}}
            </add-images>
        </div>
        </div>

        <div class="centered row">
        <div class="fifteen wide column">
            <add-description :pkey="0" ref="description-0" @update="get_descriptions($event)"
                v-bind="descriptions[0]"></add-description>
            <add-description v-for="cnt in langs_created" :key=cnt :ref="'description-'+cnt" :pkey=cnt 
                @remove="remove($event)" @update="get_descriptions($event)"        
                v-bind="descriptions[cnt]"></add-description>
            <div class="center-item" style="margin-top: -10px;">
                <a @click="add" href="javascript:;" style="text-decoration: underline;"><b>{{$t('add')}}</b></a>
            </div>
        </div>
        </div>

        <div class="centered row" style="padding: 0; margin: 0">
        <div class="fifteen wide column">
            <div class="ui error message" v-if="has_form_error">
            <ul class="list"><li>{{$t('has-form-error')}}</li></ul>
            </div>
            <slot></slot>
        </div>
        </div>

        <div class="centered row">
            <div class="ui primary button" @click="submit" 
                :class="{'loading': submit_loading, 'disabled': submit_loading}">{{submit_button}}</div>
        </div>

    </div>
</template>

<script>
import mixin from '@/mixin'

import AddRealtorBasic from '../components/AddRealtorBasic.vue'
import AddDescription from '@/components/AddDescription.vue'
import AddImages from '@/components/AddImages.vue'

export default {
    inject: ['DateTime'],
    mixins: [mixin],
    props: ['realtor', 'usage'],

    components: {
        AddRealtorBasic,
        AddDescription,
        AddImages,
    },

    data () {
        return {
            has_form_error: false,
            basic: {},
            
            langs: [...Array(this.$i18n.availableLocales.length).keys()].slice(1),
            langs_created: [],
            lang: this.$i18n.locale,

            // set input_locales and descriptions to empty every time clicking submit
            input_locales: [],
            descriptions: [],
            submit_loading: false,
            submit_button: this.$t('edit')
        }
    },

    mounted () {
        this.fill_realtor(this.realtor);
        this.langs_created = [...Array(this.input_locales.length).keys()].slice(1);
        if (this.usage=='edit')
            this.submit_button = this.$t('edit');
        else
            this.submit_button = this.$t('onboard-stripe');
    },

    methods: {
        fill_realtor(inputs) {
            if (inputs.correspondent!=null)
                this.basic['correspondent'] = inputs.correspondent;
            if (inputs.address!=null)
                this.basic['address'] = inputs.address;
            if (inputs.phone!=null)
                this.basic['phone'] = inputs.phone;
            if (inputs.start_hour!=null)
                this.basic['start_hour'] = this.DateTime.fromISO(
                    inputs.start_hour, {zone: "utc"}).toLocal().hour;
            if (inputs.end_hour!=null)
                this.basic['end_hour'] = this.DateTime.fromISO(
                    inputs.end_hour, {zone: "utc"}).toLocal().hour;
            if (inputs.rest_hours!=null)
                this.basic['rest_hours'] = inputs.rest_hours;
            if (inputs.work_days!=null)
                this.basic['work_days'] = inputs.work_days.split(',');
            if (inputs.url!=null)
                this.basic['url'] = inputs.url;

            if (inputs.i18n!=null)
                inputs.i18n.forEach((elem)=>{
                    this.input_locales.push(elem.locale);
                    this.descriptions.push({
                        'locale': elem.locale,
                        'short_description': elem.short_description,
                        'long_description': elem.long_description
                    });
                });
        },


        get_descriptions (input) {
            if(this.input_locales.includes(input.locale)) {
                this.$refs['description-'+input.pkey].mark_duplicate_lang();
                this.has_form_error = true;
            } else {
                this.descriptions.push(input);
                this.input_locales.push(input.locale);
            }
        },

        add () {
            let k = this.langs.shift();
            if (k) {
                this.langs_created.push(k);
            }
                
        },

        remove (k) {
            let index = this.langs_created.indexOf(k);
            if (index !== -1) {
                this.langs_created.splice(index, 1);
                this.langs.push(k);
            }
        },

        get_basic (inputs) {
            this.basic = inputs;
        },

        async submit () {
            this.submit_loading = true;
            this.descriptions = [];
            this.input_locales = [];
            this.has_form_error = false;

            if(this.$refs['add-realtor-basic'].validation()) {
                this.$refs['add-realtor-basic'].update_emit();
            } else {
                this.has_form_error = true;
            }

            if (this.$refs['description-0'].validation()) {
                this.$refs['description-0'].update_emit();    
            } else {
                this.has_form_error = true;
            }
            this.langs_created.forEach( (elem)=>{
                if (this.$refs['description-'+elem].validation()) 
                    this.$refs['description-'+elem].update_emit();
                else
                    this.has_form_error = true;
            });

            if (this.has_form_error) {
                this.submit_loading = false;
                return false;
            }
                

            let start_hour = this.DateTime.fromObject({hour: this.basic.start_hour});
            let end_hour = this.DateTime.fromObject({hour: this.basic.end_hour});

            try {
                let response = await this.$store.dispatch('housing/createRealtor', {
                    'basic': {
                        ...this.basic,
                        'start_hour': start_hour.toUTC(),
                        'end_hour': end_hour.toUTC(),
                        'start_hour_iso': start_hour.toISO(),
                        'end_hour_iso': end_hour.toISO(),
                        'time_zone': start_hour.zoneName
                    },

                    'descriptions' : this.descriptions,
                });

                if (this.usage != 'edit')
                    window.location.href = response.data['link'];
            } catch (error) {
                console.log(error);
                this.gErrorHandler(error);
                this.submit_loading = false;
            } 
        },
  
    }
}
</script>

<i18n>
{
    "en":{
        "add": "Add description in another language",
        "image-uploader": "Profile Image (Max. 1 photo)",
        "has-form-error": "You have form error!",
        "edit": "Update",
        "onboard-stripe": "Onboard Stripe",
    },
    "cn": {
        "add": "添加其他语言介绍",
        "image-uploader": "头像（1张）",
        "has-form-error": "您填的表格有错误!",
    }
}
</i18n>