<template>
<div class="ui form" id="house_filtering_form">
<div class="ui error message"></div>
<div class="fields" style="display:flex; justify-content: center;">
    <div class="three wide field">
        <div class="ui selection dropdown" id="type_dropdown" name="type_dropdown">
            <input type="hidden" name="housing_type" v-model.lazy="housing_type">
            <i class="primary dropdown icon"></i>
            <div class="default text">{{$t('type')}}</div>
            
            <div class="ui menu">
                <div class="item" data-value="">
                    {{$t('all')}}
                </div>
                <div class="item" data-value="renting">
                    {{$t('house-renting')}}
                </div>
                <div class="item" data-value="buying">
                    {{$t('house-buying')}}
                </div>
                <div class="item" data-value="sharing">
                    {{$t('house-sharing')}}
                </div>
                <div class="item" data-value="hotel">
                    {{$t('hotels-bnbs')}}
                </div>
                <div class="item" data-value="holiday">
                    {{$t('holiday-homes')}}
                </div>
            </div>
        </div>
    </div>

    <div class="three wide field">
        <div class="ui selection dropdown" id="country_dropdown">
            <input type="hidden" name="housing_country" v-model.lazy="housing_country">
            <i class="primary dropdown icon"></i>
            <div class="text">{{$t('ireland')}}</div>
            <div class="ui menu">
                <div class="item" data-value="1">{{ $t('ireland') }}</div>
            </div>
        </div>
    </div>

    <div class="three wide field">
        <div class="ui selection dropdown" id="city_dropdown">
            <input type="hidden" name="housing_city" v-model.lazy="housing_city">
            <i class="primary dropdown icon"></i>
            <div class="default text">{{$t('city')}}</div>
            <div class="ui menu">
                <div class="item" data-value="">{{$t('all')}}</div>
                <div class="item" :data-value="c.id" v-for="c in cities" :key="c.id">{{c.name}}</div>
            </div>
        </div>
    </div>


    <div class="six wide field">
        <div class="ui icon input">
        <input type="text" :placeholder="$t('keywords')" v-model.lazy="housing_keywords">
        <i class="search icon"></i>
        </div>
    </div>
    <div class="field" style="display:flex; justify-content: center;">
        <div class="ui primary button" @click="search">Search</div>
    </div>

</div>
</div>
</template>

<script>
// function house_filtering_validation (ctx) {
//     $('#house_filtering_form').form({
//         fields: {
//             housing_type: { identifier: 'housing_type',
//                 rules: [
//                     {
//                         type: 'empty',
//                         prompt: ()=>{
//                             return ctx.$t("housing-type-error");
//                         }
//                     }
//                 ]
//             },
//             housing_city: { identifier: 'housing_city',
//                 rules: [
//                     {
//                         type: 'empty',
//                         prompt: ()=>{
//                             return ctx.$t("housing-city-error");
//                         }
//                     }
//                 ]
//             }
//         }
//     });
// }


export default {
    props: ["modelValue"],

    data () {
        return {
            housing_type: "",
            housing_city: "",
            housing_country: '1',
            housing_keywords: "",
            cities: [],
        }
    },

    watch: {
        modelValue (newValue, oldValue) {
            this.housing_type = newValue.type || this.housing_type;
            this.housing_city = newValue.city_id || this.housing_city;
            this.housing_country = newValue.country_id || this.housing_country;
            this.housing_keywords = newValue.keywords || this.housing_keywords;
        }
    },

    created () {
        this.retrieve_cities();
        this.$emit("update:modelValue", {
            'type': this.housing_type,
            'city_id': this.housing_city,
            'country_id': this.housing_country,
            'keywords': this.housing_keywords,
        });
    },

    mounted () {
        $('#type_dropdown').dropdown();
        $('#city_dropdown').dropdown();
        // this.$nextTick(house_filtering_validation(this));
    },

    updated () {
        this.$nextTick(()=>$('#type_dropdown').dropdown('set selected', this.housing_type));
        this.$nextTick(()=>$('#city_dropdown').dropdown('set selected', this.housing_city));
    },

    methods: {
        async retrieve_cities () {
            try {
                let response = await this.$store.dispatch('housing/retrieveCities', {"country_id": 1});
                this.cities = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        search () {
            this.$emit("update:modelValue", {
                'type': this.housing_type,
                'city_id': this.housing_city,
                'country_id': this.housing_country,
                'keywords': this.housing_keywords,
            });
            this.$emit("searchClicked");

            // $("#house_filtering_form").form("validate form");
            // if ($("#house_filtering_form").form("is valid")) {
            //     this.$emit("update:modelValue", {
            //         'type': this.housing_type,
            //         'city_id': this.housing_city,
            //         'country_id': this.housing_country,
            //         'keywords': this.housing_keywords,
            //     });
            //     this.$emit("searchClicked");
            // }
        },
    }
}
</script>

<i18n>
{
  "en": {
      "housing-type-error": "Please select a housing type",
      "housing-city-error": "Please select a city",
      "type": "Type",
      "country": "Country",
      "ireland": "Ireland",
      "city": "City",
      "keywords": "Keywords",
      "house-renting": "Rent a House",
      "house-buying": "Buy a House",
      "house-sharing": "House Sharing",
      "hotels-bnbs": "Hotels & BnBs",
      "holiday-homes": "Holiday Homes",
      "all": "All"
  },
    
  "cn": {
      "housing-type-error": "请选择房屋类型",
      "housing-city-error": "请选择城市",
      "type": "类型",
      "country": "国家",
      "ireland": "爱尔兰",
      "city": "城市",
      "keywords": "关键字",
      "house-renting": "租房",
      "house-buying": "买房",
      "house-sharing": "合租",
      "hotels-bnbs": "酒店 & 民宿",
      "holiday-homes": "度假房",
      "all": "所有"
    },
}
</i18n>