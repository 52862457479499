<template>
<div class="ui basic grid segment">
	<div class="row">
	<div class="column">
		<h1>{{$t('smart')}}</h1>
		<div class="ui divider"></div>
	</div>
	</div>
	
    <div class="stackable row">
    <div class="column">
        <p class="site-p" v-html="$t('smart-description')">
        </p>
    </div>
    </div>


    <div class="stackable row">
    <div class="column">

        <div class="ui centered cards">
            <div class="card" v-for="h in ['crowd', 'roof' , 'anomaly']" :key="h">
                <div class="image" style="object-fit: cover;">
                    <img :src="require(`@/assets/${h}.jpg`)" />
                </div>

                <!-- <div class="content">
                <div class="ui placeholder">
                    <div class="header">
                    <div class="very short line"></div>
                    <div class="medium line"></div>
                    </div>
                    <div class="paragraph">
                    <div class="short line"></div>
                    </div>
                </div>
                </div> -->
            </div>

        </div> 
    </div>
    </div>

    <div class="centered row">
        <div class="ui primary button" @click="$router.push('/ai')">{{$t('more-ai')}}</div>
    </div>

</div>

</template>

<style scoped>
.ui.basic.grid.segment {
	margin-top: 20px;
}

h1 {	
	font-family: 'Raleway', sans-serif;
	color: dimgrey;
	letter-spacing: 2px;
}

p:first-child:first-letter {
  color: var(--primary-color);
  float: left;
  font-family: Georgia;
  font-size: 55px;
  line-height: 40px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}
</style>

<i18n>
{
  "en": {
    "smart": "Artificial Intelligence",
    "smart-description": "Microhalo's remote viewing is smart. The services are powered with AI technologies such as crowd counting, remote sensing, anomaly detection, etc. \
        Solutions can be either deployed in the cloud or run at edge with neural compute sticks, tailored to clients' need.", 
    "more-ai": "More AI Technologies"
  },
    
  "cn": {
    "smart": "人工智能",
    "smart-description": "人工智能技术让Microhalo的远程观视智能化。作为一家无人机公司，Microhalo拥有一系列人工智能技术诸如人群计数、遥感探测、异常检测等来提供各种基于无人机的后台服务。\
        解决方案可以部署在服务器上作为云端服务，也可以运行在神经计算棒上以满足边缘计算需求。",
    "more-ai": "了解更多"
  },
}
</i18n>