<template>
<span style="margin-left: 0.2em">
	<button class="blue circular ui icon button" @click="ccw" style="margin-right: 1.2em;">
		<i class="icon undo" style="padding: 0"></i>
	</button>
	<button class="green circular ui icon button" style="margin: 0 auto" 
        @keydown="pressControl" @click="forward" ref="joy">
		<i class="large icon angle double up" style="padding: 0"></i>
	</button>
	<button class="blue circular ui icon button" @click="cw" style="margin-left: 1.2em;">
		<i class="icon redo" style="padding: 0"></i>
	</button>
</span>
</template>

<script>
const { LocalDataTrack } = Twilio.Video;
import { markRaw } from 'vue';

export default {
	data () {
		return {
			track: null,
			timer: null,
			dataTrackPublished: {},
			room: null
		}
	},

	mounted () {
		this.$nextTick( () => this.$refs.joy.focus() );
	},

	created () {	
		const localDataTrack = new LocalDataTrack();
		this.track = markRaw(localDataTrack);
		this.dataTrackPublished.promise = new Promise( (resolve, reject)=>{
			this.dataTrackPublished.resolve = resolve;
			this.dataTrackPublished.reject = reject;
		});
	}, 	

	methods: {
		set_room (room) {
			if (room) {
				console.log("joystick room", room);
				room.localParticipant.on('trackPublished', publication=>{
					if (publication.track === this.track) {
						this.dataTrackPublished.resolve();
					}
				});
				room.localParticipant.on('trackPublicationFailed', (error, track)=>{
					if (track === this.track) {
						this.dataTrackPublished.reject(error);
					}
				});
				room.localParticipant.publishTrack(this.track);
			}
		},

		sendMessage (message) {
			this.dataTrackPublished.promise.then( ()=>{
				this.track.send(message);
			}).catch((error)=>console.log(error));
		},

		pressControl (k) {
			if (this.timer)
				return;
			this.keyControl(k);
			this.timer = setTimeout(()=>{this.timer=null;}, 500); 
		},

		keyControl (k) {
			if (k.code == "ArrowUp" || k.code == "Up") {
				this.forward();
			}
				
			if (k.code == "ArrowRight" || k.code == "Right") {
				this.cw();
			}
				
			if (k.code == "ArrowLeft" || k.code == "Left") {
				this.ccw();
			}
				
		},

		forward () {
			this.sendMessage("forward");
		},

		cw () {
			this.sendMessage("cw");
		},

		ccw () {
			this.sendMessage("ccw");
		},

		debounce (k) {
			if ( (k.code == "ArrowUp" || k.code == "Up" ||
				k.code == "ArrowRight" || k.code == "Right" ||
				k.code == "ArrowLeft" || k.code == "Left") && this.timer )
			{
				clearTimeout(this.timer);
				this.timer = null;
			}
		},

	},

}
</script>

<style scoped>

</style>
