export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
        "about-us-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microhalo is a microdrone company based in Dublin Ireland. \t\tThe company develops the telepresence platform for real-time active remote viewing. \t\tServices built upon the platform are powered with AI algorithms for various business. \t\tIf you have any queries, suggestions or complaints about our platform and services, please contact us. \t\tWe are happy to hear your opinions. \t\tIf you are interested in integrating Microhalo platform into your business \t\tor taking any Microhalo AI solutions in your service, please contact us for further discussion. \t\tOur contact information can be found at the bottom of this page."])}
      },
      "cn": {
        "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
        "about-us-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microhalo是一家总部位于爱尔兰都柏林的无人机公司。公司开发实时主动式远程观视网真系统。 \t\t人工智能技术与此系统结合为各种商业服务提供支持。如果您对我的平台和服务有任何意见或建议，请联系我们。我们很乐意倾听您的看法。\t\t如果您感兴趣将Microhalo的网真系统部署到您的商业服务中去， \t\t或是想使用任何Microhalo的人工智能解决方案， \t\t请于我们联系商谈。我们的联系方式可以在本网页的底端找到。"])}
      }
    }
  })
}
