<template>
<div class="ui basic grid segment">
	<div class="row">
	<div class="column">
		<h1>{{$t("about-us")}}</h1>
		<div class="ui divider"></div>
	</div>
	</div>

    <div class="stackable row">
    <div class="column">
        <p class="site-p" v-html="$t('about-us-description')">
        </p>
    </div>
    </div>  

</div>
</template>

<style scoped>
.ui.basic.grid.segment {
	margin-top: 20px;
}

h1 {	
	font-family: 'Raleway', sans-serif;
	color: dimgrey;
	letter-spacing: 2px;
}

p:first-child:first-letter {
  color: var(--primary-color);
  float: left;
  font-family: Georgia, KaiTi;
  font-size: 55px;
  line-height: 40px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}
</style>

<i18n>
{
  "en": {
	"about-us": "About Us",
	"about-us-description": "Microhalo is a microdrone company based in Dublin Ireland. \
		The company develops the telepresence platform for real-time active remote viewing. \
		Services built upon the platform are powered with AI algorithms for various business. \
		If you have any queries, suggestions or complaints about our platform and services, please contact us. \
		We are happy to hear your opinions. \
		If you are interested in integrating Microhalo platform into your business \
		or taking any Microhalo AI solutions in your service, please contact us for further discussion. \
		Our contact information can be found at the bottom of this page.",

  },
    
  "cn": {
	"about-us": "关于我们",
	"about-us-description": "Microhalo是一家总部位于爱尔兰都柏林的无人机公司。公司开发实时主动式远程观视网真系统。 \
		人工智能技术与此系统结合为各种商业服务提供支持。如果您对我的平台和服务有任何意见或建议，请联系我们。我们很乐意倾听您的看法。\
		如果您感兴趣将Microhalo的网真系统部署到您的商业服务中去， \
		或是想使用任何Microhalo的人工智能解决方案， \
		请于我们联系商谈。我们的联系方式可以在本网页的底端找到。",
  },
}
</i18n>