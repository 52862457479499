export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "shipping-deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drone Deposit and Shipping Fee"])},
        "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1"])},
        "pay-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, there is an error in your payment process. Please contact us or try again later."])}
      }
    }
  })
}
