export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "welcome-confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A confirmation email has been sent to <span><b>", _interpolate(_named("email")), "</b></span>. Please follow the link in the email to activate your Microhalo account."])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("resend")), " to resend the confirmation email."])},
        "resend-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resend"])},
        "email-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Confirmation"])},
        "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again or contact us for help"])},
        "toast-resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A confirmation email has been resent."])},
        "resend-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resending confirmation error"])}
      },
      "cn": {
        "welcome-confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您的确认邮件已经发送到邮箱 <span><b>", _interpolate(_named("email")), "</b></span>，请点击邮件内的链接激活您的Microhalo账号。"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["点击 ", _interpolate(_named("resend")), " 重新发送确认邮件"])},
        "resend-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重发"])},
        "email-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮件查核"])},
        "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再试一次或与客服联系"])},
        "toast-resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新的确认邮件已经发送到您的邮箱"])},
        "resend-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重发错误"])}
      }
    }
  })
}
