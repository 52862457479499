<template>
<div class="ui basic grid segment">
<div class="centered row" style="padding-top: 25px; padding-bottom: 0;">
    <router-link to='/housing/houses-admin' class="ui small primary button">Manage Houses</router-link>
</div>

<div class="ui divider"></div>

<div class="row">
<div class="column" >
    <h4 class="ui primary header">
        <i class="briefcase icon" style="margin-left: 10px;"></i>
        Remote Viewings
    </h4>

    <house-upcoming-viewings />
    <div style="margin-top: 15px;"></div>
    <house-past-viewings />
</div>
</div>
</div>
</template>

<script>
import HouseUpcomingViewings from '@/apps/housing/components/HouseUpcomingViewings.vue'
import HousePastViewings from '@/apps/housing/components/HousePastViewings.vue'

export default {
    components: {
        HouseUpcomingViewings,
        HousePastViewings
    },
}
</script>