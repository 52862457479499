<template>
<div>
	<div class="ui primary inverted borderless vertical fluid large menu" id="vertical-menu" style="margin: 0;">
    <a class="item" style="padding: 0;">
    <div class="ui fluid dropdown" style="padding: 17px;">
      <span id="mh_logo" style="margin-right:4px">Microhalo</span>
      <i class="sidebar icon"></i>
      <div class="menu" style="margin-left: 15px;">
          <router-link to="/" class="item" style="color: var(--primary-color) !important;"> 
            <i class="home icon"></i>
            {{$t('frontpage')}} 
          </router-link>
          <router-link to="/housing" class="item" style="color: var(--primary-color) !important;"> 
            <i class="binoculars icon"></i>
            {{$t('remote-viewing')}} 
          </router-link>
          <router-link to="/ai" class="item" style="color: var(--primary-color) !important;">
            <i class="robot icon"></i>
            {{$t('ai')}}
          </router-link>
          <div class="ui dropdown item" style="color: var(--primary-color) !important;">
            <input type="hidden" name="language" v-model.lazy="$root.$i18n.locale">
            <i class="globe icon"></i>
            {{$t('language')}}
            <i class="dropdown icon"></i>
              <div class="menu" id="lang_dropdown">
                  <div class="item" data-value="en" data-tooltip="English" data-position="bottom right">
                    <i class="gb flag" style="margin: 0"></i></div>
                  <div class="item" data-value="cn" data-tooltip="中文" data-position="bottom right">
                    <i class="cn flag" style="margin: 0"></i></div>
              </div>
          </div>
      </div>
    </div></a>

    <div v-if="user"> 
      <a class="item" style="padding: 0;">
        <div class="ui fluid dropdown" style="padding: 17px;">
          <b style="margin-right: 6px">{{first_name}}</b>
          <i class="th icon"></i> 
          <div class="menu" style="margin-left: 15px;">
            <router-link to="/profile" class="item" style="color: var(--primary-color) !important;">
              <i class="user circle outline icon"></i>{{$t('profile')}}
            </router-link>
            <router-link to="/bookings" class="item" style="color: var(--primary-color) !important;">
              <i class="calendar check outline icon"></i>{{$t('bookings')}}
            </router-link>
            <router-link to="/viewings" class="item" style="color: var(--primary-color) !important;" v-if="is_agent">
              <i class="laptop icon"></i>{{$t('viewings')}}
            </router-link>
            <a class="item" @click="logout" style="color: var(--primary-color) !important">
              <i class="sign out alternate icon" style="padding-left: 2px"></i>{{$t('logout')}}
            </a>
          </div>
        </div>
      </a>
      
      <!-- <a class="item" @click="logout"><b>{{$t('logout')}}</b></a> -->
      <router-link class="item" to="/cart">
        <b>{{$t('checkout')}}        
          <span class="ui circular blue label" style="margin-left: 0.4em;">
          <i class="ui shopping cart icon" style="margin-right: 4px;"></i>{{checkout_count}} &nbsp; (€{{checkout_sum.toFixed(2)}})
          </span>
        </b>
      </router-link>
    </div>
    <div v-else>
      <router-link to="/login" class="item"><b>{{$t('login')}}</b></router-link>
      <router-link to="/signup" class="item"><b>{{$t('signup')}}</b></router-link>
    </div>

  </div>

	<div class="ui primary inverted borderless large menu" id="horizontal-menu" style="margin: 0;">
		<router-link to="/" class="item" id="mh_logo">Microhalo</router-link>

    <div class="menu" id ="site-menu" style="margin-left: 5px">
      <router-link to="/housing" class="item">{{$t('remote-viewing')}}</router-link>
      <router-link to="/ai" class="item">{{$t('ai')}}</router-link>
    </div>
    
    <div class="right menu">
      <div class="right menu" v-if="user">
        <div class="ui dropdown item">
          <b>{{first_name}}</b>
          <div class="ui inverted primary menu">
            <router-link to="/profile" class="item" style="color: white !important">
              <i class="user circle outline icon"></i>{{$t('profile')}}
            </router-link>
            <router-link to="/bookings" class="item" style="color: white !important">
              <i class="calendar check outline icon"></i>{{$t('bookings')}}
            </router-link>
            <router-link to="/viewings" class="item" style="color: white !important" v-if="is_agent">
              <i class="laptop icon"></i>{{$t('viewings')}}
            </router-link>
            <a class="item" @click="logout" style="color: white !important">
              <i class="sign out alternate icon" style="padding-left: 2px"></i>{{$t('logout')}}
            </a>
          </div>
        </div>

        <!-- <a class="item" @click="logout"><b>{{$t('logout')}}</b></a> -->
        <router-link class="item" to="/cart">
          <b>{{$t('checkout')}}        
            <span class="ui circular blue label" style="margin-left: 0.4em;">
            <i class="ui shopping cart icon" style="margin-right: 4px;"></i>{{checkout_count}} &nbsp; (€{{checkout_sum.toFixed(2)}})
            </span>
          </b>
        </router-link>
      </div>
      <div class="right menu" v-else>
        <router-link to="/login" class="item"><b>{{$t('login')}}</b></router-link>
        <router-link to="/signup" class="item"><b>{{$t('signup')}}</b></router-link>
      </div>      

      <div class="ui top right dropdown item">
      <input type="hidden" name="language" v-model.lazy="$root.$i18n.locale">
      <div class="default text" :data-tooltip="language_tooltip" data-position="left center">
        <i class="gb flag"></i></div>
      <div class="ui inverted primary menu" id="lang_dropdown">
          <div class="item" data-value="en" data-tooltip="English" data-position="left center">
            <i class="gb flag" style="margin: 0"></i></div>
          <div class="item" data-value="cn" data-tooltip="中文" data-position="left center">
            <i class="cn flag" style="margin: 0"></i></div>
      </div>
      </div>

    </div>

	</div>

</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      logged_in: false,
    }
  },

	computed: {
    ...mapState('auth', ['user']),
    
    language_tooltip () {
      return this.$t('language');
    },

    first_name () {
      return this.user.first_name.charAt(0).toUpperCase() + this.user.first_name.slice(1);
    },

    checkout_count () {
      return this.$store.state.housing.cart.length;
    },

    checkout_sum () {
      return this.$store.state.housing.cart.reduce( 
        (total, item)=>total+item.price.price, 0);
    },

    is_agent () {
      return this.user.agent;
    },
	},

	updated () {
        $('.ui.dropdown').dropdown();
  },

	mounted () {
        $('.ui.dropdown').dropdown();
  },

  methods: {
    async logout () {
      await this.$store.dispatch("auth/logOut");
      this.$router.push('/');
    },
  }




}
</script>

<style scoped>
  @media screen and (min-width: 0px) and (max-width: 1000px) {
    #horizontal-menu {
      display: none !important;
    }
  }

  @media screen and (min-width: 1001px) {
    #vertical-menu {
      display: none !important;
    }
  }

	#mh_logo {
		font-family: 'Russo One', sans-serif;  
		letter-spacing: 2px; 
		font-size: 1.6em;
		text-shadow: 2px 2px 10px black;
		color: whitesmoke;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
	}

  #mh_logo:hover {
    background-color: inherit !important;
  }


</style>

<i18n>
{
  "en": {
    "login": "Log In",
    "signup": "Sign Up",
    "language": "Language",
    "remote-viewing": "Remote Viewing",
    "ai": "AI Solutions",
    "logout": "Logout",
    "checkout": "Checkout",
    "profile": "Profile",
    "bookings": "Bookings",
    "viewings": "Viewings",
    "frontpage": "Home",
  },
    
  "cn": {
    "login": "登陆",
    "signup": "注册",
    "language": "选择语言",
    "remote-viewing": "远程观视",
    "ai": "智能服务",
    "logout": "退出",
    "checkout": "购物车",
    "profile": "基本信息",
    "bookings": "个人预约",
    "viewings": "观视预订",
    "frontpage": "首页",
  },
}
</i18n>