<template>
<div class="ui basic grid segment">
<div class="row">
<div class="column">
    <calendar-user ref="calendar_user" :realtor_info="realtor" :booked_slots="booked_slots"
        @update="selected_date=$event" v-if="ready"></calendar-user>
</div>
</div>

<div class="row">
<div class="column">

    <div class="ui negative message" v-if="calendar_error">
    <ul class="list">
        <li>{{calendar_error}}</li>
    </ul>
    </div>

    <button class="ui button" @click="$router.push({
                name: 'house', params: {'id': housei18n_id} 
            })">Back</button>
    <button class="ui primary button" style="float: right" @click="add_to_cart">{{$t('order')}}</button>
</div>
</div>

    
</div>
</template>

<script>
import mixin from '@/mixin';
import CalendarUser from '../components/CalendarUser.vue';

export default {
inject: ['DateTime'],
mixins: [mixin],

components: {
    CalendarUser,
},

data () {
    return {
        housei18n_id: null,
        realtor_id: null,
        realtor: null,
        selected_date: null,
        calendar_error: "",
        booked_slots: [],
        ready: false
    }
},

async created () {
    let id = parseInt(this.$route.params.id);
    // make sure the user is logged in
    if (id && this.$store.state.auth.user) {
        this.ready = false;
        this.housei18n_id = id;
        if (this.$store.state.housing.current && this.$store.state.housing.current.id == this.housei18n_id) {
            this.realtor = this.$store.state.housing.current.house.realtor;
        } else {
            try {
                let response = await this.$store.dispatch(
                    'housing/fetchHouse', {'id': this.housei18n_id});
                this.$store.commit('housing/SET_CURRENT', response.data);
                this.realtor = this.$store.state.housing.current.house.realtor;
                // this.realtor = response.data.house.realtor;
            } catch (error) {
                this.gErrorHandler(error);
            }
        }

        try {
            let response = await this.$store.dispatch('housing/fetchSlots',
                {'realtor_id': this.realtor.id});

            // console.log("response data", response.data);
            if ('admin_slots' in response.data)
                this.booked_slots = response.data.booked_slots.concat(
                    response.data.admin_slots
                );
            else
                this.booked_slots = response.data.booked_slots;
            // console.log("booked slots", this.booked_slots);
        } catch (error) {
            this.gErrorHandler(error);
        }
        this.ready = true;
        // console.log("current realtor", this.$store.state.housing.current);
    } else {
        let msg = "";
        if (this.$store.state.auth.user === null)
            msg = this.$t('login-first');
        else
            msg = this.$t('no-house-id');

        $('body').toast({
            class: 'orange',
            displayTime: 5000,
            closeIcon: true,
            message: msg,
            position: 'top center'
        });
        this.$router.push('/housing');
    }
},

methods: {
    add_to_cart () {
        this.calendar_error = this.$refs['calendar_user'].validation();
        if (!this.calendar_error) {
            this.$refs['calendar_user'].update_emit();
            let selected_luxon = this.DateTime.fromJSDate(this.selected_date);
            let house = this.$store.state.housing.cart.find(
                item=>item.booking.getTime()==selected_luxon.toUTC().toJSDate().getTime());
            if (house)
                this.calendar_error = this.$t('duplicate-date');
            else {
                this.$store.commit('housing/ADD_CART', {
                    ...this.$store.state.housing.current,
                    'booking': selected_luxon.toUTC().toJSDate(),
                    'booking_iso': selected_luxon.toISO(),
                    'time_zone': selected_luxon.zoneName
                });
                this.$router.push('/cart');
            }   
        }
    },
},

}
</script>

<style scoped>
button {
    width: 100px;
}
</style>

<i18n>
{
"en": {
    "no-house-id": "No house code specified",
    "duplicate-date": "You have another booking with the same date and time.",
    "order": "Order"
},

"cn": {
    "no-house-id": "请指定要查看的房子",
    "duplicate-date": "您有其他看房预约在相同的时间段",
    "order": "预订"
},
}
</i18n>