export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "dashboard-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't connect to dashboard. Please try again later!"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "deposit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not collect shipping fee and deposit successfully."])},
        "conflict-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " has been booked by your customer"])},
        "lock-confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selected dates have been successfully ", _interpolate(_named("act")), "."])},
        "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change password"])},
        "password-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email for password reset has been sent to you"])}
      },
      "cn": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
        "password-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码重置邮件已发送到你的邮箱"])}
      }
    }
  })
}
