export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
        "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
        "pay-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, there is an error in your payment process. Please contact us or try again later."])},
        "duplicated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have another booking on ", _interpolate(_named("date")), "."])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your viewing on ", _interpolate(_named("date")), " is not available."])},
        "empty-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cart is empty."])},
        "invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your booking on ", _interpolate(_named("date")), " is not valid."])}
      },
      "cn": {
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
        "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
        "pay-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉，您的支付有错误。请与我们联系或稍后再试。"])},
        "duplicated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您在 ", _interpolate(_named("date")), " 已有预约"])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " 已被他人预订"])},
        "invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["很抱歉，您的", _interpolate(_named("date")), "预约无效。"])},
        "empty-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车为空"])}
      }
    }
  })
}
