export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "short-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short description"])},
        "long-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long description"])},
        "correspondent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondent"])},
        "contact-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact number"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "page-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page url"])},
        "workdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workdays"])},
        "start-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working hours start"])},
        "end-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working hours end"])},
        "rest-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest hours (start at)"])},
        "time-zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time zone"])},
        "drone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drone"])},
        "drone-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "registered-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered number"])},
        "realtor-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realtor Information"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "profile-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile image"])}
      },
      "cn": {
        "short-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["短介绍"])},
        "long-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长介绍"])},
        "correspondent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人"])},
        "contact-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
        "page-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网页地址"])},
        "workdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作日"])},
        "start-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上班"])},
        "end-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下班"])},
        "rest-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休息时间"])},
        "time-zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时区"])},
        "drone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无人机"])},
        "drone-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄送"])},
        "registered-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册号"])},
        "realtor-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经纪人信息"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
        "profile-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["头像"])}
      }
    }
  })
}
