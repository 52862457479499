<template>
  <div class="ui basic grid segment">
    <div class="centered row" style="margin: 40px 0 0 0">
      <h2 class="ui grey header">{{ $t("sign-up") }}</h2>
    </div>

    <div class="centered row">
      <div
        class="sixteen wide mobile eight wide tablet six wide computer column"
      >
        <form class="ui form" id="signup_form" @keyup.enter.prevent="signup">
          <div class="two fields">
            <div class="field">
              <label
                ><span>{{ $t("first-name") }}</span></label
              >
              <div class="ui large input">
                <input
                  type="text"
                  name="first_name"
                  :placeholder="$t('first-name')"
                  v-model.trim="first_name"
                />
              </div>
            </div>
            <div class="field">
              <label
                ><span>{{ $t("last-name") }}</span></label
              >
              <div class="ui large input">
                <input
                  type="text"
                  name="last_name"
                  :placeholder="$t('last-name')"
                  v-model.trim="last_name"
                />
              </div>
            </div>
          </div>

          <div class="field">
            <label
              ><span>{{ $t("e-mail") }}</span></label
            >
            <div class="ui large input">
              <input
                type="text"
                name="email"
                :placeholder="$t('e-mail')"
                v-model.trim="email"
              />
            </div>
          </div>

          <div class="field">
            <label
              ><span>{{ $t("password") }}</span></label
            >
            <div class="ui large input">
              <input
                type="password"
                name="password"
                :placeholder="$t('password')"
                v-model.trim="password"
              />
            </div>
          </div>
          <div class="field">
            <label
              ><span>{{ $t("confirm-password") }}</span></label
            >
            <div class="ui large input">
              <input
                type="password"
                name="confirm_password"
                :placeholder="$t('confirm-password')"
                v-model.trim="confirm_password"
              />
            </div>
          </div>

          <div class="ui error message"></div>
          <div class="ui negative message" v-if="server_error">
          <ul class="list">
            <li>{{server_error}}</li>
          </ul>
          </div>

          <div class="center-item">
            <div class="ui primary button" @click="signup">
              {{ $t("sign-up-button") }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
function signup_validation(ctx) {
  $("#signup_form").form({
    rules: {
      custom_password: function (value) {
        return (
          value &&
          value.length >= 8 &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value)
        );
      },
    },

    fields: {
      first_name: {
        identifier: "first_name",
        rules: [
          {
            type: "empty",
            prompt: () => {
              return ctx.$t("first-name-error");
            },
          },
        ],
      },
      last_name: {
        identifier: "last_name",
        rules: [
          {
            type: "empty",
            prompt: () => {
              return ctx.$t("last-name-error");
            },
          },
        ],
      },
      email: {
        identifier: "email",
        rules: [
          {
            type: "email",
            prompt: () => {
              return ctx.$t("email-error");
            },
          },
        ],
      },
      password: {
        identifier: "password",
        rules: [
          {
            type: "custom_password",
            prompt: (value) => {
              if (!value || value.length == 0) return ctx.$t("password-empty");
              else if (value.length > 0 && value.length < 8)
                return ctx.$t("password-short");
              else return ctx.$t("password-invalid");
            },
          },
        ],
      },
      confirm_password: {
        identifier: "confirm_password",
        rules: [
          {
            type: "match[password]",
            prompt: () => {
              return ctx.$t("confirm-password-error");
            },
          },
        ],
      },
    },
  });
}

export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      server_error: ""
    };
  },

  mounted() {
    $("#signup_form").submit(e=>e.preventDefault());
    this.$nextTick(signup_validation(this));
  },

  methods: {
    async signup() {
      this.server_error = "";
      await this.$store.dispatch("auth/logOut");
      $("#signup_form").form("validate form");
      if ($("#signup_form").form("is valid")) {
        var user = new Parse.User();
        user.set("username", this.email.toLowerCase());
        user.set("password", this.password);
        user.set("email", this.email.toLowerCase())

        try {
          await user.signUp();
        } catch (error) {
          Parse.User.logOut();
          if (error.message.indexOf("already exists")>-1)
            this.server_error = this.$t('signup-exists');
          else
            this.server_error = error.message;
          return;
        }
 
        try {
          // const user = await Parse.User.logIn(this.email.toLowerCase(), this.password);
          var token = user.getSessionToken();
          var inputs = {
            first_name: this.first_name,
            last_name: this.last_name,
          };
          await this.$store.dispatch("auth/signUp", { token, inputs });
          await this.$router.push('/email-confirmation');
        } catch (error) {
          if (error.response) {
            this.server_error = error.response.data.message;
          } else {
            this.server_error = error.message;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
/* label span {
  color: var(--primary-color);
} */
</style>


<i18n>
{
    "en":{
        "sign-up": "Sign Up",
        "sign-up-button": "Sign Up",
        "first-name": "First name",
        "last-name": "Last name",
        "e-mail": "E-mail",
        "password": "Password",
        "confirm-password": "Confirm password",
        "signup-exists": "The account already exists. Please login instead.",

        "first-name-error": "Please enter your first name",
        "last-name-error": "Please enter your last name",
        "email-error": "Plase enter a valid email",
        "password-empty": "Please enter the password",
        "password-short": "The password must be at least 8 characters",
        "password-invalid": "The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number",
        "confirm-password-error": "The password and confirm password do not match",

    },
    "cn":{
        "sign-up": "注册新用户",
        "sign-up-button": "注册",
        "first-name": "名",
        "last-name": "姓",
        "e-mail": "邮箱",
        "password": "密码",
        "confirm-password": "确认密码",
        "signup-exists": "用户已存在, 请登录",

        "first-name-error": "请输入您的名",
        "last-name-error": "请输入您的姓",
        "email-error": "请输入有效邮箱地址",
        "password-empty": "请输入密码",
        "password-short": "密码不得短于8个字符",
        "password-invalid": "密码至少含有一个大写字母，一个小写字母和一个数字",
        "confirm-password-error": "密码与确认密码不相符",
    }
}
</i18n>