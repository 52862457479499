export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
        "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "remote-viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Viewing"])},
        "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Solutions"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
        "viewings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewings"])},
        "frontpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
      },
      "cn": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登陆"])},
        "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择语言"])},
        "remote-viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远程观视"])},
        "ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能服务"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购物车"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
        "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人预约"])},
        "viewings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观视预订"])},
        "frontpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])}
      }
    }
  })
}
