export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
        "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])}
      },
      "cn": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
        "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人预约"])},
        "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作预约"])}
      }
    }
  })
}
