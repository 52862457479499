export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add description in another language"])},
        "image-uploader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Image (Max. 1 photo)"])},
        "has-form-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have form error!"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "onboard-stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboard Stripe"])}
      },
      "cn": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加其他语言介绍"])},
        "image-uploader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["头像（1张）"])},
        "has-form-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您填的表格有错误!"])}
      }
    }
  })
}
