<template>
<div class="ui form" :id="'addhouse-description-'+pkey">
    <div class="required inline field">
    <label><span>{{$t("language")}}</span></label>
    <select class="ui selection dropdown" v-model.lazy="lang" name="lang" :id="'lang'+pkey">
    <option :value="lg" v-for="lg in $root.$i18n.availableLocales" :key="lg">
        {{ $t(lg) }}
    </option>
    </select>
    <a @click="$emit('remove', pkey);" class="ui blue label" style="float:right; bottom: -4px; position:relative;" v-if="pkey">
        {{$t('remove')}}
    </a>
    </div>
    <div class="required field">
        <label><span>{{$t("address")}}</span></label>
        <div class="ui input">
        <input type="text" name="address"
            v-model.trim="address">                  
        </div>
    </div>

    <div class="field">
        <label><span>{{$t("short")}}</span></label>
        <textarea rows=2 name="short" v-model.trim="short"></textarea>               
    </div>

    <div class="field">
        <label><span>{{$t("long")}}</span></label>
        <textarea name="long" v-model.trim="long"></textarea>
    </div>

    <div class="ui divider"></div>

</div>
</template>

<script>
export default {
    props: ['pkey', 'description'],

    data () {
        return {
            lang: this.$i18n.locale,
            address: '',
            short: '',
            long: ''
        }
    },

    computed: {
    },

    mounted () {
        $('.selection.dropdown').dropdown();
        $('#addhouse-description-'+this.pkey).form({
            inline: true,
            fields: {
                address: {
                    identifier: 'address',
                    rules:[
                        {
                            type: 'empty',
                            prompt: this.$t('address-empty-error')
                        },
                        {
                            type: 'maxLength[255]',
                            prompt: this.$t('address-length-error')
                        }
                    ]
                },

                short: {
                    identifier: 'short',
                    rules: [
                        {
                            type: 'maxLength[255]',
                            prompt: this.$t('short-length-error')
                        }
                    ]
                },

            },
        });

        if (this.description) {
            this.lang = this.description.locale;
            this.$nextTick(()=>$('#lang'+this.pkey).dropdown('set selected', this.lang));
            this.address = this.description.address;
            this.short = this.description.short_description;
            this.long = this.description.long_description;
        }

    },

    created () {
        this.retrieve_cities();
    },

    watch: {
        lang (newValue, oldValue) {
            $("#addhouse-description-"+this.pkey).form('remove errors', {lang: 'Duplicate language selected'});
            $("#addhouse-description-"+this.pkey).form('remove prompt', 'lang');
        },

        'description.locale' (n, o) {
            this.lang = n;
            this.$nextTick(()=>$('#lang'+this.pkey).dropdown('set selected', this.lang));
        },

        'description.address' (n, o) {
            this.address = n;
        },

        'description.short_description' (n, o) {
            this.short = n;
        },

        'description.long_description' (n, o) {
            this.long = n;
        },
    },

    methods: {
        async retrieve_cities () {
            try {
                let response = await this.$store.dispatch('housing/retrieveCities', {"country_id": 1});
                this.cities = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        validation () {
            $("#addhouse-description-"+this.pkey).form("validate form");
            return $("#addhouse-description-"+this.pkey).form("is valid");
        },

        update_emit () {
            this.$emit('update', {
                'pkey': this.pkey,
                'locale': this.lang,
                'address': this.address,
                'short_description': this.short,
                'long_description': this.long
            });
        },

        mark_duplicate_lang () {
            $("#addhouse-description-"+this.pkey).form('add errors', {lang: 'Duplicate language selected'});
            $("#addhouse-description-"+this.pkey).form('add prompt', 'lang', 'Duplicate language selected');
        }
    },




}
</script>

<i18n>
{
  "en": {
      "language": "Language",
      "address": "Address (Max. 255 chars)",
      "short": "Short Description (Max. 255 chars)",
      "long": "Long Description",
      "en": "English",
      "cn": "Chinese",
      "address-empty-error": "Please provide the address of the house",
      "address-length-error": "The address can't be longer than 255 characters",
      "short-length-error": "The short description can't be longter than 255 characters",
      "remove": "Remove",
  },
    
  "cn": {
      "language": "语言",
      "en": "英语",
      "cn": "中文",
  },
}
</i18n>