export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking ID"])},
        "booked-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked time"])},
        "calendar-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing rescheduling can not be done. Please try again later."])},
        "reschedule-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your viewing is rescheduled"])}
      },
      "cn": {
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标识号"])},
        "booked-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约时间"])}
      }
    }
  })
}
