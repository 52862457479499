<template>
<div class="ui basic grid segment">
    <div class="centered row" style="margin: 40px 0 0 0;">
        <h2 class="ui grey header">{{$t("password-reset")}}</h2>
    </div>

    <div class="centered row">
        <div class="sixteen wide mobile eight wide tablet six wide computer column">
        <form class="ui form" id="reset_form" @keyup.enter.prevent="reset">
            <div class="field">
                <label><span>{{$t("password")}}</span></label>
                <div class="ui large input">
                    <input type="password" name="password" 
                        :placeholder="$t('password')" v-model.trim="password">
                </div>
            </div>

            <div class="field">
                <label><span>{{$t("confirm-password")}}</span></label>
                <div class="ui large input">
                    <input type="password" name="confirm_password" 
                        :placeholder="$t('confirm-password')" v-model.trim="confirm_password">            
                </div>
            </div>
            <div class="ui error message"></div>
            <div class="ui negative message" v-if="server_error">
                <ul class="list">
                    <li>{{server_error}}</li>
                </ul>
            </div>

            <div class="center-item">
            <div class="ui primary button" @click="reset">
                {{ $t("reset-button") }}
            </div>
            </div>
        </form>
        </div>
    </div>
</div>
</template>

<script>

function reset_validation(ctx) {

$("#reset_form").form({
rules: {
    custom_password: function (value) {
    return (
        value &&
        value.length >= 8 &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value)
    );
    },
},

fields: {
    password: {
    identifier: "password",
    rules: [
        {
        type: "custom_password",
        prompt: (value) => {
            if (!value || value.length == 0) return ctx.$t("password-empty");
            else if (value.length > 0 && value.length < 8)
            return ctx.$t("password-short");
            else return ctx.$t("password-invalid");
        },
        },
    ],
    },
    confirm_password: {
    identifier: "confirm_password",
    rules: [
        {
        type: "match[password]",
        prompt: () => {
            return ctx.$t("confirm-password-error");
        },
        },
    ],
    },
},
});
}

export default {

  data () {
      return {
          password: '',
          confirm_password: '',
          server_error: ''
      }
  },

  mounted() {
    $("#reset_form").submit(e=>e.preventDefault());
    this.$nextTick(reset_validation(this));
  },

    methods: {
        async reset () {
            if (this.$route.params.token==="") {
                $('body').toast({
                    class: 'orange',
                    displayTime: 5000,
                    closeIcon: true,
                    message: "Please use the reset link from the email.",
                    position: 'top center'
                });
                return;
            }

            await this.$store.dispatch("auth/logOut");
            this.server_error = "";
            $('#reset_form').form("validate form");
            if ($("#reset_form").form("is valid")) {
                try {
                    await this.$store.dispatch("auth/resetPassword", 
                        {'password': btoa(this.password),
                        'email_token': this.$route.params.token});
                    await this.$router.push('/reset-success');
                } catch (error) {
                    if (error.response) {
                        this.server_error = error.response.data.message;
                    } else {
                        this.server_error = error.message;
                    }
                }
            }

        }
    }
}
</script>

<i18n>
{
  "en": {
        "password-reset": "Password Reset",
        "password": "New password",
        "confirm-password": "Confirm password",
        "password-empty": "Please enter the new password",
        "password-short": "The password must be at least 8 characters",
        "password-invalid": "The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number",
        "confirm-password-error": "The password and confirm password do not match",
        "reset-button": "Reset"
  },
    
  "cn": {
        "password-reset": "密码重置",
        "confirm-password": "确认密码",
        "password-empty": "请输入新密码",
        "password-short": "密码不得短于8个字符",
        "password-invalid": "密码至少含有一个大写字母，一个小写字母和一个数字",
        "confirm-password-error": "密码与确认密码不相符",
        "reset-button": "重置"

  }
}
</i18n>