<template>
<div class="ui basic grid segment">
    <div class="centered row">
		<h2 class="ui primary header" style="margin-top: 60px;">
            <i class="ui primary big check circle outline icon"></i>
            Deposit and Shipping Fee Payment successful!
        </h2>
    </div>
    <div class="centered row">
        <router-link to="/profile">
            <b style="text-decoration: underline;">
                Go to Profile
            </b>
        </router-link>
    </div>

</div>
</template>