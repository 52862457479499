import { axios_ } from '@/main'

let housing_prefix = '/housing'
const housing = {
    namespaced: true,
    state: () => ({
        persistence: {},
        current: null,
        cart: [],
    }),

    getters: {
    },

    mutations: {
        ['SET_PERSISTENCE'] (state, payload) {
            for (var key in payload) {
                state.persistence[key] = payload[key];
            }
        },

        ['SET_CURRENT'] (state, payload) {
            state.current = payload;
        },

        ['ADD_CART'] (state, payload) {
            state.cart.push({
                'id': payload.id,
                'house_id': payload.house.id,
                'realtor_id': payload.house.realtor.id,
                'booking': payload.booking,
                'booking_iso': payload.booking_iso,
                'time_zone': payload.time_zone,
                'address': payload.address,
                'price': payload.house.realtor.price,
                'agent': payload.house.realtor.is_company ? 
                    payload.house.realtor.company_name : payload.house.realtor.agent.user.first_name
            });
        },

        ['REMOVE_CART'] (state, payload) {
            let house_idx = state.cart.findIndex(
                item => item.id == payload.id
            );
            state.cart.splice(house_idx, 1);
        },

        ['CLEAR_CART'] (state) {
            state.cart = [];
            state.current = null;
        }
    },

    actions: {
        retrieveCities ({commit}, country) {
            var api = "/housing/cities";
            return axios({
                method: 'post',
                url: api,
                data: country,
            });
        },

        houseFiltering ({commit}, criteria) {
            var api = "/housing/filtering"
            return axios({
                method: 'post',
                url: api,
                data: criteria,
            })
        },

        addHouse ({commit}, house) {
            var api = "/housing/add-house";
            return axios_().post(api, house);
        },

        uploadHousePhoto({commit}, photo) {
            var api = "/housing/upload-house-photo"
            var data = new FormData();
            data.append('photo', photo);
            return axios_().post(api, data, {
                'Content-Type': 'multipart/form-data'
            });
        },

        deleteHousePhoto({commit}, photo) {
            var api = "/housing/delete-house-photo"
            return axios_().post(api, photo);
        },

        clearUploadFolder({commit}, clear) {
            var api = "/housing/clear-upload-folder"
            return axios_().post(api, clear)
        },

        fetchHouse({commit}, house_id) {
            var api = "/housing/fetch-house"
            return axios({
                method: 'post',
                url: api,
                data: house_id
            })
        },

        fetchHouseV2({commit}, house_id) {
            var api = "/housing/fetch-house-v2"
            return axios_().post(api, house_id);
        },

        fetchHousesAdmin({commit}, payload) {
            var api = housing_prefix + '/fetch-houses-admin';
            return axios_().post(api, payload);
        },

        fetchSlots({commit}, realtor_id) {
            var api = housing_prefix + '/fetch-slots';
            return axios_().post(api, realtor_id);
        }, 

        adminBook({commit}, payload) {
            var api = housing_prefix + '/admin-book';
            return axios_().post(api, payload);
        },

        // this function has not been defined
        fetchBookingAdmin({commit}) {
            var api = housing_prefix + '/fetch-booking-admin';
            return axios_().post(api)
        },

        fetchRealtor({commit}) {
            var api = housing_prefix + '/fetch-realtor';
            return axios_().post(api);
        },

        createRealtor({commit}, payload) {
            var api = housing_prefix + '/create-realtor';
            return axios_().post(api, payload);
        },

        // fetch booking list for the realtor
        fetchBookings({commit}, payload) {
            var api = housing_prefix + '/fetch-bookings';
            return axios_().post(api, payload);
        },

        fetchViewings({commit}, payload) {
            var api = housing_prefix + '/fetch-viewings';
            return axios_().post(api, payload);
        },

        bookingDetail({commit}, booking_id) {
            var api = housing_prefix + '/booking-detail';
            return axios_().post(api, booking_id);
        },

        viewingDetail({commit}, booking_id) {
            var api = housing_prefix + '/viewing-detail';
            return axios_().post(api, booking_id);
        },

        viewingAction({commit}, payload) {
            var api = housing_prefix + '/viewing-action';
            return axios_().post(api, payload);
        },

        bookingAction({commit}, payload) {
            var api = housing_prefix + '/booking-action';
            return axios_().post(api, payload);
        },

        payDeposit({commit}, payload) {
            var api = housing_prefix + '/pay-deposit';
            return axios_().post(api, payload);
        },

        view({commit}, booking_id) {
            var api = housing_prefix + '/view';
            return axios_().post(api, booking_id);
        },

        attendStat({commit}, payload) {
            var api = housing_prefix + '/attend-stat';
            return axios_().post(api, payload);
        },

        house_admin({commit}, payload) {
            var api = housing_prefix + '/house-admin';
            return axios_().post(api, payload);
        },

        housingApp ({commit}) {
            var api = housing_prefix + '/housing-app';
            return axios_().post(api, null, {responseType: 'blob'});
        }
    }
}

export default housing