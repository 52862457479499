export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "from-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Company"])},
        "other-correspondent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Correspondent"])},
        "other-correspondent-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name different with signed name"])},
        "full-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Address"])},
        "full-address-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address, City, Country"])},
        "contact-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Number"])},
        "contact-number-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
        "workdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workdays"])},
        "working-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Hours"])},
        "start-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Hour"])},
        "end-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Hour"])},
        "rest-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rest Hours (start at)"])},
        "select-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Hours"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Personal Page"])},
        "correspondent-empty-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "correspondent-length-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "phone-format-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address-empty-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address-length-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "url-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "cn": {
        "from-company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自公司"])},
        "other-correspondent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人"])},
        "other-correspondent-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人姓名"])},
        "full-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
        "full-address-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家，城市，地址"])},
        "contact-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
        "contact-number-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话"])},
        "workdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作日"])},
        "working-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作时间"])},
        "start-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上班"])},
        "end-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下班"])},
        "rest-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休息时间(始于)"])},
        "select-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择钟点"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人介绍外部链接"])},
        "correspondent-empty-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入联系人"])},
        "correspondent-length-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人姓名不可超过32个字"])},
        "phone-format-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话输入错误"])},
        "address-empty-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入地址"])},
        "address-length-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址不可超过127个字"])},
        "url-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL地址错误"])}
      }
    }
  })
}
