export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "remote-viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Viewing"])},
        "house-viewing-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing homes can be inconvenient and time consuming.         The images or videos shown online may not match the real places, which happens often when we book hotels or B&Bs.         Microhalo offers a very different real-time remote viewing experience with its patented technology.         Simply <b><a href='/' style='text-decoration: underline;'>logging in</a></b> our website,         a real-time home viewing can be done online with a flexible schedule by just a few clicks.         No more travel is needed, and what you see is what you get."])},
        "view-more-house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View houses"])},
        "view-more-hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View hotels"])},
        "view-more-holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View holiday homes"])}
      },
      "cn": {
        "remote-viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远程观视"])},
        "house-viewing-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寻找新家总是费时费力。网上的图片和视频还可能和真实的地方不符。这种事情在我们网订酒店和民宿的时候时有发生。        借助 Microhalo 专利，Microhalo通过其实时远程观视服务提供了一种截然不同的看房体验。<b><a href='/' style='text-decoration: underline;'>登录</a></b>我们的网站，         只需几个点击您就可以随时随地轻松愉快地在线实时看房了。不再有舟车劳苦且所见即所得。远程观视里的一对一视频也将使得您和屋主的交流更加轻松。"])},
        "view-more-house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看房产"])},
        "view-more-hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看酒店"])},
        "view-more-holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看民宿"])}
      }
    }
  })
}
