import Housing from './views/Housing.vue'
import House from './views/House.vue'
import Booking from './views/Booking.vue'
import CreateRealtor from './views/CreateRealtor.vue'
import BookingDetail from './views/BookingDetail.vue'
import HousesAdmin from './views/HousesAdmin.vue'
import AddHouse from './views/AddHouse.vue'
import EditRealtor from './views/EditRealtor.vue'
import View from './views/View.vue'
import ViewingDetail from './views/ViewingDetail.vue'

const routes = [
    {path: '', component: Housing, name: 'housing'},
    // {path: 'addhouse', component: AddHouse, name: 'addhouse'},
    {path: 'house/:id(.*)*', component: House, name: 'house'},
    {path: 'booking/:id(.*)*', component: Booking, name: 'booking'},
    {path: 'booking-detail/:id(.*)', component: BookingDetail, name: 'booking-detail'},
    {path: 'viewing-detail/:id(.*)', component: ViewingDetail, name: 'viewing-detail'},
    {path: 'create-realtor', component: CreateRealtor, name: 'create-realtor'},
    {path: 'houses-admin', component: HousesAdmin},
    {path: 'add-house/:id', component: AddHouse, name: 'add-house'},
    {path: 'add-house', component: AddHouse},
    {path: 'edit-realtor', component: EditRealtor},
    {path: 'view/:id(.*)', component: View, name: 'view'},
    
]

export default routes;