<template>
<div class="ui basic segment" :class="{'loading':loading}"
    style="margin:0; padding:0;">
    <table class="ui selectable table">
        <thead><tr>
            <th colspan="4" class="center aligned">
                <span class="ui grey text">{{$t('past-viewings')}}</span>
            </th> 
        </tr></thead>
        <thead><tr>
            <th class="two wide">{{$t('id')}}</th>
            <th class="eight wide">{{$t('viewing')}}</th>
            <th class="four wide">{{$t('booking')}}</th>
            <th class="two wide">{{$t('status')}}</th>
        </tr></thead>
        <tbody>
            <tr v-for="booking in this.bookings" :key="booking.id"
                @click="$router.push({
                        name: 'booking-detail', 
                        params: {'id': booking.id}})">
                <td>#{{booking.id}}</td>
                <td>
                    <span style="word-break: break-all;">{{booking.address}}</span>
                </td>

                <td>{{booking.booking}}</td>
                <td>{{booking.status}}</td>
            </tr>
        </tbody>
        <tfoot><tr >
            <td colspan="4" class="center aligned">
            <small-pagination v-model="page" 
                :total_pages="total_pages" @pageChanged="fetch" />
            </td>
        </tr></tfoot>
    </table>
</div>

</template>

<script>
import mixin from '@/mixin'
import SmallPagination from '@/components/SmallPagination.vue'

export default {
    mixins: [mixin],
    inject: ['DateTime'],

    components: {
        SmallPagination,
    },

    data () {
        return {
            page: 1,
            total_pages: 1,
            bookings: [],
            loading: false,
        }
    },

    async created () {
        await this.fetch();
    },

    methods: {
        async fetch () {
            try {
                this.loading = true;
                let response = await this.$store.dispatch('housing/fetchViewings', 
                    {'fetch': 'past', 'page': this.page});
                this.bookings = this.gHouseBookingsReform(response.data.bookings);
                this.page = response.data.page;
                this.total_pages = response.data.total_pages;
            } catch (error) {
                this.gErrorHandler(error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>

<i18n>
{
    "en": {
        "past-viewings": "Past Viewings",
        "id": "ID",
        "viewing": "Viewing",
        "booking": "Booking",
        "status": "Status"
    },
    "cn": {
        "past-viewings": "过往看房",
        "id": "标识号",
        "viewing": "观视",
        "booking": "预约",
        "status": "状态"
    }
}
</i18n>