<template>
<div class="ui basic grid segment">

<div class="centered row" style="margin: 20px 0 10px 0;">
<div class="fourteen wide column">

<div class="ui form" id="addhouse">
    <div class="fields">
    <div class="required four wide field">
        <label><span>{{$t("housing-type")}}</span></label>
        <select class="ui selection dropdown" name="housing_type" id="type_dropdown" v-model="housing_type">
        <option value="">{{ $t("housing-type") }}</option>
        <option value="renting">{{$t('house-renting')}}</option>
        <option value="buying">{{$t('house-selling')}}</option>
        <option value="sharing">{{$t('house-sharing')}}</option>
        <option value="hotel">{{$t('hotels-bnbs')}}</option>
        <option value="holiday">{{$t('holiday-homes')}}</option>
        </select>
    </div>
    
    <div class="required three wide field">
        <label><span>{{$t("country")}}</span></label>
        <select class="ui selection dropdown" name="country" id="country_dropdown" v-model="country">
            <option value="">{{$t("country")}}</option>
            <option value="1">{{$t('ireland')}}</option>
        </select>
    </div>

    <div class="required three wide field" v-if="cities">
        <label><span>{{$t("city")}}</span></label>
        <select class="ui selection dropdown" id="city_dropdown" name="city" v-model="city_id">
        <option value="">{{$t("city")}}</option>
        <option :value="c.id" v-for="c in cities" :key="c.id">
            {{c.name}}
        </option>
        </select>
    </div>
    </div>

    <div class="required twelve wide field">
        <label><span>{{$t("price")}}</span></label>
        <div class="fields">
        <div class="four wide field">
            <div class="ui selection dropdown" id="periodicity_dropdown">
                <input type="hidden" name="periodicity" v-model.lazy="periodicity">
                <i class="primary dropdown icon"></i>
                <div class="default text">{{$t("periodicity")}}</div>
                <div class="ui menu">
                    <div class="item" data-value="once">{{$t('once')}}</div>
                    <div class="item" data-value="hour">{{$t('hourly')}}</div>
                    <div class="item" data-value="day">{{$t('daily')}}</div>
                    <div class="item" data-value="week">{{$t('weekly')}}</div>
                    <div class="item" data-value="month">{{$t('monthly')}}</div>
                    <div class="item" data-value="year">{{$t('yearly')}}</div>
                </div>
            </div>
        </div>
        <div class="five wide field">
            <div class="ui input">
            <input type="number" name="price" :placeholder="$t('price')"
                v-model.trim="price">  
            <span style="margin-left: 10px; bottom: -8px; position:relative;"><b>Euro</b></span>               
            </div>
        </div>
        </div>
    </div>

    <div class="field">
        <label><span>{{$t("url")}}</span></label>
        <input type="url" name="url" :placeholder="$t('url')"
            v-model.trim="url">             
    </div>

    <add-house-images :imgs="images"></add-house-images>
    <add-house-description :pkey=0 ref="description-0" @update="get_inputs($event)" :description="i18ns[0]?i18ns[0]:{}"></add-house-description>
    <add-house-description v-for="cnt in langs_created" :key=cnt :ref="'description-'+cnt" :pkey=cnt 
        @remove="remove($event)" @update="get_inputs($event)" :description="i18ns[cnt]?i18ns[cnt]:{}" >{{cnt}}</add-house-description>

    <div class="center-item" style="margin-top: 10px;">
        <a @click="add" href="javascript:;" style="text-decoration: underline;"><b>{{$t('add')}}</b></a>
    </div>

    <div class="ui negative message" v-if="server_error">
    <ul class="list">
        <li>{{server_error}}</li>
    </ul>
    </div>

    <div class="center-item" style="margin-top: 24px;">
        <div class="ui primary button" @click="submit" :class="{'loading': loading, 'disabled': loading}">
            {{$t('submit')}}
        </div>
    </div>


</div>

</div>
</div>
</div>
</template>

<script>
import AddHouseDescription from '../components/AddHouseDescription.vue'
import AddHouseImages from '../components/AddHouseImages.vue'
import mixin from '@/mixin'

export default {
    mixins: [mixin],

    components: {
        AddHouseDescription,
        AddHouseImages,
    },

    data () {
        return  {
            cities: [],
            housing_type: "",
            country: "",
            city_id: "",
            periodicity: "",
            price: "",
            url: '',
            langs: [...Array(this.$i18n.availableLocales.length).keys()].slice(1),
            langs_created: [],
            lang: this.$i18n.locale,
            descriptions: [],
            server_error: "",
            form_error: "",
            input_locales: [],
            loading: false,
            house_id: '',
            house: null,
            images: [],
            i18ns: [],
        }
    },

    mounted () {
        $('.selection.dropdown').dropdown();
        $('#addhouse').form({
            inline: true,
            rules: {
                custom: function (value) {
                    if (value.length > 0)
                        return $.fn.form.settings.rules.url(value);
                    else
                        return true;
                },

                custom_periodicity: function (value, identifier, $module) {
                    let housing_type = $module.find('[name="' + identifier +'"]').val();
                    if (housing_type==='buying')
                        return $.fn.form.settings.rules.is(value, 'once');
                    else
                        return $.fn.form.settings.rules.not(value, 'once');
                }
            },

            fields: {
                housing_type: {
                    identifier: 'housing_type',
                    rules:[{type: 'empty', prompt: this.$t('housingtype-error')}]
                },

                country: {
                    identifier: 'country',
                    rules: [{type: 'empty', prompt: this.$t('country-error')}]
                },

                city: {
                    identifier: 'city',
                    rules: [{type: 'empty', prompt: this.$t('city-error')}]
                },

                periodicity: {
                    identifier: 'periodicity',
                    rules: [
                        {type: 'empty', prompt: this.$t('periodicity-error')},
                        {type: 'custom_periodicity[housing_type]', prompt: this.$t('custom-periodicity-error')}
                    ]
                },

                price: {
                    identifier: 'price',
                    rules: [{type: 'decimal', prompt: this.$t('price-error')}]
                },

                url: {
                    identifier: 'url',
                    rules: [{type: 'custom', prompt: this.$t('url-error')}]
                },
            },
        });

        this.house_id = this.$route.params.id;
        console.log("house id", this.house_id);
        if (parseInt(this.house_id))
            this.retrieve_house();

    },

    created () {
        this.retrieve_cities();
        console.log(this.$i18n.availableLocales);
        // let id = parseInt(this.booking_id);
    },

    updated () {
        this.$nextTick(()=>$('#type_dropdown').dropdown('set selected', this.housing_type));
        this.$nextTick(()=>$('#country_dropdown').dropdown('set selected', this.country_id));
        this.$nextTick(()=>$('#city_dropdown').dropdown('set selected', this.city_id));
        this.$nextTick(()=>$('#periodicity_dropdown').dropdown('set selected', this.periodicity));
    },

    methods: {

        add () {
            let k = this.langs.shift();
            if (k) {
                this.langs_created.push(k);
            }
                
        },

        remove (k) {
            let index = this.langs_created.indexOf(k);
            if (index !== -1) {
                this.langs_created.splice(index, 1);
                this.langs.push(k);
            }
        },

        async retrieve_house () {
            try {
                let response = await this.$store.dispatch('housing/fetchHouseV2', {'id': this.house_id});
                console.log("res", response.data);
                this.house = response.data;

                this.city_id = this.house.city_id;
                this.housing_type = this.house.type;
                this.country = this.house.country_id;
                this.periodicity = this.house.periodicity;
                this.price = this.house.price;
                this.url = this.house.url;
                this.images = this.house.images;
                this.i18ns = this.house.i18n;
                for (var j=1; j<this.i18ns.length; j++) {
                    this.langs_created.push(j);
                    this.langs.shift();

                }
                // this.langs_created = [...Array(this.i18ns.length).keys()].slice(1);

                


            }catch (error) {
                this.gErrorHandler(error);
            }
        },

        async retrieve_cities () {
            try {
                let response = await this.$store.dispatch('housing/retrieveCities', {"country_id": 1});
                this.cities = response.data;
                console.log("citiessss", this.cities);
            } catch (error) {
                this.gErrorHandler(error);
            }
        },

        async submit () {
            this.descriptions = [];
            this.input_locales = [];
            this.form_error = "";
            this.server_error = "";
            this.loading = true;


            $("#addhouse").form("validate form");
            if (!$("#addhouse").form("is valid")) {
                this.form_error = "form error";
            }
            if (!this.$refs['description-0'].validation()) {
                this.form_error = "form error";
            }
            this.$refs['description-0'].update_emit();

            console.log("langs created", this.langs_created);
            this.langs_created.forEach( (elem)=>{
                console.log("elem", elem, this.$refs);

                if (this.$refs['description-'+elem][0].validation()) 
                    this.$refs['description-'+elem][0].update_emit();
                else 
                    this.form_error = "form error";
            });

            if (this.form_error) {
                this.form_error_toast(this.$t('form-error'));
                this.loading = false;
                return;
            }

            try {
                let response = await this.$store.dispatch("housing/addHouse", {
                    'housing_type': this.housing_type,
                    'country': this.country,
                    'city_id': this.city_id,
                    'periodicity': this.periodicity,
                    'price': this.price,
                    'url': this.url,
                    'descriptions': this.descriptions,
                    'for_sale': this.housing_type==='buying',
                    'house_id': parseInt(this.house_id)
                });
                console.log("response", response);
                this.$router.push('/housing/houses-admin');
                
            } catch (error) {
                console.log(error);
                if (error.response) {
                    this.server_error = error.response.data.message;
                } else {
                    this.server_error = error.message;
                }
                console.log(this.server_error);
            } finally {
                this.loading = false;
            }
        },

        get_inputs (input) {
            if(this.input_locales.includes(input.locale)) {
                this.$refs['description-'+input.pkey][0].mark_duplicate_lang();
                this.form_error = "form error";
            } else {
                this.descriptions.push(input);
                this.input_locales.push(input.locale);
            }
        },

        form_error_toast (msg) {
            $('body').toast({
                class: 'orange',
                displayTime: 5000,
                closeIcon: true,
                message: msg,
                position: 'top center'
            });
        },

    }
}
</script>

<i18n>
{
"en": {
    "title": "Add Remote Viewing",
    "housing-type": "Housing Type",
    "country": "Country",
    "city": "City",
    "price": "Price",
    "url": "Page URL",
    "periodicity": "Periodicity",
    "hourly": "Hourly",
    "daily": "Daily",
    "weekly": "Weekly",
    "monthly": "Monthly",
    "yearly": "Yearly",
    "once": "Once",
    "house-renting": "Rent a House",
    "house-selling": "Sell a House",
    "house-sharing": "Share a House",
    "hotels-bnbs": "Hotels & BnBs",
    "holiday-homes": "Holiday Homes",
    "ireland": "Ireland",
    "submit": "Submit",
    "add": "Add description in another language",
    "housingtype-error": "Please select a housing type",
    "country-error": "Please select a country",
    "city-error": "Please select a city",
    "periodicity-error": "Please select a periodicity",
    "custom-periodicity-error": "Once for selling a house",
    "price-error": "A price must be a postive value",
    "url-error": "Please give a valid url",
    "form-error": "You have form errors!",
    "upload": "Upload",
},

"cn": {
    "housing-type": "类型",
    "country": "国家",
    "city": "城市",
    "price": "价格",
    "url": "网页地址",
    "periodicity": "单位",
    "add": "添加其他语言介绍",
    "upload": "上传"
},
}
</i18n>
