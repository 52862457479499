<template>
<div class="ui basic grid segment">
    <table class="ui primary table" style="padding:0">
        <thead><tr>
            <th colspan="4" class="center aligned">
                <span class="ui grey text">{{$t('remote-viewing')}}</span>
            </th>
        </tr></thead>
        <thead><tr>
            <th class="nine wide">{{$t('viewing')}}</th>
            <th>{{$t('booking')}}</th>
            <th>{{$t('price')}}</th>
            <th></th>
        </tr></thead>
        <tbody>
            <tr v-for="booking in this.cart" :key="booking.id">
                <td><h4 class="ui header">
                    <div class="content">
                        <a href="javascript:;" @click="$router.push({name: 'house', params: {'id': booking.id}})">
                            <span style="word-break: break-all;">{{booking.address}}</span>
                        </a>
                        <div class="sub header">
                            Agent: <span>{{booking.agent}}</span>
                        </div>
                    </div>
                </h4></td>

                <td>{{ Intl.DateTimeFormat('default', {
                        year: 'numeric', month: 'short', day: '2-digit', 
                        hour: 'numeric', minute: 'numeric', hour12: true})
                        .format(booking.booking)
                        .replace("am", "AM")
                        .replace("pm","PM") }}</td>
                <td>€{{booking.price.price.toFixed(2)}}</td>
                <td class="right aligned">
                    <a class="ui orange rounded label" style="padding-right:1px;" @click="remove_from_cart(booking)">
                    <i class="trash alternate icon"></i>
                    </a>
                </td>
            </tr>
        </tbody>
        <tfoot><tr>
            <!-- <td></td> -->
            <td colspan="3">
                <router-link to="/housing" class="ui primary basic label">{{$t("add")}}</router-link>
                <span style="float: right"><b>{{$t('subtotal')}}</b></span>
            </td>
            <td class="right aligned" style="color: dimgrey">
                <b>€{{subtotal.toFixed(2)}}</b>
            </td>
        </tr></tfoot>
    </table>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    inject: ['DateTime'],

    computed: {
        ...mapState('housing', ['cart']),
        subtotal () {
            let subtotal = this.$store.state.housing.cart.reduce( (total, item)=>total+item.price.price, 0);
            this.$emit('update', subtotal);
            return subtotal;
        }
    },

    methods: {
        remove_from_cart (item) {
            this.$store.commit('housing/REMOVE_CART', item);        
        },
    },
}
</script>

<i18n>
{
    "en": {
        "add": "Add",
        "remote-viewing": "Remote Viewing",
        "viewing": "Viewing",
        "booking": "Booking",
        "price": "Price",
        "subtotal": "Subtotal (Inc. VAT)",
    },
    "cn": {
        "add": "添加",
        "remote-viewing": "远程观视",
        "viewing": "看房",
        "booking": "预约",
        "price": "价格",
        "subtotal": "合计 (含税)",
    }
}
</i18n>