export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
        "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "forget-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget the password"])},
        "sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account"])},
        "email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
        "password-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the password"])},
        "password-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 8 characters"])},
        "password-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number"])},
        "password-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email for password reset has been sent to you"])}
      },
      "cn": {
        "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "forget-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码了"])},
        "sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册新用户"])},
        "email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效邮箱地址"])},
        "password-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入密码"])},
        "password-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不得短于8个字符"])},
        "password-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码至少含有一个大写字母，一个小写字母和一个数字"])},
        "password-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码重置邮件已发送到你的邮箱"])}
      }
    }
  })
}
