export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address (Max. 255 chars)"])},
        "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short Description (Max. 255 chars)"])},
        "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long Description"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
        "cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
        "address-empty-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide the address of the house"])},
        "address-length-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address can't be longer than 255 characters"])},
        "short-length-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The short description can't be longter than 255 characters"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
      },
      "cn": {
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
        "cn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])}
      }
    }
  })
}
