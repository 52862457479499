export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "no-house-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No house code specified"])},
        "duplicate-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have another booking with the same date and time."])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])}
      },
      "cn": {
        "no-house-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请指定要查看的房子"])},
        "duplicate-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您有其他看房预约在相同的时间段"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预订"])}
      }
    }
  })
}
