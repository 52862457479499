<template>
<div class="ui fluid primary horizontal link grid card" :class="{'red': !house.house.is_available}">
    <div class="three column stackable row">
        <div class="thirteen wide column">
        <div class="ui two column grid" @click="$router.push({ name: 'house', params: {'id': house.id} })">
            <div class="four wide column">
                <div class="ui image" v-if="house.house.images.length>0">
                    <img :src="house.house.images[0]" style="max-height: 120px; max-width: 150px;">
                </div>
                <div class="ui placeholder" v-else>
                    <div class="image"></div>
                </div>           
            </div>

            <div class="nine wide column">
                <div class="ui fluid card">
                <div class="content">
                    <div class="header">
                        {{ 
                            house.house.price.toLocaleString("en", 
                                { style: 'currency', currency: 'EUR', maximumFractionDigits: 0})
                        }} <span class="ui small text"> {{per_unit}} </span>
                    </div>
                    <div class="meta">
                        {{house.address}}
                    </div>
                    <div class="description">
                        {{house.short_description}}
                    </div>
                    <div class="extra content">
                        <div class="meta">
                            {{extra_content}}
                        </div>
                    </div>

                </div>
                </div>
            </div>

        </div>
        </div>

        <div class="three wide right aligned column off-hand" v-if="admin && house">
            <div>
                <div class="ui tiny blue icon button" @click="edit_house_admin">
                    <i class="pen icon"></i> Edit
                </div>
                <div class="ui tiny orange icon button" @click="off_house_admin" v-if="house.house.is_available">
                    <i class="store alternate slash icon"></i> Off
                </div>
                <div class="ui tiny green icon button" @click="on_house_admin" v-else>
                    <i class="store alternate icon"></i> On
                </div>
            </div>
        </div>

        <div class="three wide column" v-else>
            <div class="ui card" >
                <div class="ui tiny image" v-if="house.house.realtor.profile_images.length>0">
                    <img :src="house.house.realtor.profile_images[0]">
                </div>
                <div class="ui placeholder" v-else>
                    <div class="image"></div>
                </div>
            <div class="content">
                <div class="ui center aligned tiny header">
                    {{house.house.realtor.is_company ? 
                        gUpperFirstChar(house.house.realtor.company_name) : gUpperFirstChar(house.house.realtor.agent.user.first_name)}}
                </div>
            </div>
            </div>
        </div>


    </div>
</div>

</template>

<script>
import mixin from '@/mixin'

export default {
    mixins: [mixin],
    props: ['item', 'admin'],

    data () {
        return {
            'house': this.item
        }
    },

    computed: {
        per_unit () {
            if (this.house.house.periodicity == "once")
                return ""
            else if (this.house.house.periodicity == "day" && this.house.house.type == "hotel")
                return " / night"
            else
                return " / " + this.house.house.periodicity
        },

        extra_content () {
            var DateTime = luxon.DateTime;
            let s = ["updated on "+DateTime.fromISO(this.house.house.updated_at, { zone:"utc"}).toLocaleString(DateTime.DATE_MED)];
            if (this.house.house.tenants_count)
                s.unshift(this.house.house.tenants_count + " people live in"); 
            if (this.house.house.room_type)
                s.unshift(this.house.house.room_type + " room");
            if (this.house.house.bedrooms_count) 
                s.unshift(this.house.house.bedrooms_count + " bedrooms");

            return s.join(", ");
        }
    },

    // created () {
    //     console.log("item", this.house);
    // },

    methods: {
        edit_house_admin () {
            this.$router.push({name: 'add-house', params: {'id': this.house.house_id}})
        },

        async off_house_admin () {
            try{
                await this.$store.dispatch('housing/house_admin', {
                    'house_id': this.house.house_id,
                    'on': false 
                });
                this.house.house.is_available = false;
            } catch (error) {
                this.gErrorHandler(error);
            }
        },

        async on_house_admin () {
            try{
                await this.$store.dispatch('housing/house_admin', {
                    'house_id': this.house.house_id,
                    'on': true 
                });
                this.house.house.is_available = true;
            } catch (error) {
                this.gErrorHandler(error);
            }
        },

    }

}
</script>

<style scoped>
.ui.card {
    box-shadow: none;
}
.ui.button {
    margin: 5px;
    width: 60px;
}

.off-hand {
    cursor: auto !important;
}
</style>