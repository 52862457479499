import { createStore } from 'vuex'
import { axios_ } from '@/main'

const auth = {
    namespaced: true,
    state: () => ({
        user: null
    }),

    getters: {

    },
    
    mutations: {
        ['SET_USER'] (state, user) {
            state.user = user;
        },
    },

    actions: {
        logOut ({state, commit, rootState}) {
            commit('housing/CLEAR_CART', null, {root: true});
            commit('SET_USER', null);
            if (Parse.User.current()) {
                return Parse.User.logOut();
            }
        },

        logIn ({commit}) {
            var api = '/auth/login';
            if (Parse.User.current())
                return axios_().post(api);
            else
                return Promise.reject("No current user found");
        },

        signUp ({commit}, {token, inputs}) {
            var api = '/auth/signup';
            return axios({
                method: 'post',
                url: api,
                headers: {
                    'Authorization': 'Bearer '+token
                },
                data: inputs,
            });
        },

        sendConfirmation({commit}, email_token) {
            var api = '/auth/send-confirmation';
            if (Parse.User.current())
                return axios_().post(api, email_token);
            else
                return axios({
                    method: 'post',
                    url: api,
                    data: email_token,
                })
        },

        verifyEmail({commit}, email_token) {
            var api = '/auth/verify-email';
            return axios({
                method: 'post',
                url: api,
                data: email_token,
            });
        },

        stripeDashboard({commit}) {
            var api = '/auth/stripe-dashboard';
            return axios_().post(api);
        },

        clearUploadFolder({commit}, clear) {
            var api = "/auth/clear-upload-folder"
            return axios_().post(api, clear)
        },

        deletePhoto({commit}, photo) {
            var api = "/auth/delete-photo"
            return axios_().post(api, photo);
        },

        // uploadPhoto({commit}, payload={'photo': 'photo', 'subfolder': 'subfolder'}) {
        //     return Promise.reject(new Error('Action is not implementd'));
        // },

        uploadPhoto({commit}, {photo, subfolder}){
            console.log(photo);
            var api = '/auth/upload-photo'
            var data = new FormData();
            data.append('photo', photo);
            data.append('subfolder', subfolder);
            return axios_().post(api, data, {
                'Content-Type': 'multipart/form-data'
            });
        },

        createOrder({commit}, payload) {
            var api = '/auth/create-order';
            return axios_().post(api, payload);
        },

        confirmOrder({commit}, order_id) {
            var api = '/auth/confirm-order';
            return axios_().post(api, order_id);
        },

        resendPassword({commit}, email) {
            var api = '/auth/resend-password';
            return axios({
                method: 'post',
                url: api,
                data: email,
            });
        },

        resetPassword({commit}, password) {
            var api = '/auth/reset-password';
            return axios({
                method: 'post',
                url: api,
                data: password
            });
        }

    }
}

import housing from '@/apps/housing/store';

const store = createStore({
    modules: {
        auth,
        housing,
    }
})

export default store;
