export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "password-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
        "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
        "password-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new password"])},
        "password-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 8 characters"])},
        "password-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number"])},
        "confirm-password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password and confirm password do not match"])},
        "reset-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
      },
      "cn": {
        "password-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码重置"])},
        "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
        "password-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入新密码"])},
        "password-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不得短于8个字符"])},
        "password-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码至少含有一个大写字母，一个小写字母和一个数字"])},
        "confirm-password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码与确认密码不相符"])},
        "reset-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])}
      }
    }
  })
}
