export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "no-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No date is selected."])},
        "no-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hour is selected."])},
        "no-minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No minute is selected."])},
        "conflict-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " has been booked by a viewer"])}
      },
      "cn": {
        "no-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请指定看房日期"])},
        "no-hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请指定看房时间"])},
        "no-minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请指定看房时间"])}
      }
    }
  })
}
