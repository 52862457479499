<template>
<div class="ui basic grid segment">
<div class="centered row" style="margin-top: 15px;">
    <router-link to="/housing/add-house" style="text-decoration: underline; word-spacing: 4px;"><b>Add a House</b></router-link>
</div>

<div class="centered row">
    <div class="fourteen wide column">
    <div class="ui cards" v-for="house in houses" :key="house.id">
        <housing-item :admin="admin" :item="house"></housing-item>
    </div>
    </div>
</div>

<div class="centered row">
    <pagination v-model="page" :total_pages="total_pages" @pageChanged="page_changed"></pagination>
</div>
</div>
</template>

<script>
import HousingItem from '../components/HousingItem.vue'
import Pagination from '@/components/Pagination.vue'

export default {
    components: {
        HousingItem,
        Pagination
    },

    data () {
        return {
            admin: true,
            houses: [],
            page: 1,
            total_pages: 1,
        }
    },

    created () {
        this.fetch_houses_admin();
    },

    methods: {
        page_changed () {
            this.fetch_houses_admin();
        },

        async fetch_houses_admin () {
            try{
                let response = await this.$store.dispatch(
                    'housing/fetchHousesAdmin', {
                        'page': this.page,
                        'language': this.$i18n.locale,
                });
                console.log("houses admin", response.data);
                this.houses = response.data.houses;
                this.total_pages = response.data.total_pages;
            } catch (error) {
                this.gErrorHandler(error);
            }
        },
    }
}
</script>