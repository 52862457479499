<template>
<div class="ui basic grid segment" v-if="house"> 
<div class="stackable row">
    <div class="eight wide column">
        <image-gallery :images="images"></image-gallery>
    </div>
    <div class="eight wide column" 
        style="display: flex; flex-direction: column;">
        <div class="ui header" 
            style="margin-top: 10px;">
        <span class="ui text">
        {{ 
            house.house.price.toLocaleString("en", 
                { style: 'currency', currency: 'EUR', maximumFractionDigits: 0})
        }} {{mixin_housePriceUnit}} 
        </span>
        </div>

        <div class="ui small grey header">
            {{house.address}}
        </div>

        <div>
            {{mixin_houseExtraContent}}
        </div>

        <div>
        <span class="ui text">link:</span>
        <span class="ui small primary header">
        <a :href="house.house.url" target="_blank" style="margin-left: 6px;">{{link_url}}</a>
        </span>
        </div>

        <p style="margin-top: 10px;">{{house.short_description}}</p>

        <div style="flex: 1;">
        </div>

        <div class="ui horizontal fluid card">
            <div class="right aligned content">
                <div class="header">
                    {{house.house.realtor.is_company ? 
                        gUpperFirstChar(house.house.realtor.company_name) : 
                        gUpperFirstChar(house.house.realtor.agent.user.first_name)}}
                </div>
                <div class="description">
                <p>{{realtor_description}}</p>
                </div>
            </div>
            <div class="ui small rounded image" style="width: 70px; height: 70px; object-fit: contain;">
                <img :src="house.house.realtor.profile_images.length > 0 ? 
                    house.house.realtor.profile_images[0] : require('@/assets/no-image.jpg')">
            </div>

        </div>
    </div>
</div>

<div class="row">
<div class="column">
    <div class="ui header">Description</div>
    <p>{{house.long_description}}</p>
</div>
</div>

<div class="row">
<div class="column">
    <button class="ui button" @click="$router.push('/housing')">Search</button>
    <button class="ui primary button" style="float: right" @click="go_book">Book</button>
</div>
</div>

</div>

<div class="ui basic segment" style="margin-top: 10em;" v-else>
    <div class="ui active loader"></div>
    <p></p>    
</div>

</template>

<script>
import mixin from '@/mixin'
import ImageGallery from '@/components/ImageGallery.vue';

export default {

mixins: [mixin],

components: {
    ImageGallery
},

data () {
    return {
        house: null,
        images: [],
        id: null,
    }
},

async created () {
    let id = parseInt(this.$route.params.id);
    if (id) {
        this.id = id;
        if (this.$store.state.housing.current && this.$store.state.housing.current.id == this.id) {
            this.house = this.$store.state.housing.current;
            this.images = this.house.house.images;
        } else
            this.fetch_house();
    } else {
        $('body').toast({
            class: 'orange',
            displayTime: 5000,
            closeIcon: true,
            message: this.$t('no-house-id'),
            position: 'top center'
        });
        this.$router.push('/housing');
    }
},

async beforeRouteUpdate(to, from) {
    this.fetch_house(to.params.id);
},

computed: {
    realtor_description () {
        var en;
        for (var i=0; i<this.house.house.realtor.i18n.length; i++) {
            if (this.house.house.realtor.i18n[i].locale==this.$i18n.locale)
                return this.house.house.realtor.i18n[i].short_description;
            
            if (this.house.house.realtor.i18n[i].locale=="en")
                en = this.house.house.realtor.i18n[i].short_description;
        }

        return en;
    },

    link_url () {
        let limit = 100;
        if (this.house.house.url.length < limit)
            return this.house.house.url;
        else
            return this.house.house.url.substring(0, limit)+" ...";
    }
},

methods: {
    async fetch_house () {
        try {
            let response = await this.$store.dispatch(
                'housing/fetchHouse', {'id': this.id});
            console.log("house", response.data);
            this.house = response.data;
            this.images = this.house.house.images;
            this.$store.commit('housing/SET_CURRENT', this.house);
        } catch (error) {
            this.gErrorHandler(error);
        }
    },

    go_book () {
        if (this.$store.state.auth.user)
            this.$router.push({name: 'booking', params: {'id': this.id}});
        else
            $('body').toast({
                class: 'orange',
                displayTime: 5000,
                closeIcon: true,
                message: this.$t('login-first'),
                position: 'top center'
            });
    },
}

}
</script>

<style scoped>
.header {
    margin: 4px 0;
}

.ui.card {
    background-color: whitesmoke;
    box-shadow: none;
}

button {
    width: 100px;
}
</style>

<i18n>
{
"en": {
    "no-house-id": "No house code specified",
},

"cn": {
    "no-house-id": "请指定要查看的房子",
},
}
</i18n>