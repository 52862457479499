<template>
<div class="ui basic grid segment" v-if="house">
<div class="stackable row">
    <div class="eight wide column">
        <image-gallery :images="house.house.images"></image-gallery>
    </div>
    <div class="eight wide column" 
        style="display: flex; flex-direction: column;">
        <div class="ui header" style="margin-top: 10px; margin-bottom: 4px;">
        <span class="ui text">
        {{ 
            house.house.price.toLocaleString("en", 
                { style: 'currency', currency: 'EUR', maximumFractionDigits: 0})
        }} {{mixin_housePriceUnit}} 
        </span>
        </div>
        <div>
            <span class="ui grey text"><b>{{address}}</b></span>
        </div>

        <div>
            {{mixin_houseExtraContent}}
        </div>

        <router-link :to="internal_link">Full description</router-link>

        <p style="margin-top: 10px;">{{short_description}}</p>

        <div style="flex: 1;">
        </div>

        <slot></slot>
        
    </div>
</div>
</div>
</template>

<script>
import mixin from '@/mixin'
import ImageGallery from '@/components/ImageGallery.vue';

export default {
    mixins: [mixin],
    props: ['house'],

    components: {
        ImageGallery,
    },

    computed: {
        address () {
            let a = this.house.house.i18n.filter(elem=>elem.locale==this.$i18n.locale);
            if (a.length==0)
                a = this.house.house.i18n.filter(elem=>elem.locale=="en");
            return a[0]? a[0].address:'';
        },

        short_description () {
            let a = this.house.house.i18n.filter(elem=>elem.locale==this.$i18n.locale);
            if (a.length==0)
                a = this.house.house.i18n.filter(elem=>elem.locale=="en");
            return a[0]? a[0].short_description:'';
        },

        internal_link () {
            let a = this.house.house.i18n.filter(elem=>elem.locale==this.$i18n.locale);
            if (a.length==0)
                a = this.house.house.i18n.filter(elem=>elem.locale=="en");
            return a[0]? '/housing/house/'+a[0].id.toString():'';
        },

    }
}
</script>
