<template>
<div class="ui basic grid segment">
    <table class="ui primary unstackable table" style="padding:0">
    <thead><tr>
        <th colspan="2" class="center aligned">
            <span class="ui grey text">{{$t('shipping-deposit')}}</span>
        </th>
    </tr></thead>
    <tbody>
        <tr>
            <td>Shipping fee</td>
            <td class="right aligned">€{{shipping_fee.toFixed(2)}}</td>
        </tr>
        <tr>
            <td>Drone deposit</td>
            <td class="right aligned">€{{deposit.toFixed(2)}}</td>
        </tr>
    </tbody>
    <tfoot><tr>
        <td><b>Total</b></td>
        <td class="right aligned"><b>€{{total.toFixed(2)}}</b></td>
    </tr></tfoot>
    </table>

    <div class="centered row">
        <div class="thirteen wide column">
            <payment-ui ref="paymentui-deposit"></payment-ui>
        </div>
    </div>

    <div class="centered row" style="padding: 0;">
        <div class="thirteen wide column">
            <div class="ui negative message" v-if="pay_error">
                <ul class="list">
                    <li>{{pay_error}}</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="centered row">
        <div class="ui button" style="width: 100px" @click="restart"
            :class="{'loading': restart_loading, 'disabled': restart_loading}">
            {{$t("step1")}}
        </div>
        <div class="ui primary button" style="width: 100px" @click="pay"
            :class="{'loading': pay_loading, 'disabled': pay_loading}">
            {{$t("pay")}}
        </div>
    </div>
</div>
</template>

<script>
import PaymentUi from '@/components/PaymentUi.vue'

export default {
    props: ['shipping_fee', 'deposit'],
    
    components: {
        PaymentUi
    },

    data () {
        return {
            restart_loading: false,
            pay_loading: false,
            pay_error: '',
        }
    },

    computed: {
        total () {
            return this.shipping_fee + this.deposit;
        },
    },

    methods: {
        async restart () {
            try {
                this.restart_loading = true;
                await this.$store.dispatch('housing/payDeposit', {'pay': 'restart'});
            } catch (error) {
                console.log(error);
            } finally {
                this.restart_loading = false;
            }
            
            this.$router.go(0);
        },

        async pay () {
            try {
                this.pay_loading = true;
                this.pay_error = '';
                let response = await this.$store.dispatch('housing/payDeposit', {'pay': 'create', 'total': this.total});
                if (response.data.client_secret) {
                    this.pay_error = await this.$refs['paymentui-deposit'].submit(response.data.client_secret);
                    if (this.pay_error === "") {
                        await this.$store.dispatch('housing/payDeposit', {'pay': 'confirm'});
                        this.$router.push('/deposit-success');
                    }
                }
            } catch (error) {
                if (error.response)
                    this.pay_error = error.response.data.message;
                else if (error.message)
                    this.pay_error = error.message;
                else
                    this.pay_error = this.$t('pay-error');
            } finally {
                this.pay_loading = false;
            }
        }
    }

}
</script>

<i18n>
{
    "en": {
        "shipping-deposit": "Drone Deposit and Shipping Fee",
        "pay": "Pay",
        "step1": "Step 1",
        "pay-error": "Sorry, there is an error in your payment process. Please contact us or try again later.",
    }
}
</i18n>