export default {
    methods: {
        gUpperFirstChar: function (s) {
            return s.charAt(0).toUpperCase() + s.slice(1);
        },

        gErrorHandler: function (error) {
            let msg = '';
            if (error.response) {
                msg = error.response.data.message;
            } else if (error.message) {
                msg = error.message;
            } else {
                console.log(error);
            }
            if (msg)
                this.gToast(msg);
            throw error;
        },

        gToast: function(msg, color='orange') {
            $('body').toast({
                class: color,
                displayTime: 5000,
                closeIcon: true,
                message: msg,
                position: 'top center'
            });
        },

        gHouseBookingsReform (d) {
            let o = [];
            d.forEach((item)=>{                
                let b = luxon.DateTime.fromISO(item.booking_iso).toLocal().toJSDate();
                let a = item.house.i18n.filter(elem=>elem.locale==this.$i18n.locale);
                if (a.length==0)
                    a = item.house.i18n.filter(elem=>elem.locale=="en");
                let c = item.realtor.is_company? item.realtor.company_name : item.realtor.agent.user.first_name;
                let u = item.order.user.first_name;
                o.push({
                    booking: Intl.DateTimeFormat('default', {
                        year: 'numeric', month: 'short', day: '2-digit', 
                        hour: 'numeric', minute: 'numeric', hour12: true})
                        .format(b)
                        .replace("am", "AM")
                        .replace("pm","PM"),
                    id: String(item.id).padStart(8, '0'),
                    address: a[0]? a[0].address: '',
                    agent: this.gUpperFirstChar(c),
                    viewer: u ? this.gUpperFirstChar(u): '',
                    status: item.status
                });
            });
            return o;
        },

        gDatetimeString (d) {
            return Intl.DateTimeFormat('default', {
                year: 'numeric', month: 'short', day: '2-digit', 
                hour: 'numeric', minute: 'numeric', hour12: true})
                .format(d)
                .replace("am", "AM")
                .replace("pm","PM");
        },


    },

    computed: {
        mixin_housePriceUnit () {
            if (this.house.house.periodicity == "once")
                return ""
            else if (this.house.house.periodicity == "day" && this.house.house.type == "hotel")
                return " / night"
            else
                return " / " + this.house.house.periodicity
        },

        mixin_houseExtraContent () {
            var DateTime = luxon.DateTime;
            let s = ["updated on "+DateTime.fromISO(this.house.house.updated_at, { zone:"utc"}).toLocaleString(DateTime.DATE_MED)];
            if (this.house.house.tenants_count)
                s.unshift(this.house.house.tenants_count + " people live in"); 
            if (this.house.house.room_type)
                s.unshift(this.house.house.room_type + " room");
            if (this.house.house.bedrooms_count) 
                s.unshift(this.house.house.bedrooms_count + " bedrooms");
            return s.join(", ");
        }
    },
}
