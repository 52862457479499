export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "remote-viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Viewing"])},
        "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing"])},
        "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal (Inc. VAT)"])}
      },
      "cn": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
        "remote-viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["远程观视"])},
        "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看房"])},
        "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
        "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合计 (含税)"])}
      }
    }
  })
}
