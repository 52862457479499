export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "terms-of-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
        "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "all-rights-reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])}
      },
      "cn": {
        "terms-of-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用条款"])},
        "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
        "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
        "all-rights-reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版权所有"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话："])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮件："])}
      }
    }
  })
}
