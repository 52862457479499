export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload (Max. 5 Photos)"])},
        "upload-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
      },
      "cn": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传 (做多5张)"])},
        "upload-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])}
      }
    }
  })
}
