import { createApp } from 'vue'
import { createI18n } from "vue-i18n"

import router from '@/routes'
import store from '@/store'
import App from '@/App.vue'


Parse.serverURL = 'https://parseapi.back4app.com'; 
// development, gifts.grief@gifts2grief.com Sample
// Parse.initialize(
//   'WrGuNNZGT7NbVEriyojFPFRsNfD7fEb2YWjT9wF1', 
//   '9ql97NkqsMfe3hEANhiJBFbWUC9sj1Up1klZEWoo'
// );
// microhalo
Parse.initialize(
  'mqgCR8syg81HA6oZp1CtUAv6KVpw0lw8R27661rj', 
  'gBKLHSUHHXwbCRl6f29Ct806m0Mgjbup2wuWuz2A'
);
Parse.User.enableUnsafeCurrentUser();

const i18n = createI18n({
    locale: "en", // set locale
    fallbackLocale: "en", // set fallback locale
    messages: {
      en: {'login-first': 'Please log in first!'},
      cn: {'login-first': '请先登录！'},
    }
})


axios.defaults.baseURL = '/base'

// development
//axios.defaults.baseURL = 'https://192.168.0.22:5000'
// axios.defaults.baseURL = 'https://10.42.0.83:5000'
// axios.defaults.baseURL = 'https://192.168.0.48:25000'
axios.interceptors.request.use( (config)=>{
  config.headers.post['locale'] = i18n.global.locale;
  return config;
}, null, {synchronous: true})

const axios_ = ()=>{
  if (Parse.User.current())
    return axios.create({
      headers:{'Authorization': 'Bearer '+Parse.User.current().getSessionToken()}
    });
  else {
    $('body').toast({
      class: 'orange',
      displayTime: 5000,
      closeIcon: true,
      message: i18n.global.t('login-first'),
      position: 'top center'
    });
    throw {'name': 'axios_', 'message': 'unauthorized'};
  }
}

// const axios_ = ()=>{
//   return axios.create({
//     headers:{'Authorization': 'Bearer '+Parse.User.current().getSessionToken()}
//   });
// }

var app = createApp(App)
    .use(router)
    .use(i18n)
    .use(store)

var DateTime = luxon.DateTime;
app.provide('DateTime', DateTime);
var Interval = luxon.Interval;
app.provide('Interval', Interval);


// jzhou
var stripe = Stripe('pk_test_51JWHkHCwH0GavkEiXEBbgICyQag8DwdHnled8yYP9afVP4qlJQkSrMduw1CWGXWRVl7dpP7KFw5WbSM5xS0rjMhv00e5VhpDBY')
// microhalo
// var stripe = Stripe('pk_test_51KgU7RIjB9EtBDawCGVy6b5woe9QjgjaJXQtUlQ69gXGLpuguNoLqVQtVtYaV2bAklwH2LseIsQ2jKOUOIdwwbt800whV5vbYZ')
app.provide('stripe', stripe);

app.mount('#app')

export { axios_ }
