export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "crowd-counting-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crowd Counting"])},
        "remote-sensing-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Sensing"])},
        "anomaly-detection-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anomaly Detection"])},
        "object-detection-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Detection"])},
        "face-detection-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face Detection"])},
        "image-retrieval-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Retrieval"])},
        "activity-recognition-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Recognition"])},
        "text-recognition-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Recognition"])},
        "nlp-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural Language Processing"])},
        "crowd-counting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A neural network is built to estimate the count of objects from an image with arbitrary crowd density and arbitrary perspective. The objects can be humans, farm animals, blood cells, trees, etc. The network can be deployed on an Intel Movidius neural compute stick for edge computing, such that the GDPR issue can be avoided."])},
        "remote-sensing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image segmentation can be applied to satellite and drone images to locate the regions of interest. For example, we detected the house roofs from satellite images to help the researchers from the Pennsylvania State University study the rural areas of Alaska. Moreover, with multispectral images from satellites and drones, together with the in-situ data, the environment can be well monitored. For example, we built neural networks to have a detailed study of soil characteristics and a better estimation of soil moisture on a large scale, which is very useful in precision agriculture."])},
        "anomaly-detection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video anomaly detection aims to localize anomalies in a video in space and/or time. The anomalies could be abnormalities, novelties and outliers. In our recent work a new metric was designed to have a fine grained assessment of the detection. The techniques can be also applied to other data and applications, such as network monitoring, financial fraud detection, predictive maintenance, etc."])},
        "object-detection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can detect predefined objects such as cars in drone footage, pedestrians in surveillance camera, polyps in endoscopy images, etc. The objects can be detected with morden approaches built on neural networks such as faster R-CNN, SSD, YOLO, etc, however those methods could be power hungry. The objects can also be detected with conventional approaches such as SVM and LDA, which are resource economic but less accurate."])},
        "face-detection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face detection and blurring has become a routine in video surveillance. Not only can our model detect the faces, but also detects the face attributes such as the gender,         which is useful in market research. All algorithms run on neural computing sticks as edge computing. Only the statistics are reported. No personal data is retained         and the GDPR is complied."])},
        "image-retrieval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The images of interest can be retrieved by analyzing the content of the image using computer vision techniques.         Our technology had been applied to build user interest profiles for recommendation and personalisation in a pilot project for a hotel booking website.         The techniques also helped in recent healthcare studies from Duke University."])},
        "activity-recognition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human activities can be recorded with multiple sensors built in smart phone, fitbit, google glass, etc. for futher analysis.         We had built a platform with Google Glass to help football coaches capture and annotate interesting events during training and matches.         The platform applies the hindsight recording principle and automatically detects those interesting events indicated by predefined hand gestures."])},
        "text-recognition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We trained neural networks to translate graphic text embedded in images into computer readable text. The graphic text can be printed text,         artistic text or handwritten text. It is very useful in process automation. Our technology had helped an advertising company index thousands of images without any labels         hidden in their database."])},
        "nlp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We built neural networks to identify the various forms of online racist hateful speech which is a concern from the European Network Against Racism,         Irish Human Rights and Equality Commission and National Anti Bullying Research and Resource Center.         Our technology helped social scientists explore the massive amount of social media feeds efficiently and draw insights from data collected."])},
        "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI Solutions"])}
      },
      "cn": {
        "crowd-counting-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人群统计"])},
        "remote-sensing-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遥测遥感"])},
        "anomaly-detection-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["异常侦测"])},
        "object-detection-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物体侦测"])},
        "face-detection-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人脸侦测"])},
        "image-retrieval-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图像检索"])},
        "activity-recognition-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行为识别"])},
        "text-recognition-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文字识别"])},
        "nlp-head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自然语言处理"])},
        "crowd-counting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A neural network is built to estimate the count of objects from an image with arbitrary crowd density and arbitrary perspective. The objects can be humans, farm animals, blood cells, trees, etc. The network can be deployed on an Intel Movidius neural compute stick for edge computing, such that the GDPR issue can be avoided."])},
        "remote-sensing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image segmentation can be applied to satellite and drone images to locate the regions of interest. For example, we detected the house roofs from satellite images to help the researchers from the Pennsylvania State University study the rural areas of Alaska. Moreover, with multispectral images from satellites and drones, together with the in-situ data, the environment can be well monitored. For example, we built neural networks to have a detailed study of soil characteristics and a better estimation of soil moisture on a large scale, which is very useful in precision agriculture."])},
        "anomaly-detection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video anomaly detection aims to localize anomalies in a video in space and/or time. The anomalies could be abnormalities, novelties and outliers. In our recent work a new metric was designed to have a fine grained assessment of the detection. The techniques can be also applied to other data and applications, such as network monitoring, financial fraud detection, predictive maintenance, etc."])},
        "object-detection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can detect predefined objects such as cars in drone footage, pedestrians in surveillance camera, polyps in endoscopy images, etc. The objects can be detected with morden approaches built on neural networks such as faster R-CNN, SSD, YOLO, etc, however those methods could be power hungry. The objects can also be detected with conventional approaches such as SVM and LDA, which are resource economic but less accurate."])},
        "face-detection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face detection and blurring has become a routine in video surveillance. Not only can our model detect the faces, but also detects the face attributes such as the gender, which is useful in market research. All algorithms run on neural computing sticks as edge computing. Only the statistics are reported. No personal data is retained and the GDPR is complied."])},
        "image-retrieval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The images of interest can be retrieved by analyzing the content of the image using computer vision techniques. Our technology had been applied to build user interest profiles for recommendation and personalisation in a pilot project for a hotel booking website. The techniques also helped in recent healthcare studies from Duke University."])},
        "activity-recognition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human activities can be recorded with multiple sensors built in smart phone, fitbit, google glass, etc. for futher analysis. We had built a platform with Google Glass to help football coaches capture and annotate interesting events during training and matches. The platform applies the hindsight recording principle and automatically detects those interesting events indicated by predefined hand gestures."])},
        "text-recognition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We trained neural networks to translate graphic text embedded in images into computer readable text. The graphic text can be printed text, artistic text or handwritten text. It is very useful in process automation. Our technology had helped an advertising company index thousands of images without any labels hidden in their database."])},
        "nlp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We built neural networks to identify the various forms of online racist hateful speech which is a concern from the European Network Against Racism, Irish Human Rights and Equality Commission and National Anti Bullying Research and Resource Center. Our technology helped social scientists explore the massive amount of social media feeds efficiently and draw insights from data collected."])},
        "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["智能服务"])}
      }
    }
  })
}
