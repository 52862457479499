export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
        "sign-up-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
        "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
        "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
        "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
        "signup-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account already exists. Please login instead."])},
        "first-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your first name"])},
        "last-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your last name"])},
        "email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plase enter a valid email"])},
        "password-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the password"])},
        "password-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 8 characters"])},
        "password-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number"])},
        "confirm-password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password and confirm password do not match"])}
      },
      "cn": {
        "sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册新用户"])},
        "sign-up-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
        "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
        "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
        "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
        "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
        "signup-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户已存在, 请登录"])},
        "first-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的名"])},
        "last-name-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的姓"])},
        "email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效邮箱地址"])},
        "password-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入密码"])},
        "password-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不得短于8个字符"])},
        "password-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码至少含有一个大写字母，一个小写字母和一个数字"])},
        "confirm-password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码与确认密码不相符"])}
      }
    }
  })
}
