<template>
<div class="ui basic grid segment">
    <div class="centered row" style="margin: 40px 0 0 0;">
        <h2 class="ui grey header">{{$t("sign-in")}}</h2>
    </div>

    <div class="centered row">
    <div class="sixteen wide mobile eight wide tablet six wide computer column">
            <form class="ui form" id="login_form" @keyup.enter.prevent="login">
                <div class="field">
                    <label><span>{{$t("e-mail")}}</span></label>
                    <div class="ui large input">
                    <input type="text" name="email" :placeholder="$t('e-mail')" 
                        v-model.trim="email">
                    </div>
                </div>

                <div class="field">
                    <label><span>{{$t("password")}}</span></label>
                    <div class="ui large input">
                    <input type="password" name="password" :placeholder="$t('password')" 
                        v-model.trim="password" @keyup.enter.prevent="keyfun">
                    </div>
                </div>
                <div class="ui error message"></div>
                <div class="ui negative message" v-if="server_error">
                <ul class="list">
                    <li>{{server_error}}</li>
                </ul>
                </div>
                <div class="center-item">
                    <div class="ui primary button" :class="{'loading': loading, 'disabled': loading}" 
                        @click="login">{{$t("login")}}</div>
                </div>
                <div class="center-item" style="margin-top: 10px;">
                    <a href="javascript:;" @click="forget_password">{{$t('forget-password')}} ?</a>
                </div>

            </form>
    </div>
    </div>

    <div class="centered row">
    <div class="sixteen wide mobile eight wide tablet six wide computer column">
        <div class="ui divider"></div>
        <div class="center-item">
        <div class="ui primary button" @click="$router.push('/signup')">
            {{$t('sign-up')}}
        </div>
        </div>
    </div>
    </div>

</div>
</template>

<script>
function login_validation (ctx) {
    $('#login_form').form({
        rules: {
            custom: function (value) {
                return value && value.length >= 8 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value);
            }
        },

        fields: {
            email: { identifier: 'email',
                rules: [
                    {
                        type: 'email',
                        prompt: ()=>{
                            return ctx.$t("email-error");
                        }
                    }
                ]
            },
            password: { identifier: 'password',
                rules: [
                    {
                        type: 'custom',
                        prompt: (value)=>{
                            if (!value || value.length==0)
                                return ctx.$t("password-empty");
                            else if (value.length>0 && value.length<8)
                                return ctx.$t("password-short");
                            else
                                return ctx.$t("password-invalid");
                        }
                    }
                ]
            }
        }
    });
}

export default {
data () {
    return {
        email: "",
        password: "",
        server_error: "",
        loading: false,
    }
},

methods: {
    async forget_password () {
        this.server_error = "";
        $("#login_form").form("validate field", "email");
        $("#login_form").form("set optional", "password", true);
        $("#login_form").form("validate form");
        if ( $("#login_form").form('is valid', 'email') ) {
            try {
                console.log("resend password");
                await this.$store.dispatch("auth/resendPassword", {'email': this.email});
                $('body').toast({
                    class: 'inverted olive',
                    displayTime: 5000,
                    closeIcon: true,
                    message: this.$t('password-send'), 
                    position: 'top center',          
                });

            } catch (error) {
                if (error.response)
                    this.server_error = error.response.data.message;
                else
                    this.server_error = error.message;
                return;
            }
            
        }
    },

    async login () {
        this.server_error = "";
        this.loading = true;
        await this.$store.dispatch("auth/logOut");
        $("#login_form").form("set optional", "password", false);
        $("#login_form").form("validate form");
        if ($("#login_form").form("is valid")) {
            try {
                await Parse.User.logIn(this.email.toLowerCase(), this.password);
            } catch (error) {
                this.server_error = error.message;
                this.loading = false;
                return;
            }

            try {
                let response = await this.$store.dispatch("auth/logIn");
                this.$store.commit('auth/SET_USER', response.data);
                await this.$router.push('/');
            } catch (error) {
                if (error.response) {
                    console.log("error response", error.response);
                    this.server_error = error.response.data.message;
                    if (error.response.status==449) {
                        this.$router.push('/email-confirmation');
                    }
                } else {
                    this.server_error = error.message;
                    this.loading = false;
                }
            }
        }
        this.loading = false;
    }
},

mounted () {
    $("#login_form").submit(e=>e.preventDefault());
    this.$nextTick(login_validation(this));
}

}

</script>

<style scoped>
/* label span {
    color: var(--primary-color);
} */
</style>

<i18n>
{
  "en": {
      "sign-in": "Sign In",
      "e-mail": "E-mail",
      "password": "Password",
      "login": "Login",
      "forget-password": "Forget the password",
      "sign-up": "Create a new account",
      "email-error" : "Please enter a valid email", 
      "password-empty": "Please enter the password",
      "password-short": "The password must be at least 8 characters",
      "password-invalid": "The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number",
      "password-send": "An email for password reset has been sent to you"
  },
    
  "cn": {
      "sign-in": "登录",
      "e-mail": "邮箱",
      "password": "密码",
      "login": "登录",
      "forget-password": "忘记密码了",
      "sign-up": "注册新用户",
      "email-error": "请输入有效邮箱地址",
      "password-empty": "请输入密码",
      "password-short": "密码不得短于8个字符",
      "password-invalid": "密码至少含有一个大写字母，一个小写字母和一个数字",
      "password-send": "密码重置邮件已发送到你的邮箱"
  },
}
</i18n>
