<template>
<div class="ui grid segment" :class="{'loading': loading}">
<div class="row">
    <div class="column">
        <span class="ui primary header">
            <i class="address card icon"></i>
            {{$t('realtor-information')}}
        </span>
        <router-link to="/housing/edit-realtor" class="ui primary large label" style="float: right">{{$t('edit')}}</router-link>
    </div>
</div>

<div class="stackable row" style="padding-top: 0; padding-bottom: 0;">
    <div class="four wide column">
        <img class="ui centered medium image" :src="profile_image" style="border: red 1px solid"/>
        <div class="center-item"><b>{{$t('profile-image')}}</b></div>
    </div>
    <div class="twelve wide column">
        <p><b>{{$t('correspondent')}}:</b>&nbsp;&nbsp; {{correspondent}}</p>
        <p><b>{{$t('contact-number')}}:</b>&nbsp;&nbsp; {{info.phone}}</p>
        <p><b>{{$t('address')}}:</b>&nbsp;&nbsp; {{info.address}}</p>
        <p><b>{{$t('page-url')}}:</b>&nbsp;&nbsp; 
        <a :href="info.url" target="_blank" style="margin-left: 6px;">{{link_url}}</a>
        </p>
        <p><b>{{$t('workdays')}}:</b>&nbsp;&nbsp; {{workdays}}</p>
        <p><b>{{$t('start-hour')}}:</b>&nbsp;&nbsp; {{startat}}</p>
        <p><b>{{$t('end-hour')}}:</b>&nbsp;&nbsp; {{endat}}</p>
        <p><b>{{$t('rest-hours')}}:</b>&nbsp;&nbsp;{{restat}} </p>
        <p><b>{{$t('time-zone')}}</b>&nbsp;&nbsp;{{info.time_zone}}</p>
        <p><b>{{$t('drone')}}:</b>&nbsp;&nbsp;{{}}</p>
        <p><b>{{$t('registered-number')}}:</b>&nbsp;&nbsp;{{}}</p>
        <p><b>{{$t('drone-status')}}:</b>&nbsp;&nbsp;{{}}</p>
    </div>
</div>

<div class="row" style="padding-top: 0">
    <div class="column">
    <div v-for="description, idx in info.i18n" :key=idx style="margin-bottom: 8px;">
        <div class="ui tiny header" style="margin: 0">{{$t('short-description', description.locale)}}</div>
        <p>{{description.short_description}}</p>
        <div class="ui tiny header" style="margin: 0">{{$t('long-description', description.locale)}}</div>
        <p>{{description.long_description}}</p>
    </div>
    </div>
</div>


</div>
</template>

<script>
import mixin from '@/mixin'

export default {
inject: ['DateTime'],
mixins: [mixin],

props: ['info'],

data () {
    return {
        // info: {},
        loading: false,
    }
},

// async created () {
//     try{
//         this.loading = true;
//         let response = await this.$store.dispatch('housing/fetchRealtor');
//         this.info = {...response.data}
//         // if (response.data.correspondent == null)
//         //     this.info.correspondent = this.gUpperFirstChar(this.$store.state.auth.user.first_name) + 
//         //         " " + this.gUpperFirstChar(this.$store.state.auth.user.last_name);
//     } catch (error) {
//         this.gErrorHandler(error);
//     } finally {
//         this.loading = false;
//     }

// },

computed: {
    link_url () {
        let limit = 200;
        if (this.info.url == null)
            return '';
        else if (this.info.url.length < limit)
            return this.info.url;
        else
            return this.info.url.substring(0, limit)+" ...";
    },

    workdays () {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 
            'Thursday', 'Friday', 'Saturday'];
        if (this.info.work_days==null)
            return '';
        let work = this.info.work_days.split(',')
        let ret = ''
        work.forEach((item)=>{
            ret += days[item] + ', '
        })
        return ret;
    },

    startat () {
        if (this.info.start_hour) {
            let start = this.DateTime.fromISO(this.info.start_hour_iso,
                { zone:this.info.time_zone});
            return start.toLocaleString(this.DateTime.TIME_SIMPLE)
        } else {
            return '';
        }
    },

    endat () {
        if (this.info.end_hour) {
            let end = this.DateTime.fromISO(this.info.end_hour_iso, 
                { zone:this.info.time_zone});
            return end.toLocaleString(this.DateTime.TIME_SIMPLE)
        } else {
            return '';
        }
    },

    restat () {
        if (this.info.rest_hours.length==0)
            return '';
        let hours = this.info.rest_hours.split(',');
        let ret = '';
        hours.forEach((item)=>{
            // we comment this as they are not utc
            // ret += this.DateTime.utc().set({hour: item, minute: 0}).toLocaleString(
            //     this.DateTime.TIME_SIMPLE
            // ) + ', ';
            ret += item+':00, ';

        });
        return ret;
    },

    profile_image () {
        if (this.info.profile_images && this.info.profile_images.length > 0)
            return this.info.profile_images[0];
        else
            return require('@/assets/no-image.jpg');
    },

    correspondent () {
        if (this.info.correspondent == null && this.$i18n.locale == "cn")
            return this.$store.state.auth.user.last_name + 
                " " + this.$store.state.auth.user.first_name;
        if (this.info.correspondent == null && this.$i18n.locale != "cn")
            return this.gUpperFirstChar(this.$store.state.auth.user.first_name) + 
                " " + this.gUpperFirstChar(this.$store.state.auth.user.last_name);
        
        return this.info.correspondent;
    }


},

}
</script>

<style scoped>
p {
    /* color: dimgrey; */
    margin-top: 4px;
    margin-bottom: 4px;
}
</style>

<i18n>
{
    "en": {
        "short-description": "Short description",
        "long-description": "Long description",
        "correspondent": "Correspondent",
        "contact-number": "Contact number",
        "address": "Address",
        "page-url": "Page url",
        "workdays": "Workdays",
        "start-hour": "Working hours start",
        "end-hour": "Working hours end",
        "rest-hours": "Rest hours (start at)",
        "time-zone": "Time zone",
        "drone": "Drone",
        "drone-status": "Status",
        "registered-number": "Registered number",
        "realtor-information": "Realtor Information",
        "edit": "Edit",
        "profile-image": "Profile image"
    },
    "cn": {
        "short-description": "短介绍",
        "long-description": "长介绍",
        "correspondent": "联系人",
        "contact-number": "联系电话",
        "address": "地址",
        "page-url": "网页地址",
        "workdays": "工作日",
        "start-hour": "上班",
        "end-hour": "下班",
        "rest-hours": "休息时间",
        "time-zone": "时区",
        "drone": "无人机",
        "drone-status": "寄送",
        "registered-number": "注册号",
        "realtor-information": "经纪人信息",
        "edit": "编辑",
        "profile-image": "头像"

    }
}
</i18n>