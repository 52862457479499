<template>
<div class="ui basic grid segment" id="footer">
    
    <div class="primary stackable vertical row">
        <div class="three wide column">
          <div class="ui inverted link list">
            <a href="#" class="item"><b>{{$t('terms-of-use')}}</b></a>
            <a href="#" class="item"><b>{{$t("privacy-policy")}}</b></a>
          </div>
        </div>
        <div class="five wide column">
          <b>{{$t('contact-us')}}</b>
          <div class="ui list">
            <div class="item"><span style="margin-right: 4px;">{{$t('phone')}}</span> +353 867319350</div>
            <div class="item"><span style="margin-right: 4px;">{{$t('email')}}</span> business@microhalo.ie</div>
          </div>
        </div>
        <div class="six wide right floated column">
            <b>Copyright © 2021 Microhalo - {{ $t("all-rights-reserved") }}</b>
        </div>   
    </div>


    <!-- <div class="primary stackable row">

        <div class="eight wide column">
        <div class="ui horizontal list">
			<router-link to="/terms" class="middle aligned item" >
                <a class="about"><b>{{ $t('terms-of-use') }}</b></a>
            </router-link>
			<router-link to="/privacy" class="middle aligned item" >
                <a class="about"><b>{{ $t("privacy-policy") }}</b></a>
            </router-link>
			<router-link to="/about" class="middle aligned item" >
                <a class="about"><b>{{ $t("contact-us") }}</b></a>
            </router-link>        
        
        </div>
        </div>

        <div class="computer only eight wide right aligned column">
            <b>Copyright © 2021 Microhalo - {{ $t("all-rights-reserved") }}</b>
        </div>
        <div class="tablet mobile only eight wide left aligned column">
            <b>Copyright © 2021 Microhalo - {{ $t("all-rights-reserved") }}</b>
        </div>

    </div> -->




</div>



</template>

<style scoped>
    .about {
        color: white;
    }

    #footer {
        margin-top: 80px;
    }

    .vertical.row {
        padding: 2em 0em 4em 0em;
    }

    a {
        color: white !important ;
        margin: 0 0 12px 0 !important;
    }
</style>

<i18n>
{
  "en": {
    "terms-of-use": "Terms of Use",
    "contact-us": "Contact Us",
    "privacy-policy": "Privacy Policy",
    "all-rights-reserved": "All Rights Reserved",
    "phone": "Phone:",
    "email": "Email:",
  },
    
  "cn": {
    "terms-of-use": "使用条款",
    "contact-us": "联系我们",
    "privacy-policy": "隐私政策",
    "all-rights-reserved": "版权所有",
    "phone": "电话：",
    "email": "邮件："
  },
}
</i18n>