<template>
<div class="ui basic grid segment">
<div class="row">
<div class="column" style="padding-top: 10px;">

    <h4 class="ui primary header">
        <i class="laptop house icon" style="margin-left: 10px;"></i>
        Booked Viewing
    </h4>
    <div class="ui divider"></div>

    <house-upcoming-bookings />
    <div style="margin-top: 15px;"></div>
    <house-past-bookings />

</div>
</div>
</div>
</template>

<script>
import HouseUpcomingBookings from '@/apps/housing/components/HouseUpcomingBookings.vue'
import HousePastBookings from '@/apps/housing/components/HousePastBookings.vue'

export default {
    components: {
        HouseUpcomingBookings,
        HousePastBookings
    },
}
</script>