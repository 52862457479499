<template>
<div class="ui basic grid segment" id="outer">

<div class="primary row">
<div class="column">

    <div class="ui basic grid segment" id="inner">
    <div class="row">
    <div class="column">
        <div class="ui calendar" id="date-user"></div>
    </div>
    </div>

    <div class="row">
        <label class="ui fluid label"><div class="center-item">Hour</div></label>
    </div>

    <div class="row">
    <div class="column">
        <div class="ui calendar" id="hour-user"></div>
    </div>
    </div>

    <div class="row">
        <label class="ui fluid label"><div class="center-item">Minute</div></label>
    </div>

    <div class="row">
    <div class="column">
        <table class="ui celled unstackable table" id="minute-user" @click="minute_select">
        <tbody>
        <tr class="center aligned">
            <td data-value="00" class="selectable">: 00</td>
            <td data-value="15" class="selectable">: 15</td> 
        </tr>
        <tr class="center aligned">
            <td data-value="30" class="selectable">: 30</td>
            <td data-value="45" class="selectable">: 45</td>
        </tr>
        </tbody>
        </table>
    </div>
    </div>
    </div>

</div>
</div>
</div>
</template>

<script>
function update_cell(date, container, uiclass) {
    container.find('td').each(function () {
        var cell = $(this);
        var cellDate = cell.data('date');
        if (!cellDate) {
            return;
        }
        var disabled = cell.hasClass($.fn.calendar.settings.className.disabledCell);
        // var active = cell.hasClass($.fn.calendar.settings.className.activeCell);
        var right = !!cellDate && !!date && cellDate.getTime()==date.getTime();
        if (!disabled && right)
            cell.addClass(uiclass);

    });
}

import mixin from '@/mixin'

export default {
inject: ['DateTime'],
mixins: [mixin],

props: {
    'realtor_info': {
        type: Object,
        default () {
            return {}
        }
    },
    'booked_slots': {
        type: Array,
        default () {
            return []
        }
    }
},

data () {
    return {
        work_days: this.realtor_info.work_days,
        start_hour_iso: this.realtor_info.start_hour_iso || this.DateTime.local().set({hour: 9}).toISO(),
        end_hour_iso: this.realtor_info.end_hour_iso || this.DateTime.local().set({hour: 17}).toISO(),
        rest_hours: this.realtor_info.rest_hours,
        time_zone: this.realtor_info.time_zone || this.DateTime.local().zoneName,

        disabled_datetimes: [],
        disabled_dates: [],
        disabled_times: [],
        // event_datetimes: [],

        min_date: null,
        max_date: null,
        min_hour: null,
        max_hour: null,

        selected_date: null,
        selected_hour: null,
        selected_minute: null,

        selected_js: null,

    }
},

created () {
    // let response = {'data': {
    //     'unavailable_slots': ['2021-09-21T15:30:00', '2021-09-23T20:00:00', 
    //         '2021-09-23T20:15:00', '2021-09-23T20:30:00', 
    //         '2021-09-23T20:45:00', '2021-09-24T18:15:00'],
    // }};

    // this.unavailable_slots = response.data['unavailable_slots'].map((item)=>{
    //     return this.DateTime.fromISO(item, {zone: "utc"}).toLocal();
    // }); 

    this.unavailable_slots = this.booked_slots.map(
        item=>this.DateTime.fromISO(item).toLocal());

    // console.log("unavailable slots", this.unavailable_slots.map(el=>el.toString()));

    // we toLocal() later in the setting
    this.min_date = this.DateTime.local().startOf('day').plus({days: 2}).setZone(this.time_zone);
    this.max_date = this.min_date.plus({days: 30});
    let rest_hours = [];
    if (this.rest_hours)
        rest_hours = this.rest_hours.split(',').map(elem=>parseInt(elem));
    let work_days = [];

    if (this.work_days==null)
        work_days = [1,2,3,4,5];
    else if (this.work_days)
        work_days = this.work_days.split(',').map(elem=>parseInt(elem));

    this.min_hour = this.DateTime.fromISO(this.start_hour_iso, 
        {zone: this.time_zone});
    this.min_hour = this.min_date.set({hour: this.min_hour.hour}).toLocal();

    this.max_hour = this.DateTime.fromISO(this.end_hour_iso, 
        {zone: this.time_zone}).plus({hours: -1});
    this.max_hour = this.min_date.set({hour: this.max_hour.hour}).toLocal();
    
    for (let d=this.min_date; d<this.max_date; d=d.plus({hours: 1})) {
        if (!rest_hours.includes(d.hour) && work_days.includes(d.weekday%7))
            continue;
        this.disabled_datetimes.push( d.toLocal().set({minute: 0, second: 0, millisecond: 0}) );
    }

    for (let i=0; i<rest_hours.length; i++) {
        let rest_hour = this.min_date.set({hour: rest_hours[i]});
        this.disabled_times.push(rest_hour.toLocal().startOf('hour').toJSDate());
    }

    if (this.min_hour.hour > this.max_hour.hour)
    {
        for (let t=this.max_hour.hour+1; t<this.min_hour.hour; t=t+1)
            this.disabled_times.push(this.min_hour.set({hour: t}).toJSDate());
    }
    
    this.disabled_dates.push(this.DateTime.local().startOf('day').toJSDate());
    for (let d=this.min_date; d<this.max_date; d=d.plus({days: 1})) {
        let d_local = d.toLocal();
        let one_day = this.disabled_datetimes.filter(elem=>+elem.startOf('day')===+d_local.startOf('day'));
        if (one_day.length>=24) {
            this.disabled_dates.push(d_local.toJSDate());
        }
    }
},

beforeUnmount () {
    $('.ui.calendar').empty();
},

mounted () {
    $('#date-user').calendar({
        'inline': true,
        'type': 'date',
        'startMode': 'day',
        'selectAdjacentDays': true,
        'disableYear': true,
        'disableMonth': true,
        'disabledDates': this.disabled_dates,
        'minDate': this.min_date.toLocal().toJSDate(),
        'maxDate': this.max_date.minus({days: 1}).toLocal().toJSDate(),
        'onSelect': (date, mode)=> this.date_select(date, mode)
    });

    $('#hour-user').calendar({ 
        'inline': true,
        'type': 'time',
        'startMode': 'hour',
        'disableMinute': true,
        'disabledDates': this.disabled_times,
        'minDate': this.min_hour.hour > this.max_hour.hour ? this.min_hour.startOf('day').toJSDate() : this.min_hour.toJSDate(),
        'maxDate': this.min_hour.hour > this.max_hour.hour ? this.max_hour.endOf('day').toJSDate() : this.max_hour.toJSDate(),
        'formatter': {
            time: function (date, settings) {
                if (!date) return '';
                var hour = date.getHours();
                var ampm = '';
                if (settings.ampm) {
                    ampm = ' ' + (hour < 12 ? settings.text.am : settings.text.pm);
                    hour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
                }
                return hour + ampm;    
            },
        },
        'onSelect': (date, mode)=>{
            this.hour_select(date, mode);
            return false;
        }
    });
},

watch: {
    selected_js (newValue, oldValue) {
        this.$emit('dateSelected', newValue);
    }
},

methods: {
    clear_cells (container, uiclass) {
        container.find('td').each(function () {
            var cell = $(this);
            cell.removeClass(uiclass);
        });    
    },

    update_minute_cells (minute, container, uiclass) {
        container.find('td').each(function () {
            var cell = $(this);
            var cellDate = Number(cell.data('value'));
            var disabled = cell.hasClass('disabled');
            var right = cellDate==minute;
            if (!disabled && right)
                cell.addClass(uiclass);
        });    
    },

    date_select (date, mode) {
        this.selected_js = null;
        this.selected_hour = null;
        this.selected_minute = null;
        $('#hour-user').calendar('set date', date);
        $('#hour-user .active').removeClass('active');

        this.selected_date = this.DateTime.fromJSDate(date);
        let unavailable_hours = this.unavailable_slots.filter(
            item=>item.hasSame(this.selected_date, 'day'));

        for (let h=this.min_hour; h<=this.max_hour; h=h.plus({hours: 1})) {
            let slots = unavailable_hours.filter(item=>item.hour==h.hour);
            if (slots.length == 4)
                update_cell(slots[0].set(
                    {minute: 0, second: 0, millisecond: 0}).toJSDate(), 
                    $('#hour-user'), 'red disabled');
        }

        this.clear_cells($('#minute-user'), 'red disabled');
        this.clear_cells($('#minute-user'), 'active');
    },

    hour_select (date, mode) {
        this.selected_js = null;
        this.selected_minute = null;
        this.selected_hour = this.DateTime.fromJSDate(date);
        let unavailable_minutes = this.unavailable_slots.filter(
            item=>item.hasSame(this.selected_hour, 'hour'));

        this.clear_cells($('#minute-user'), 'red disabled');
        this.clear_cells($('#minute-user'), 'active');
        unavailable_minutes.forEach((item)=>{
            this.update_minute_cells(item.get('minute'), $('#minute-user'), 'red disabled');
        });

        $('#hour-user .active').removeClass('active');
        let date_copy = new Date(date.getTime())
        date_copy.setMinutes(0,0,0)
        update_cell(date_copy, $('#hour-user'), 'active');
    },

    minute_select (event) {
        $('#minute-user .active').removeClass('active');
        this.selected_js = null;
        this.selected_minute = null;
        if (this.selected_date && this.selected_hour) {
            this.selected_minute = this.selected_hour.set(
                {minute: Number(event.target.getAttribute('data-value'))});
            this.selected_js = this.selected_minute.toJSDate();
        }
        event.target.classList.toggle('active');
    },

    validation () {
        if (this.selected_date === null)
            return this.$t('no-date');
        
        if (this.selected_hour === null)
            return this.$t('no-hour');

        if (this.selected_minute === null)
            return this.$t('no-minute');

        return '';
    },

    update_emit () {
        this.$emit('update', this.selected_js);
    }
},

}
</script>

<style scoped>
#outer .row {
    padding: 5px 0;
}
#outer .column {
    padding: 0 5px;
}


#inner .row {
    margin: 0;
    padding: 0 1px;
    background-color: WhiteSmoke;
}

#inner .column {
    margin: 0;
    padding: 0;
}

#minute-user td {
    padding: 6px !important;
}
</style>

<i18n>
{
"en": {
    "no-date": "No date is selected.",
    "no-hour": "No hour is selected.",
    "no-minute": "No minute is selected.",
},

"cn": {
    "no-date": "请指定看房日期",
    "no-hour": "请指定看房时间",
    "no-minute": "请指定看房时间",
},
}
</i18n>