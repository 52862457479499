export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "smart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artificial Intelligence"])},
        "smart-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microhalo's remote viewing is smart. The services are powered with AI technologies such as crowd counting, remote sensing, anomaly detection, etc.         Solutions can be either deployed in the cloud or run at edge with neural compute sticks, tailored to clients' need."])},
        "more-ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More AI Technologies"])}
      },
      "cn": {
        "smart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智能"])},
        "smart-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人工智能技术让Microhalo的远程观视智能化。作为一家无人机公司，Microhalo拥有一系列人工智能技术诸如人群计数、遥感探测、异常检测等来提供各种基于无人机的后台服务。        解决方案可以部署在服务器上作为云端服务，也可以运行在神经计算棒上以满足边缘计算需求。"])},
        "more-ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
      }
    }
  })
}
