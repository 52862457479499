<template>
<div class="ui container" id="page-container" v-if="loading">
  <div class="ui basic segment" style="margin-top: 6em;">
    <div class="ui active loader"></div>
    <p></p>
  </div>
</div>

<div class="ui container" id="page-container" v-else>
  <app-header></app-header>
  <div id="page-main">
    <router-view></router-view>
  </div>
  <app-footer></app-footer>
</div>

<!-- Cookie Consent, we comment it as we are only using essential cookies right now  -->
<!--
<div class="ui center aligned segment" id="cookie-consent">
    This website uses cookies to ensure you get the best experience on our website. 
    <a href="#" target="_blank" style="margin: 0em 1em;">Learn more</a>
    <div class="ui mini secondary button" style="margin: 0em 2em;">OK</div>
</div>
-->

</template>

<script>
import appHeader from './components/Header.vue'
import appFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    appHeader,
    appFooter,
  },

  data () {
    return {
      loading: true
    }
  },

  async beforeCreate () {
    this.loading = true;
    try {
      let response = await this.$store.dispatch('auth/logIn');
      this.$store.commit('auth/SET_USER', response.data);
    } catch {
      void 0;
    } finally {
      this.loading = false;
    }
  },

}
</script>

<style>
#cookie-consent {
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 6px;
}

#page-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: whitesmoke;  
}

#page-main {
	flex: 1;
}

img {
  vertical-align: top;
}

label span {
    color: var(--primary-color);
}

.site-p {
	font-family: monospace, Aerial, sans-serif; 
	line-height: 1.6em;
}

.ui.basic.grid.segment {
  padding: 0;
  margin: 0;
}

.ui.basic.grid.container {
  padding: 0;
  margin: 0;
}

:root{
  --primary-color: #3c75a9;
  --secondary-color: #7ca9cc;
}

.center-item {
  display: flex;
  justify-content: center;
}

</style>

<i18n>
{
  "en": {
    "profile": "Profile",
    "bookings": "Bookings",
    "jobs": "Jobs",
  },
  "cn": {
    "profile": "基本信息",
    "bookings": "个人预约",
    "jobs": "工作预约",
  }
}
</i18n>

