<template>
<div class="ui basic grid segment" :class="{'loading': loading}">

    <div class="centered row" style="padding: 0; margin-top: 5px;" id="participantsrow" ref="participantsrow">
        <div class="participant-container" v-for="(participant, ) in participants" 
            :key="participant.identity" :ref="participant.identity" :id="participant.identity">
        </div>
        <!-- <div id="participants"  style="overflow-x: auto; white-space: nowrap;">
        </div> -->
    </div>

    <div class="centered row" v-if="participants.length>0">
        <div id="active-participant" class="active-container">
            <camic class="top-overlay"></camic>
            <joy-stick class="bottom-overlay" v-show="joystick" ref="joystick"></joy-stick>
            <video autoplay playsinline muted
                style="width: 100%; height: auto; object-fit: contain; 
                    border-radius: 10px; display: block;">
            </video>
        </div>

        <!-- <div id="active-participant" style="position: relative;">
            <div class="participant main">
                <video autoplay playsinline muted 
                    style="opacity: 0; width: 100%; height: auto; object-fit: contain;">
                </video>
                <camic class="top-overlay"></camic>
                <joy-stick class="bottom-overlay" v-show="joystick" ref="joystick"></joy-stick>
            </div>
        </div> -->
    </div>
    <div class="centered row" style="padding: 0; border: 2x soild green;" v-else>
        <img src='@/assets/adhere.jpg' style="width: 50%; min-width: 320px;" />
    </div>

    <div class="centered row">
        <div class="ui small button" @click="back">Back</div>
        <div class="ui small primary button" @click="join">Join</div>
        <div class="ui small orange button" @click="leave">Leave</div>
    </div>


</div>
</template>

<script>
import { toRaw } from 'vue';
import mixin from '@/mixin';
const {connect} = Twilio.Video;

import Camic from '../components/Camic.vue';
import JoyStick from '../components/JoyStick.vue';

export default {

inject: ['DateTime'],
mixins: [mixin],

components: {
    Camic,
    JoyStick
},

data () {
    return {
        booking_id: this.$route.params.id,
        room: null,
        room_name: '',
        joystick: false,
        participants: [],

        connectOptions: {
            maxAudioBitrate: 16000,
            video: {height: 180, width: 240, frameRate: 16, name: ''},
            audio: true,

            // // Available only in Small Group or Group Rooms only. Please set "Room Type"
            // // to "Group" or "Small Group" in your Twilio Console:
            // // https://www.twilio.com/console/video/configure
            // bandwidthProfile: {
            //     video: {
            //     dominantSpeakerPriority: 'high',
            //     mode: 'collaboration',
            //     clientTrackSwitchOffControl: 'auto',
            //     contentPreferencesMode: 'auto'
            //     }
            // },
            // dominantSpeaker: true,
            // preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }],
        },
    }
},

mounted () {
    let elem = document.getElementById('participantsrow');
    elem.addEventListener('droneSubscribed', (e)=>{
        console.log("drone subscribed called");
        let track = e.detail.droneTrack();
        console.log("drone track", track);
        this.participants.push({
            'identity': 'Drone',
            'videoTracks': new Map().set('0', track),
        });
        this.$nextTick( ()=>{
                let container = document.getElementById('Drone');
                container.appendChild(track.track.attach());
                let container_video = $('#'+container.id+'> video');
                this.addVideoCss(container_video);
            });
        this.setActiveVideo(toRaw(this.participants), $('#active-participant'));      
        this.joystick = true;
        this.$refs['joystick'].set_room(toRaw(this.room));
        console.log("joystick", this.joystick);
    });
    elem.addEventListener('droneUnsubscribed', (e)=>{
        console.log("drone unsubscribed is called");
        let track = e.detail.droneTrack();
        console.log("drone unsubscribed track", track);
        track.detach().forEach( el=>{
            if ($(el).parent().attr('id')!=="active-participant")
                el.remove();
        } );
        this.participants = toRaw(this.participants).filter( p=>p.identity!=='Drone' );
        $("#active-participant").attr('data-identity', '');
        this.$nextTick(()=>{
            this.setActiveVideo(toRaw(this.participants), $('#active-participant'));
            this.joystick = false
            this.$refs['joystick'].set_room(null);
            console.log("joystick", this.joystick);
        });
    });
    elem.addEventListener('videoTrackUpdated', (track)=>{
        console.log("participants", this.participants);
        if ((track) && (track.name!=="drone")) {
            this.setActiveVideo(toRaw(this.participants), $('#active-participant'));
        }
    });

},

methods: {
    remoteParticipantConnected (participant, container) {
        // handle the TrackPublications already published by the participant
        // not much use as tracks are not subscribed at this time
        participant.tracks.forEach( publication => {
            console.log("connect publication", publication.track);
            if (publication.track) {
                console.log("connected track", publication.track);
                if (publication.track.name=="drone") {
                    container.dispatchEvent(this.drone_subscribed_event(publication));
                } else {
                    container.appendChild(publication.track.attach());
                    let container_video = $('#'+container.id+' > video');
                    this.addVideoCss(container_video);
                }
            }
        });

        participant.on('subscriptionFailed', (error)=>{
            console.log("subscription failed", error);
        });

        // remote participant published a track
        participant.on('trackSubscribed', (track, publication)=>{
            if (track) {
                console.log("tack subscribed track", track, track.name=="drone");
                if (track.name=="drone") {
                    container.dispatchEvent(this.drone_subscribed_event(publication));
                } else {
                    container.appendChild(track.attach());
                    if (track.kind=="video") {
                        let container_video = $('#'+container.id+' > video');
                        this.addVideoCss(container_video);
                        console.log("participant subscribed", participant, participant==this.participants[-1]);
                        container.dispatchEvent(this.videotrack_updated_event(track));
                    }    
                }
            }       
        });

        // remote participant unpublished a track
        participant.on('trackUnsubscribed', (track, publication)=>{
            if (track) {
                console.log("tack unsubscribed track", track, track.name=="drone");
                if (track.name=="drone") {
                    container.dispatchEvent(this.drone_unsubscribed_event(track));
                } else {
                    track.detach().forEach( el=>{
                        if ($(el).parent().attr('id')!=="active-participant")
                            el.remove();
                    });
                    // if (track.kind=="video") {
                    //     container.dispatchEvent(this.videotrack_updated_event(track));
                    // }
                }
            }
        });
    },

    remoteParticipantDisConnected (participant) {
        // before disconnect, the remote participant would unpublish first
        // unpin first if the participant is active
        participant.tracks.forEach(publication=>{
            // not much use here as publication.track is null due to being unsubscribed
            if (publication.track) {
                if (publication.track.name=="drone") {
                    // we use document here so that the function could be moved into a js file
                    let container = document.getElementById(participant.identity);
                    container.dispatchEvent(this.drone_unsubscribed_event(publication.track));
                } else 
                    publication.track.detach().forEach (el=>{
                        if ($(el).parent().attr('id')!=="active-participant")
                            el.remove();
                    });
            }

        });
    },

    addVideoCss (container_video) {
        container_video.attr('height', '100%');
        container_video.attr('width', '100%');
        container_video.attr('object-fit', 'scale-down');
    },

    setActiveVideo (participants, container) {
        // participants[0] is always the localParticipant
        console.log("participants", participants);
        let el_selector = '#'+container.attr('id')+' > video';
        let active_participant = null;
        if (participants.length==1) {
            active_participant = participants[0];
        } else if (participants.length > 1) {
            let drone_participant = participants.filter(p=>p.identity==="Drone")[0];
            if (drone_participant)
                active_participant = drone_participant;
            else
                active_participant = participants.slice(-1)[0];
        }

        if (container.attr('data-identity')==="Drone" && active_participant.identity !== "Drone")
            return;

        console.log(participants.slice(-1), participants.slice(-1)[0]);
        console.log("active participant", active_participant);
        let videoTrack = Array.from(active_participant.videoTracks.values())[0];
        console.log("videoTrack", videoTrack);
        if (videoTrack && videoTrack.track) {
            console.log("videoTrack track", videoTrack.track);
            videoTrack.track.attach(el_selector);
            container.attr('data-identity', active_participant.identity);
        } 

        // if (participants.length == 1) {
        //     if (Array.from( toRaw(participants[0]).videoTracks.values() ).length > 0) {
        //         let localVideoTrack = Array.from(toRaw(participants[0]).videoTracks.values()).filter(
        //             track => track.kind=="video"
        //         )[0].track;
        //         localVideoTrack.attach(el_selector);
        //         container.attr('data-identity', participants[0].identity);
        //     }
        // } else if (participants.length > 1) {
        //     let drone_participant = participants.filter(p=>p.identity==="Drone");
        //     if (drone_participant.length > 0) {
        //         let track = drone_participant[0].videoTracks[0].track;
        //         track.attach(el_selector);
        //         container.attr('data-identity', drone_participant[0].identity);
        //     } else {
        //         let track = participants.slice(-1)[0].videoTracks[0].track;
        //         track.attach(el_selector);
        //         container.attr('data-identity', participants.slice(-1)[0].identity);
        //     }
        // }
        // else {
        //     // customized video ads
        // }
 
    },


    leave () {
        let room = toRaw(this.room);
        room.disconnect();

    },

    videotrack_updated_event (track) {
        return new CustomEvent('videoTrackUpdated', {
            bubbles: true,
            detail: { videoTrack: ()=>track }
        });
    },

    drone_subscribed_event (track) {
        return new CustomEvent('droneSubscribed', {
            bubbles: true,
            detail: { droneTrack: ()=>track }
        });
    },

    drone_unsubscribed_event (track) {
        return new CustomEvent('droneUnsubscribed', {
            bubbles: true,
            detail: { droneTrack: ()=>track }
        });
    },

    async join () {
        
        try {
            let response = await this.$store.dispatch('housing/view', {
                'booking_id': this.$route.params.id
            });

            if ('link' in response.data) {
                let a = document.createElement('a');
                a.href = response.data.link;
                console.log(a.href);
                a.click();
                return;
            }

            this.connectOptions['name'] = response.data.room;
            let token = response.data.token;

            let room = await connect(token, this.connectOptions);
            this.room = room;
            console.log("refs", this.$refs);
            
            // operations for the local participant
            this.participants.push(room.localParticipant);
            // this.$refs['joystick'].set_room(room);

            this.$nextTick(()=>{
                let container = document.getElementById(room.localParticipant.identity);
                room.localParticipant.tracks.forEach(publication => {
                if (publication.track) {
                    if (publication.track.name=="drone") {
                        this.$refs['participantsrow'].dispatchEvent(this.drone_subscribed_event());
                    } else {
                        container.appendChild(publication.track.attach());
                        let container_video = $('#'+container.id+'> video');
                        this.addVideoCss(container_video);
                    }
                }});
            });

            // // operations for remote participants
            // // participants already connected to the room
            room.participants.forEach(participant=>{
                this.participants.push(participant);
                this.$nextTick(()=>{
                    let container = document.getElementById(participant.identity);
                    this.remoteParticipantConnected(participant, container);
                });
            });

            this.$nextTick(()=>{
                this.setActiveVideo(toRaw(this.participants), $("#active-participant"));
            });

            this.room.on('participantConnected', participant=>{
                this.participants.push(participant);
                this.$nextTick(()=>{
                    let container = document.getElementById(participant.identity);
                    this.remoteParticipantConnected(participant, container);
                    // this.setActiveVideo(toRaw(this.participants), $("#active-participant"));
                });

                
            });

            this.room.on('participantDisconnected', participant=>{
                this.remoteParticipantDisConnected(participant);
                this.$nextTick(()=>{
                    this.participants = toRaw(this.participants).filter( p=>p.identity!==participant.identity );
                    console.log("participant disconnected", this.participants);
                    this.setActiveVideo( toRaw(this.participants) , $("#active-participant"));
                });    
            });
            
            // // this feature is not provided for now 
            // this.room.on('dominantSpeakerChanged', ()=>{
            // });

            room.once('disconnected', room=>{         
                this.participants = [];
                $("#active-participant").attr('data-identity', '');
                this.room = null;
            });


        } catch (error) {
            this.gErrorHandler(error);
        }
        
    }
}

}

</script>

<style scoped>
.participant-container {
    border: 1px lightgrey solid;
    border-radius: 5px; 
    background: WhiteSmoke;
    height: 150px;
    width: 150px;
    margin: 0 10px;
    /* cursor: pointer; */
    display: inline-block;
}
.active-container {
    border-radius: 10px; 
    background: grey;
    /* margin: 10px 0; */
    min-width: 320px;
    max-height: 50vh;
    width: 50vw;
    height: auto;
}
.top-overlay {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
}
.bottom-overlay {
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0.75;
    z-index: 1000;
}

video {
    height: 100% !important;
    width: 100% !important;
    object-fit: scale-down;
}
</style>