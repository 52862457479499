<template>

<div class="ui basic grid segment" :class="{'loading': loading}">
    <div class="centered row" style="padding-top: 36px;">
    <div class="fifteen wide column">
        <div class="ui three steps">

        <div class="step">
        <div class="ui primary circular label" style="margin-right: 12px;">1</div>
        <div class="content">
            <div class="title">Agent Information</div>
            <div class="description">
                Please make sure the correspondent, the contact number and the address are correct 
                as our drone will be delivered to the given address.
            </div>
        </div>
        </div>

        <div class="step">
        <div class="ui primary circular label" style="margin-right: 12px;">2</div>
        <div class="content">
            <div class="title">Onboard Stripe</div>
            <div class="description">
                Set up a Stripe account for getting rewards from remote viewing. 
                It also helps us refund your deposit for the drone.
            </div>
        </div>
        </div>

        <div class="step">
        <div class="ui primary circular label" style="margin-right: 12px;">3</div>
        <div class="content">
            <div class="title">Shipping Fee & Deposit</div>
            <div class="description">
                Pay the delivery fee and €{{deposit}} deposit for the drone.
                The deposit will be paid back to your Stripe account once the drone is returned. 
            </div>
        </div>
        </div>

        </div>    
    </div>
    </div>




    <div class="centered row" v-if="step<3">
    <div class="fifteen wide column">
        <create-realtor-step-one :realtor="realtor" v-if="realtor">
            <div class="ui error message" v-if="stripe_incomplete">
                {{$t('incomplete-agent')}}
            </div>
        </create-realtor-step-one>
    </div>  
    </div>

    <div class="centered row" v-if="step==3">
    <div class="fifteen wide column">
        <create-realtor-step-three :shipping_fee="shipping_fee" :deposit="deposit"/>
    </div>  
    </div>


</div>
</template>

<script>
import mixin from '@/mixin'

import CreateRealtorStepOne from '../components/CreateRealtorStepOne.vue'
import CreateRealtorStepThree from '../components/CreateRealtorStepThree.vue'
 
export default {
mixins: [mixin],

components: {
    CreateRealtorStepOne,
    CreateRealtorStepThree
},

data () {
    return {
        realtor: null,
        loading: false,
        step: 0,
        shipping_fee: 12,
        deposit: 209,
        // link: null,
        stripe_incomplete: false,

    }
},

async created () {
    try{
        this.loading = true;
        this.stripe_incomplete = false;
        let response = await this.$store.dispatch("housing/fetchRealtor");
        console.log("step", response.data);
        this.realtor = response.data;
        this.step = response.data['step'];
        if ('shipping_fee' in response.data)
            this.shipping_fee = response.data['shipping_fee'];
        if ('deposit' in response.data)
            this.deposit = response.data['deposit'];
        let realtor_active = response.data.is_active;
        if (this.step==1 && realtor_active === false) {
            this.gToast(this.$t('incomplete-agent'));
            this.stripe_incomplete = true;
        }

            
    } catch (error) {
        this.gErrorHandler(error);
    } finally {
        this.loading = false;
    }
},

methods: {
 

}

}
</script>

<style scoped>
</style>

<i18n>
{
    "en":{
        "incomplete-agent": "Please make sure there is no \'Missing required information\' in your Stripe account setup." 
    },
    "cn": {
    }
}
</i18n>
