<template>
<div class="ui basic grid segment" :class="{'loading': loading}">
    <div class="centered row" style="padding-top: 28px; padding-bottom: 0px;">
    <div class="fifteen wide column" style="padding-bottom: 0px;">
        <div class="ui primary header">{{$t('edit-realtor-title')}}</div>
        <div class="ui divider"></div>
    </div>
    </div>

    <create-realtor-step-one :realtor="realtor" usage="edit" v-if="realtor" />
</div>
</template>

<script>
import CreateRealtorStepOne from '../components/CreateRealtorStepOne.vue'

export default {

components: {
    CreateRealtorStepOne,
},

data () {
    return {
        realtor: null,
        loading: false
    }
},

async created () {
    try{
        this.loading = true;
        let response = await this.$store.dispatch("housing/fetchRealtor");
        this.realtor = response.data;
        console.log(this.realtor);
    } catch (error) {
        this.gErrorHandler(error);
    } finally {
        this.loading = false;
    }
},


}
</script>

<i18n>
{
    "en": {
        "edit-realtor-title": "Edit Realtor Information",
    }
}
</i18n>