<template>
<div class="ui modal" id="viewing-cancel">
    <div class="content site-p">
        <p>There will be €0.90 cancellation fee deducted from your account.</p> 
        <p>Are you sure you want to cancel this viewing?</p>
    </div>
    <div class="actions">
        <div class="ui small primary approve button" @click="cancel_viewing"
            :class="{'loading': cancel_loading}">Yes</div>
        <div class="ui small cancel button">Cancel</div>
    </div>
</div>

<div class="ui modal" id="viewing-reschedule">
    <div class="content site-p">
        <p>Do you want to send a rescheduling request to your viewer?</p>
    </div>
<div class="actions">
    <div class="ui primary button" @click="reschedule_viewing"
        :class="{'loading': reschedule_loading}">Reschedule</div>
    <div class="ui cancel button">Cancel</div>
</div>
</div>

<div class="ui basic grid segment" :class="{'loading': loading}">
    <house-short :house="house">   
    </house-short>
    
    <div class="row" style="padding-bottom: 0;">
    <div class="column">
        <div><b style="color: grey; margin-right: 5px;">
            {{$t('id')}}:</b> {{booking_id}}
        </div>
        <div><b style="color: grey; margin-right: 5px;">
            {{$t('booked-time')}}: </b>{{booked_time}}
        </div>
    </div>
    </div>

    <div class="row">
    <div class="column">

        <div class="ui small button" @click="$router.push('/viewings')">Viewings</div>
        <div class="ui small primary button" @click="cancel" :class="{'disabled': action_disabled}">Cancel</div>
        <div class="ui small primary button" @click="reschedule" :class="{'disabled': action_disabled}">Reschedule</div>
        <div class="ui small primary button" @click="$router.push({
            name: 'view', params: {'id': booking_id}})"
            :class="{'disabled': view_disabled}">View</div>
    </div>
    </div>

</div>
</template>

<script>
import mixin from '@/mixin'
import HouseShort from '../components/HouseShort.vue'

export default {
    mixins: [mixin],
    inject: ['DateTime', 'Interval'],

    components: {
        HouseShort,
    },

    data () {
        return {
            booking_id: '',
            house: null,
            booking: null,
            loading: false,
            cancel_loading: false,
            reschedule_loading: false,
            realtor: null,
            booked_slots: [],
            calendar_error: '',
            selected_date: null,
            is_cancelled: false,
        }
    },

    computed: {

        booked_time () {
            if (this.booking) {
                return this.DateTime.fromISO(this.booking.booking_iso).toLocaleString(this.DateTime.DATETIME_MED, { locale: 'en-US' });
                // let t = this.DateTime.fromISO(this.booking.booking_iso).toLocal().toJSDate();
                // return this.gDatetimeString(t);
            } else {
                return '';
            }
        },

        view_disabled () {
            if (this.booking) {
                if (this.booking.status != 'waiting')
                    return true;

                let d = this.DateTime.fromISO(this.booking.booking_iso).toLocal().plus({minutes: 15});
                let i = this.Interval.fromDateTimes(this.DateTime.local(), d);
                console.log(i, i.length('minutes'));
                if (i.isValid==false)
                    return true;
                else
                    return false;
            } else
                return true;
        },

        action_disabled () {
            if (this.view_disabled)
                return true;
            let d = this.DateTime.fromISO(this.booking.booking_iso).toLocal();
            let i = this.Interval.fromDateTimes(this.DateTime.local(), d);

            if (i.isValid==false)
                return true;

            return false;
        }
    }, 

    async created () {
        try {
            this.loading = true;
            this.booking_id = this.$route.params.id;
            let id = parseInt(this.booking_id);

            let response = await this.$store.dispatch('housing/viewingDetail', {
                'booking_id': id
            });
            this.house = {'house': response.data.house};
            this.realtor = this.house.house.realtor;
            this.booking = response.data.booking;
            console.log("detail", response.data);
        } catch (error) {
            this.gErrorHandler(error);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        cancel () {
            $('#viewing-cancel').modal('show');
        },

        reschedule () {
            $('#viewing-reschedule').modal('show');
        },

        async cancel_viewing () {
            if (this.is_cancelled)
                return;

            try {
                this.cancel_loading = true;
                let response = await this.$store.dispatch('housing/viewingAction', {
                    'booking_id': parseInt(this.booking_id),
                    'action': 'cancel'
                });
                this.booking = response.data.booking;
                $('#viewing-cancel').modal('hide');
                this.gToast(this.$t('cancel-confirm'), 'green');
            }
            catch (error) {
                this.gErrorHandler(error);
            } finally {
                this.cancel_loading = false;
            }
        },

        async reschedule_viewing () {
            try {
                this.reschedule_loading = true;
                await this.$store.dispatch('housing/viewingAction', {
                    'booking_id': parseInt(this.booking_id),
                    'action': 'reschedule'
                });
                $('#viewing-reschedule').modal('hide');
                this.gToast(this.$t('reschedule-confirm'), 'green');
            } catch (error) {
                this.gErrorHandler(error);
            } finally {
                this.reschedule_loading = false;
            }
        }
    },

}
</script>

<style scoped>
.ui.button {
    width: 120px;
    margin: 5px;
}
.ui.card {
    background-color: whitesmoke; 
}
</style>

<i18n>
{
    "en": {
        "id": "Booking ID",
        "booked-time": "Booked time",
        "calendar-error": "Viewing rescheduling can not be done. Please try again later.",
        "reschedule-confirm": "A rescheduling request has been sent.",
        "cancel-confirm": "The viewing has been cancelled."
    },
    "cn": {
        "id": "标识号",
        "booked-time": "预约时间"
    }

}
</i18n>