export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Remote Viewing"])},
        "housing-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing Type"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page URL"])},
        "periodicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodicity"])},
        "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
        "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
        "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once"])},
        "house-renting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent a House"])},
        "house-selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell a House"])},
        "house-sharing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share a House"])},
        "hotels-bnbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels & BnBs"])},
        "holiday-homes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday Homes"])},
        "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add description in another language"])},
        "housingtype-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a housing type"])},
        "country-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a country"])},
        "city-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a city"])},
        "periodicity-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a periodicity"])},
        "custom-periodicity-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once for selling a house"])},
        "price-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A price must be a postive value"])},
        "url-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a valid url"])},
        "form-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have form errors!"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
      },
      "cn": {
        "housing-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网页地址"])},
        "periodicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加其他语言介绍"])},
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])}
      }
    }
  })
}
