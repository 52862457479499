<template>
<div class="ui basic grid segment">
    <div class="row" style="margin-top: 8px; padding-bottom: 0px;">
        <div class="column">
        <div class="center-item"><h1>{{$t('solutions')}}</h1></div>
            <div class="ui divider"></div>
        </div>
    </div>

    <div class="row" v-for="v, k in techs" :key="k">
        <div class="ui grid blue segment modifier">
            <div class="row">
                <div class="two wide computer one wide tablet one wide mobile column"></div>
                <div class="twelve wide computer fourteen wide tablet fourteen wide mobile column">
                    <div class="center-item">
                    <div class="ui image">
                        <img style="max-height: 210px;" :src="require('@/assets/banners/'+k.replace('-','_')+'.jpg')" />
                    </div>
                    </div>
                </div>
                <div class="two wide computer one wide tablet one wide mobile column"></div>
            </div>
            <div class="row">
                <div class="column">
                <div class="ui header">{{$t(v)}}</div>
                    <p class="site-p">{{$t(k)}}</p>
                    <!-- <p class="site-p" v-html="$t(k)"></p> -->
                </div>
            </div>
        </div>
    </div>

    <div class="centered row">
        <a href="#"><b>Back to Top</b></a>
    </div>
</div>
</template>

<script>
export default {
    data () {
        return {
            techs: {
                'crowd-counting': "crowd-counting-head",
                'remote-sensing': "remote-sensing-head",
                'anomaly-detection': "anomaly-detection-head",
                'object-detection': "object-detection-head",
                'face-detection': "face-detection-head",
                'image-retrieval': "image-retrieval-head",
                // 'action-recognition': "Action Recognition",
                'activity-recognition': "activity-recognition-head",
                'text-recognition': "text-recognition-head",
                'nlp': "nlp-head",

            }
        }
    }
}
</script>


<style scoped>
h1 {	
	font-family: 'Raleway', sans-serif;
	color: dimgrey;
	letter-spacing: 2px;
}

.modifier {
    margin: 0 12px 0 12px !important;
    width: 100%;
}

.ui.header {
	letter-spacing: 2px;
}
</style>

<i18n>
{
  "en": {
    "crowd-counting-head": "Crowd Counting",
    "remote-sensing-head": "Remote Sensing",
    "anomaly-detection-head": "Anomaly Detection",
    "object-detection-head": "Object Detection",
    "face-detection-head": "Face Detection",
    "image-retrieval-head": "Image Retrieval",
    "activity-recognition-head": "Activity Recognition",
    "text-recognition-head": "Text Recognition",
    "nlp-head": "Natural Language Processing",
    "crowd-counting": "A neural network is built to estimate the count of objects from an image with arbitrary crowd density and arbitrary perspective. The objects can be humans, farm animals, blood cells, trees, etc. The network can be deployed on an Intel Movidius neural compute stick for edge computing, such that the GDPR issue can be avoided.",
    "remote-sensing": "Image segmentation can be applied to satellite and drone images to locate the regions of interest. For example, we detected the house roofs from satellite images to help the researchers from the Pennsylvania State University study the rural areas of Alaska. Moreover, with multispectral images from satellites and drones, together with the in-situ data, the environment can be well monitored. For example, we built neural networks to have a detailed study of soil characteristics and a better estimation of soil moisture on a large scale, which is very useful in precision agriculture.", 
    "anomaly-detection": "Video anomaly detection aims to localize anomalies in a video in space and/or time. The anomalies could be abnormalities, novelties and outliers. In our recent work a new metric was designed to have a fine grained assessment of the detection. The techniques can be also applied to other data and applications, such as network monitoring, financial fraud detection, predictive maintenance, etc.", 
    "object-detection": "We can detect predefined objects such as cars in drone footage, pedestrians in surveillance camera, polyps in endoscopy images, etc. The objects can be detected with morden approaches built on neural networks such as faster R-CNN, SSD, YOLO, etc, however those methods could be power hungry. The objects can also be detected with conventional approaches such as SVM and LDA, which are resource economic but less accurate.", 
    "face-detection": "Face detection and blurring has become a routine in video surveillance. Not only can our model detect the faces, but also detects the face attributes such as the gender, \
        which is useful in market research. All algorithms run on neural computing sticks as edge computing. Only the statistics are reported. No personal data is retained \
        and the GDPR is complied.",
    "image-retrieval": "The images of interest can be retrieved by analyzing the content of the image using computer vision techniques. \
        Our technology had been applied to build user interest profiles for recommendation and personalisation in a pilot project for a hotel booking website. \
        The techniques also helped in recent healthcare studies from Duke University.",
    "activity-recognition": "Human activities can be recorded with multiple sensors built in smart phone, fitbit, google glass, etc. for futher analysis. \
        We had built a platform with Google Glass to help football coaches capture and annotate interesting events during training and matches. \
        The platform applies the hindsight recording principle and automatically detects those interesting events indicated by predefined hand gestures.",
    "text-recognition": "We trained neural networks to translate graphic text embedded in images into computer readable text. The graphic text can be printed text, \
        artistic text or handwritten text. It is very useful in process automation. Our technology had helped an advertising company index thousands of images without any labels \
        hidden in their database.",
    "nlp": "We built neural networks to identify the various forms of online racist hateful speech which is a concern from the European Network Against Racism, \
        Irish Human Rights and Equality Commission and National Anti Bullying Research and Resource Center. \
        Our technology helped social scientists explore the massive amount of social media feeds efficiently and draw insights from data collected.",
    "solutions": "AI Solutions"
  },
   "cn": {
        "crowd-counting-head": "人群统计",
        "remote-sensing-head": "遥测遥感",
        "anomaly-detection-head": "异常侦测",
        "object-detection-head": "物体侦测",
        "face-detection-head": "人脸侦测",
        "image-retrieval-head": "图像检索",
        "activity-recognition-head": "行为识别",
        "text-recognition-head": "文字识别",
        "nlp-head": "自然语言处理",
        "crowd-counting": "A neural network is built to estimate the count of objects from an image with arbitrary crowd density and arbitrary perspective. The objects can be humans, farm animals, blood cells, trees, etc. The network can be deployed on an Intel Movidius neural compute stick for edge computing, such that the GDPR issue can be avoided.",
        "remote-sensing": "Image segmentation can be applied to satellite and drone images to locate the regions of interest. For example, we detected the house roofs from satellite images to help the researchers from the Pennsylvania State University study the rural areas of Alaska. Moreover, with multispectral images from satellites and drones, together with the in-situ data, the environment can be well monitored. For example, we built neural networks to have a detailed study of soil characteristics and a better estimation of soil moisture on a large scale, which is very useful in precision agriculture.", 
        "anomaly-detection": "Video anomaly detection aims to localize anomalies in a video in space and/or time. The anomalies could be abnormalities, novelties and outliers. In our recent work a new metric was designed to have a fine grained assessment of the detection. The techniques can be also applied to other data and applications, such as network monitoring, financial fraud detection, predictive maintenance, etc.", 
        "object-detection": "We can detect predefined objects such as cars in drone footage, pedestrians in surveillance camera, polyps in endoscopy images, etc. The objects can be detected with morden approaches built on neural networks such as faster R-CNN, SSD, YOLO, etc, however those methods could be power hungry. The objects can also be detected with conventional approaches such as SVM and LDA, which are resource economic but less accurate.", 
        "face-detection": "Face detection and blurring has become a routine in video surveillance. Not only can our model detect the faces, but also detects the face attributes such as the gender, which is useful in market research. All algorithms run on neural computing sticks as edge computing. Only the statistics are reported. No personal data is retained and the GDPR is complied.",
        "image-retrieval": "The images of interest can be retrieved by analyzing the content of the image using computer vision techniques. Our technology had been applied to build user interest profiles for recommendation and personalisation in a pilot project for a hotel booking website. The techniques also helped in recent healthcare studies from Duke University.",
        "activity-recognition": "Human activities can be recorded with multiple sensors built in smart phone, fitbit, google glass, etc. for futher analysis. We had built a platform with Google Glass to help football coaches capture and annotate interesting events during training and matches. The platform applies the hindsight recording principle and automatically detects those interesting events indicated by predefined hand gestures.",
        "text-recognition": "We trained neural networks to translate graphic text embedded in images into computer readable text. The graphic text can be printed text, artistic text or handwritten text. It is very useful in process automation. Our technology had helped an advertising company index thousands of images without any labels hidden in their database.",
        "nlp": "We built neural networks to identify the various forms of online racist hateful speech which is a concern from the European Network Against Racism, Irish Human Rights and Equality Commission and National Anti Bullying Research and Resource Center. Our technology helped social scientists explore the massive amount of social media feeds efficiently and draw insights from data collected.",
        "solutions": "智能服务"
   },
}
</i18n>