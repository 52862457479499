<template>
<div class="ui modal" id="large-view">
    <img class="ui centered image" :src="large_image" style="margin-top: 10px; margin-bottom: 10px;">
    <div class="actions" style="padding: 0">
        <div class="ui cancel bottom attached button" style="margin: 0;">Close</div>
    </div>
</div>

<div class="ui basic grid segment">
<div class="row" style="padding-bottom: 0">
        <a href="javascript:;" class="ui image">
            <img :src="inline_image" @click="large_view(inline_image)">
        </a>
</div>
<div class="row" v-if="images.length > 1" style="padding-top: 0">
<div class="ui images">
    <div v-for="(photo, idx) in images" :key="idx" style="float:left; margin: 5px 0px;">
        <a href="javascript:;" @click="inline_view(photo)" class="ui bordered image" 
            style="max-width: 100px; margin-bottom: 0px; z-index: 10;">
            <img :src="photo" ref="photos" style="width:100%; height:100%; object-fit: contain;">
        </a>
    </div>
</div>
</div>

</div>
</template>

<script>
export default {
props: ['images'],

data () {
    return {
        inline_image: "",
        large_image: "",
    }
},

watch: {
    images (newValue, oldValue) {
        this.inline_image = 
            this.images.length==0 ? require("@/assets/no-image.jpg") : this.images[0];
    }
},

created () {
    console.log("images", this.images);
    this.inline_image = this.images.length==0 ? require("@/assets/no-image.jpg") : this.images[0];
},

methods: {
    inline_view (photo) {
        this.inline_image = photo;
    },

    large_view(photo) {
        this.large_image = photo;
        $('#large-view').modal({
            closeIcon: true,
        }).modal('show');
    },
}

}
</script>

<style scoped>
.row {
    padding: 0 !important;
}

.column {
    padding: 0 !important;
}
</style>