<template>
<div id="front-page">
    <banner-top></banner-top>
    <banner-house-viewing></banner-house-viewing>
    <banner-smart></banner-smart>
    <banner-about></banner-about>
</div>
</template>

<script>
import BannerTop from '@/views/FrontPage/BannerTop.vue'
import BannerHouseViewing from '@/views/FrontPage/BannerHouseViewing.vue'
import BannerAbout from '@/views/FrontPage/BannerAbout.vue'
import BannerSmart from '@/views/FrontPage/BannerSmart.vue'

export default {
  components: {
      BannerTop,
      BannerHouseViewing,
      BannerAbout,
      BannerSmart
  }
}
</script>

<style scoped>

</style>