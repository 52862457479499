<template>
<div class="ui modal" id="large-view">
    <img class="ui centered image" :src="large_image" style="margin-top: 10px; margin-bottom: 10px;">
    <div class="actions" style="padding: 0">
        <div class="ui cancel bottom attached button" style="margin: 0;">Close</div>
    </div>
</div>

<div style="margin-bottom: 15px;">
<div class="ui form" id="addhouse-images">
    <div class="field">
        <label><span>{{$t('upload')}}</span></label>
        <div class="ui action input">
            <input type="file" accept="image/*;capture=camera" ref="photo" name="photo">
            <div class="ui primary button" @click="compress" 
                :class="{'loading': uploading, 'disabled': uploading}">
            {{$t('upload-button')}}
            </div>
        </div>
    </div>
</div>

<div class="ui images">
    <div v-for="(photo, idx) in images" :key="idx" style="float:left; margin: 5px 0;">
        <a href="javascript:;" @click="large_view(photo)" class="ui bordered image" 
            style="width: 150px; height: 150px; margin-bottom: 0px; z-index: 10;">
            <img :src="photo" ref="photos" style="width:100%; height:100%; object-fit: contain;">
        </a>
        <div class="ui bottom orange attached mini button" style="margin:0 5px; z-index: 10;" @click="delete_house_photo(photo, idx)">Delete</div>
    </div>
</div>
</div>

</template>

<script>
export default {
props: ['imgs'],

data () {
    return {
        uploading: false,
        images: [],
        large_image: "",
    }
},

watch: {
    'imgs' (n, o) {
        this.images = n;
    },
},

created () {
    this.clear_upload_folder();
    this.images = this.imgs;
},

methods: {
    async clear_upload_folder() {
        await this.$store.dispatch('housing/clearUploadFolder', {'clear': 'house'});
    },

    async upload_photo(f) {
        let response = await this.$store.dispatch('housing/uploadHousePhoto', f);
        this.images.push(response.data);
        this.$refs.photo.value=null;
    },


    compress () {
        let max_photos = 5
        this.uploading = true;
        try {
            if (this.images.length >= max_photos)
                throw {'name': 'file', 'message': 'At most 5 photos are allowed'};

            var in_f = this.$refs.photo.files[0];
            if (!in_f) {
                throw {'name': 'file', 'message': 'No file is selected'};
            }

            const max_size = 800;
            const fileName = in_f.name;
            const reader = new FileReader();
            reader.readAsDataURL(in_f);
            reader.onload = event =>{
                const img = new Image();
                img.src = event.target.result;
                img.onload = ()=>{
                    var width = img.width;
                    var height = img.height;
                    if (img.width > img.height) {
                        if (img.width > max_size) {
                            height = img.height * max_size/img.width;
                            width = max_size;
                        }
                    } else {
                        if (img.height > max_size) {
                            width = img.width * max_size/img.height;
                            height = max_size;
                        }
                    }

                    const elem = document.createElement('canvas');
                    elem.width = width;
                    elem.height = height;
                    const ctx = elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    ctx.canvas.toBlob( (blob)=>{
                        const file = new File([blob], fileName, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        });
                        this.upload_photo(file);
                    }, 'image/jpeg', 1);
                },
                reader.onerror = (error) => {
                    throw {'name': 'reader', 'message': error.message};
                };
            };
        } catch (error) {
            $('body').toast({
                class: 'orange',
                displayTime: 5000,
                closeIcon: true,
                message: error.message,
                position: 'top center'
            });
        } finally {
            this.uploading = false;
        }
    },

    large_view(photo) {
        this.large_image = photo;
        $('#large-view').modal({
            closeIcon: true,
        }).modal('show');
    },

    async delete_house_photo (photo, idx) {
        try {
            await this.$store.dispatch('housing/deleteHousePhoto', {
                'photo': photo
            });
            this.images.splice(idx, 1);
        } 
        catch (error) {
            let msg = '';
            if (error.response) {
                msg = error.response.data.message;
            } else if (error.message) {
                msg = error.message;
            } else {
                console.log(error);
            }
            if (msg)
                $('body').toast({
                    class: 'orange',
                    displayTime: 5000,
                    closeIcon: true,
                    message: msg,
                    position: 'top center'
                });
        }
    },

}

}
</script>

<i18n>
{
"en": {
    "upload": "Upload (Max. 5 Photos)",
    "upload-button": "Upload",
},

"cn": {
    "upload": "上传 (做多5张)",
    "upload-button": "上传",
},
}
</i18n>