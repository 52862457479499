export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "housing-type-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a housing type"])},
        "housing-city-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a city"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
        "house-renting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent a House"])},
        "house-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy a House"])},
        "house-sharing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House Sharing"])},
        "hotels-bnbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels & BnBs"])},
        "holiday-homes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday Homes"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
      },
      "cn": {
        "housing-type-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择房屋类型"])},
        "housing-city-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择城市"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
        "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["爱尔兰"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
        "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关键字"])},
        "house-renting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["租房"])},
        "house-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买房"])},
        "house-sharing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合租"])},
        "hotels-bnbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒店 & 民宿"])},
        "holiday-homes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["度假房"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])}
      }
    }
  })
}
