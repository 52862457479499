export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "no-house-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No house code specified"])}
      },
      "cn": {
        "no-house-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请指定要查看的房子"])}
      }
    }
  })
}
