<template>
<div class="ui small secondary pagination menu" style="padding-left: 0; padding-right: 0;">

    <a class="item" @click="prev_page">
        <i class="primary angle double left icon" style="margin: auto;"></i>
    </a>

    <div class="primary item">
        <div class="ui small right labeled input focus">
            <div class="ui small input">
            <input type="text" size="4" v-model.trim="page_str" @keyup.enter="input_page" style="width:40px;">
            <span class="ui blue label" style="margin-left: -1px;">{{total_pages}}</span>
            </div>

        </div>
    
    </div>

    <a class="item" @click="next_page">
        <i class="primary angle double right icon" style="margin: auto;"></i>
    </a>

</div>
</template>

<script>
export default {
    props: {
        total_pages: Number,
        modelValue: Number,
    },
    emits: ['update:modelValue', 'pageChanged'],

    data () {
        return {
            page: this.modelValue,
            timeout: null,
            page_str: this.modelValue.toString(),
        }
    },

    watch: {
        modelValue (newValue, oldValue) {
            this.page = newValue;
            this.page_str = this.page.toString();
        }
    },

    methods: {
        delay_emit () {
            if (this.timeout)
                clearTimeout(this.timeout);
            this.timeout = setTimeout(()=>{
                this.$emit('update:modelValue', this.page);
                this.$emit('pageChanged');
            }, 500);
        },

        prev_page () {
            if (this.page > 1) {
                this.page -= 1;
                this.page_str = this.page.toString();
                this.delay_emit();
            }
        },

        next_page () {
            if (this.page < this.total_pages) {
                this.page += 1;
                this.page_str = this.page.toString();
                this.delay_emit();
            }
        },

        input_page () {
            this.page_str = this.page_str.replace(/\s/g, '');
            if (parseInt(this.page_str))
                this.page = parseInt(this.page_str);
            else
                this.page = 1;
            
            if (this.page < 1)
                this.page = 1;

            if (this.page > this.total_pages)
                this.page = this.total_pages;
            
            this.page_str = this.page.toString();
            this.delay_emit();
        }

    }
}
</script>

<style scoped>
.fitted.item {
    background-color: inherit !important;
}

.item {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
</style>