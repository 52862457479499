export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "upcoming-viewings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Viewings"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewing"])},
        "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
        "viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewer"])}
      },
      "cn": {
        "upcoming-viewings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看房等待"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标识号"])},
        "viewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["观视"])},
        "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预约"])},
        "viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户"])}
      }
    }
  })
}
