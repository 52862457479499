<template>
<div class="ui modal" id="booking-cancel">
    <div class="content site-p">
        <p>There will be €0.90 cancellation fee deducted from your refund. You can reschedule your booking without any charge.</p> 
        <p>Are you sure you want to cancel this booking?</p>
    </div>
    <div class="actions">
        <div class="ui small primary approve button" @click="cancel_booking">Yes</div>
        <div class="ui small cancel button">Cancel</div>
    </div>
</div>

<div class="ui modal" id="booking-reschedule">
<div class="content">
    <calendar-user ref="calendar_reschedule" :realtor_info="realtor" 
        :booked_slots="booked_slots" @update="selected_date=$event" 
        v-if="!reschedule_loading && realtor"></calendar-user>
</div>
<div class="actions">
    <div class="ui negative message" v-if="calendar_error">
    <ul class="list">
        <li>{{calendar_error}}</li>
    </ul>
    </div>

    <div class="ui primary button" @click="reschedule_booking">Reschedule</div>
    <div class="ui cancel button">Cancel</div>
</div>

</div>

<div class="ui basic grid segment" :class="{'loading': loading}">
    <house-short :house="house">    
        <div class="ui horizontal centered fluid card">
            <div class="content">
                <div class="ui small header" style="margin: 0;">
                    <router-link :to="realtor_link">
                    {{house.house.realtor.is_company ? 
                        gUpperFirstChar(house.house.realtor.company_name) : 
                        gUpperFirstChar(house.house.realtor.agent.user.first_name)}}
                    </router-link>
                </div>
                <div class="description">
                    <p>{{realtor_description}}</p>
                </div>
            </div>
            <div class="image">
                <img :src="house.house.realtor.profile_images.length > 0 ? 
                    house.house.realtor.profile_images[0] : require('@/assets/no-image.jpg')">
            </div>
        </div>
    </house-short>
    
    <div class="row" style="padding-bottom: 0;">
    <div class="column">
        <div><b style="color: grey; margin-right: 5px;">
            {{$t('id')}}:</b> {{booking_id}}
        </div>
        <div><b style="color: grey; margin-right: 5px;">
            {{$t('booked-time')}}: </b>{{booked_time}}
        </div>
    </div>
    </div>

    <div class="row">
    <div class="column">
        <div class="ui warning message" :class="{'hidden': hide_message}" 
            style="margin-bottom: 8px;">
            You can cancel or reschedule this viewing at least 24 hours before your booked time
        </div>

        <div class="ui small button" @click="$router.push('/bookings')">Back</div>
        <div class="ui small primary button" @click="cancel" 
            :class="{'disabled': action_disabled, 'loading': cancel_loading}">Cancel</div>
        <div class="ui small primary button" @click="reschedule"
            :class="{'disabled': action_disabled, 'loading': reschedule_loading}">Reschedule</div>
        <div class="ui small primary button" @click="$router.push({
            name: 'view', params: {'id': booking_id}})"
            :class="{'disabled': view_disabled}">View</div>
    </div>
    </div>

</div>
</template>

<script>
import mixin from '@/mixin'
import HouseShort from '../components/HouseShort.vue'
import CalendarUser from '../components/CalendarUser.vue';


export default {
    mixins: [mixin],
    inject: ['DateTime', 'Interval'],

    components: {
        HouseShort,
        CalendarUser
    },

    data () {
        return {
            booking_id: '',
            house: null,
            booking: null,
            loading: false,
            cancel_loading: false,
            reschedule_loading: false,
            realtor: null,
            booked_slots: [],
            calendar_error: '',
            selected_date: null,
        }
    },

    computed: {
        realtor_description () {
            let b = this.house.house.realtor.i18n.filter(elem=>elem.locale==this.$i18n.locale);
            if (b.length==0)
                b = this.house.house.realtor.i18n.filter(elem=>elem.locale=="en");
            return b[0]? b[0].short_description:'';
        },

        // the link is not correct
        realtor_link () {
            let b = this.house.house.realtor.i18n.filter(elem=>elem.locale==this.$i18n.locale);
            if (b.length==0)
                b = this.house.house.realtor.i18n.filter(elem=>elem.locale=="en");
            return b[0]? '/housing':'';
        },

        booked_time () {
            if (this.booking) {
                return this.DateTime.fromISO(this.booking.booking_iso).toLocaleString(this.DateTime.DATETIME_MED, { locale: 'en-US' });
                // let t = this.DateTime.fromISO(this.booking.booking_iso).toLocal().toJSDate();
                // return this.gDatetimeString(t);
            } else {
                return '';
            }
        },

        view_disabled () {
            if (this.booking) {
                if (this.booking.status != 'waiting')
                    return true;

                let d = this.DateTime.fromISO(this.booking.booking_iso).toLocal().plus({minutes: 15});
                let i = this.Interval.fromDateTimes(this.DateTime.local(), d);
                console.log(i, i.length('minutes'));
                if (i.isValid==false)
                    return true;
                else
                    return false;
            } else
                return true;
        },

        action_disabled () {
            if (this.view_disabled)
                return true;
            let d = this.DateTime.fromISO(this.booking.booking_iso).toLocal();
            let i = this.Interval.fromDateTimes(this.DateTime.local(), d);

            if (i.isValid==false || i.length('hours')<24)
                return true;

            return false;
        }
    }, 

    async created () {
        try {
            this.loading = true;
            this.booking_id = this.$route.params.id;
            let id = parseInt(this.booking_id);

            let response = await this.$store.dispatch('housing/bookingDetail', {
                'booking_id': id
            });
            this.house = {'house': response.data.house};
            this.realtor = this.house.house.realtor;
            this.booking = response.data.booking;
            console.log("detail", response.data);
        } catch (error) {
            this.gErrorHandler(error);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        cancel () {
            $('#booking-cancel').modal('show');
        },

        async reschedule () {
            try {
                this.reschedule_loading = true;
                this.calendar_error = '';
                this.selected_date = null;
                console.log("detail realtor", this.realtor);
                let response = await this.$store.dispatch('housing/fetchSlots',
                    {'realtor_id': this.realtor.id});
                console.log("reschedule response data", response.data);
                if ('admin_slots' in response.data)
                    this.booked_slots = response.data.booked_slots.concat(
                        response.data.admin_slots);
                else
                    this.booked_slots = response.data.booked_slots;
                $('#booking-reschedule').modal('show');
            } catch (error) {
                this.gErrorHandler(error);
            } finally {
                this.reschedule_loading = false;
            }

        },

        async cancel_booking () {
            if (this.is_cancelled)
                return;

            try {
                this.cancel_loading = true;
                let response = await this.$store.dispatch('housing/bookingAction', {
                    'booking_id': parseInt(this.booking_id),
                    'action': 'cancel'
                });
                this.booking = response.data.booking;
                console.log("cancel booking", this.booking);
            }
            catch (error) {
                this.gErrorHandler(error);
            } finally {
                this.cancel_loading = false;
            }
        },

        async reschedule_booking () {
            this.calendar_error = this.$refs['calendar_reschedule'].validation()
            if (!this.calendar_error) {
                this.$refs['calendar_reschedule'].update_emit();
                let selected_luxon = this.DateTime.fromJSDate(this.selected_date);
                try {
                    let response = await this.$store.dispatch('housing/bookingAction', {
                        'booking_id': parseInt(this.booking_id),
                        'booking': selected_luxon.toUTC().toJSDate(),
                        'booking_iso': selected_luxon.toISO(),
                        'time_zone': selected_luxon.zoneName,
                        'action': 'reschedule'
                    });
                    this.booking = response.data.booking;
                    $('#booking-reschedule').modal('hide');
                    $('body').toast({
                        class: 'green',
                        displayTime: 5000,
                        closeIcon: true,
                        message: this.$t('reschedule-success'),
                        position: 'top center'
                    });

                } catch (error) {
                    if (error.response)
                        this.calendar_error = error.response.data.message;
                    else if (error.message)
                        this.calendar_error = error.message;
                    else
                        this.calendar_error = this.$t('calendar-error');
                }

            }
        }
    },

}
</script>

<style scoped>
.ui.button {
    width: 100px;
    margin: 5px;
}
.ui.card {
    background-color: whitesmoke; 
}
</style>

<i18n>
{
    "en": {
        "id": "Booking ID",
        "booked-time": "Booked time",
        "calendar-error": "Viewing rescheduling can not be done. Please try again later.",
        "reschedule-success": "Your viewing is rescheduled",
    },
    "cn": {
        "id": "标识号",
        "booked-time": "预约时间"
    }

}
</i18n>